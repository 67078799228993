import { isInteger, round } from "lodash";
import CountUp from "react-countup";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  background-color: #eaeaea;
  padding: 16px 20px;
  border-radius: 20px;
  display: grid;
  gap: 10px;
  background-color: ${props => props.color}44;
  border: 1px solid ${props => props.color}66;
`;

const CardLabel = styled.div`
  font-weight: 500;
  text-align: center;
`;

const CardValue = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 500;
`;

const formatLabel = labelStr => {
  try {
    const parts = labelStr.split("_");
    return parts.map(part => part[0].toUpperCase() + part.slice(1)).join(" ");
  } catch (e) {
    return labelStr;
  }
};

const KeyFiguresParams = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
}) => {
  const cards = Object.entries(records?.[0]?.fields || {});

  return (
    <Container>
      {cards?.map(([key, field]) => {
        const roundedValue = round(field?.value, 2);

        return (
          <Card key={key} color={getColorFromString(key)}>
            <CardValue>
              {isInteger(roundedValue) ? (
                <CountUp start={0} end={roundedValue} duration={2} />
              ) : (
                roundedValue
              )}
            </CardValue>
            <CardLabel>{formatLabel(key)}</CardLabel>
          </Card>
        );
      })}
    </Container>
  );
};

export default KeyFiguresParams;
