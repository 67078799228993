import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import LayoutNew from "components/LayoutNew";
import { getWordDoc, patchWordDoc } from "api/services/wordDocsService";
import { ChatIcon } from "components/IconsNew";
import InputExpanding from "components/InputExpanding";
import ChatViewNew from "components/ChatViewNew";

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  height: calc(100vh - 40px);
  transition: width 0.2s;
`;

const StyledInputExpanding = styled(InputExpanding)`
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: transparent;
`;

const TopLeftBar = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ChatDocPageNew = () => {
  const { wordDocId } = useParams();
  const [wordDocName, setWordDocName] = useState("");

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  const doPopulateWordDoc = async () => {
    if (!wordDocId) {
      return;
    }
    const { data, error } = await getWordDoc(wordDocId);
    if (error) {
      return;
    }
    setWordDocName(data?.fileName);
  };

  const doPatchWordDoc = async (fields = {}) => {
    if (!wordDocId) {
      return;
    }
    const { data: latestDoc, error: errGet } = await getWordDoc(wordDocId);
    if (errGet) {
      return;
    }
    const { data, error } = await patchWordDoc(
      wordDocId,
      {},
      {
        ...latestDoc,
        ...fields,
      }
    );
    if (error) {
      return;
    }
    setWordDocName(data?.fileName);
  };

  return (
    <LayoutNew
      bgColor="linear-gradient(180deg, #F3F5F7 0%, #FDFDFD 100%);"
      topBarBgColor="#f3f5f7"
    >
      <TopLeftBar>
        <Link to="/">
          <ChatIcon
            height="32px"
            style={{
              fill: "black",
            }}
          />
        </Link>
        <StyledInputExpanding
          value={wordDocName}
          onChange={e => setWordDocName(e.target.value)}
          onBlur={() => doPatchWordDoc({ fileName: wordDocName })}
        />
      </TopLeftBar>
      <Container>
        <ChatViewNew wordDocId={wordDocId} />
      </Container>
    </LayoutNew>
  );
};

export default ChatDocPageNew;
