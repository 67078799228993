import { apiGet, apiPatch, apiPost } from "api/api-http-methods";
import { zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getLoginTokens = async (userName, password) => {
  const { data, error } = await apiPost(
    `/solutions/ocr/authentication-service/login`,
    {},
    {
      userName,
      password,
    }
  );
  if (error) {
    return { data, error };
  }
  return { data, error };
};

export const postLogin = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/authentication-service/login",
    params,
    body
  );
  return { data, error };
};

export const resetPassword = async user => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/authentication-service/users/reset-pwd/${user}`
  );
  return { data, error };
};

export const updateUser = async (userId, user) => {
  const { data, error } = await apiPatch(
    `/solutions/ocr/authentication-service/users/${userId}`,
    null,
    user
  );
  return { data, error };
};

export const getVerifyOneTimeCode = async (userId, params) => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/users/2fa/${userId}/verify`,
    params
  );
  return { data, error };
};

export const getUserGroups = async () => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/authentication-service/v1/user-groups`
  );
  return { data, error };
};

export const getUsers = async () => {
  const { data, error } = await apiGet(
    `/solutions/ocr/authentication-service/users`
  );
  return { data, error };
};

export const postUserGroups = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    "/solutions/ocr/authentication-service/v1/user-groups",
    params,
    body
  );
  return { data, error };
};

export const registerTrialUser = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/authentication-service/users/register-trial-user",
    params,
    body
  );
  return { data, error };
};

export const getUserProfile = async (params = {}, body = {}) => {
  const { data, error } = await apiGet(
    "/authentication-service/api/v1/user-profile",
    params,
    body
  );
  return { data, error };
};

export const postUpgradeTrialUser = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/authentication-service/users/upgrade-trial-user",
    params,
    body
  );
  return { data, error };
};

export const postUsersVerify = async (params = {}, body = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/authentication-service/users/verify",
    params,
    body
  );
  return { data, error };
};

export const postUsers = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/authentication-service/users",
    params,
    body
  );
  return { data, error };
};

export const postAccessTokensTrialUser = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/solutions/ocr/authentication-service/access-tokens/trial-user",
    params,
    body
  );
  return { data, error };
};

export const getStripeCustomer = async (params = {}, body = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/authentication-service/api/v1/customers",
    params,
    body
  );
  return { data, error };
};

export const patchStripeCustomerBillingAddress = async (
  params = {},
  body = {}
) => {
  const { data, error } = await zapiPatch(
    "/solutions/ocr/authentication-service/api/v1/customers/update-billing-address",
    params,
    body
  );
  return { data, error };
};

export const getInvoices = async (params = {}, body = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/authentication-service/api/v1/invoices",
    params,
    body
  );
  return { data, error };
};

export const getMembersAndTeams = async (params = {}, body = {}) => {
  const { data, error } = await zapiGet(
    "/solutions/ocr/authentication-service/api/v1/organization/members-and-teams",
    params,
    body
  );
  return { data, error };
};
