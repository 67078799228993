import { putSigmasV2 } from "api/backend/fileSystemEndpoints";
import { PencilIconDetailed } from "components/IconsNew";
import RecordFieldInput from "components/RecordFieldInput";
import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { cloneDeep, set } from "lodash";
import { useState, useEffect } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 600px;
  height: 300px;
  border-radius: 24px;
  padding: 20px;

  ${props => props?.isDisabled && "opacity: 0.5; pointer-events: none;"}

  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  align-items: end;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  justify-content: end;
  justify-items: end;
`;

const EditSigmaRecordFieldModal = ({
  className,
  record,
  column,
  onRecordPatchComplete = (recordId, fields) => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const value = record?.fields?.[column?.dbName]?.value;
    if (column?.type === "DATETIME" && value?.endsWith("Z")) {
      const formattedDate = value?.replace("Z", "");
      setFieldValue(formattedDate);
      return;
    }

    setFieldValue(value);
  }, [JSON.stringify({ record, column })]);

  if (isOpen) {
    console.log({ record, column });
  }

  const doPatchRecordField = async () => {
    setIsLoading(true);
    const newRecord = cloneDeep(record);
    set(newRecord, `fields.${column.dbName}.value`, fieldValue);
    const { data } = await putSigmasV2(record?.id, {}, newRecord);
    onRecordPatchComplete(record?.id, data);
    setIsOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <PencilIconDetailed
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
        className={className}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <ModalTitle>Edit "{column?.displayName}"</ModalTitle>
          <RecordFieldInput
            value={fieldValue}
            onChange={e => setFieldValue(e.target.value)}
            column={column}
            style={{ width: "100%" }}
            isEditMode
          />
          <TwoColumns>
            <ButtonWord isPrimary onClick={doPatchRecordField}>
              Save
            </ButtonWord>
            <ButtonWord onClick={() => setIsOpen(false)}>Cancel</ButtonWord>
          </TwoColumns>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditSigmaRecordFieldModal;
