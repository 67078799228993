import { useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import { CrossIcon } from "components/ui/Icons";
import {
  getDirectoryItems,
  patchFileSystemUpdateAccess,
} from "api/services/fileSystem";
import { useEffect } from "react";
import { PeopleIcon, PersonBlankIcon, ShareIcon } from "components/IconsNew";
import { getMembersAndTeams } from "api/backend/authenticationServiceEndpoints";
import SearchablePopoverItems from "components/SearchablePopoverItems";
import {
  postPipelineConfigsShare,
  postPipelineConfigsUnshare,
} from "api/backend/fileSystemEndpoints";
import {
  getAppBasePathSource,
  getAppBasePathWorking,
} from "components/LayoutApp";

const ActionOption = styled.div`
  height: 30px;
  padding-right: 50px;
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  gap: 8px;

  :hover {
    background-color: #d9d9d9;
  }

  svg {
    height: 14px;
    width: 14px;
  }
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 32px;
  border-radius: 24px;
  display: grid;
  gap: 10px;
  justify-items: start;
  align-content: start;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  width: 600px;
  min-height: 300px;
`;

const FileName = styled.div`
  font-size: 18px;
  padding-bottom: 20px;
`;

const Contributor = styled.div`
  background-color: #f3f5f7;
  padding: 8px 12px;
  width: 100%;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  gap: 10px;
  svg {
    height: 18px;
    fill: #ccc;
  }

  border-radius: 12px;
`;

const ShortDiv = styled.div`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const GreySpan = styled.span`
  color: ${props => props.theme.color.closer2};
`;

const SharedWithText = styled.div`
  font-weight: bold;
`;

const StyledCrossIcon = styled(CrossIcon)`
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  :hover {
    background-color: ${props => props.theme.color.furthest};
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const ContirbutorsContainer = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

const ErrorMsg = styled.div`
  color: ${props => props.theme.color.error};
  line-height: 1.2;
`;

const InputAndAddButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 10px;
  align-items: center;
`;

const StyledSearchablePopoverItems = styled(SearchablePopoverItems)`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const ItemContainer = styled.div`
  padding: 6px 10px;
  display: flex;
  gap: 10px;
  background-color: white;
  font-weight: 500;
  align-items: center;

  svg {
    fill: #ccc;
    height: 18px;
  }

  :hover {
    background-color: #eaeaea;
  }
`;

export const doSharePipeline = async (
  pipelineId,
  { userGroupId, accessLevel }
) => {
  // share pipeline
  let postUpdateAccess =
    accessLevel === "NO_ACCESS"
      ? postPipelineConfigsUnshare
      : postPipelineConfigsShare;

  const { data, error } = await postUpdateAccess(
    pipelineId,
    {},
    {
      userGroupId,
      accessLevel,
    }
  );

  let params = {
    // pageSize: 1,
    directoryPath: "/",
  };
  const { data: rootDir } = await getDirectoryItems(params);

  // share sources directory
  const sourceAppDirItem = rootDir?.items?.find(
    item => item?.path === getAppBasePathSource(pipelineId)
  );
  await patchFileSystemUpdateAccess({
    id: sourceAppDirItem?.id,
    itemType: sourceAppDirItem?.type,
    access: {
      userGroupId,
      accessLevel,
    },
  });

  // share working files directory
  const workingAppDirItem = rootDir?.items?.find(
    item => item?.path === getAppBasePathWorking(pipelineId)
  );
  console.log({ rootDir, workingAppDirItem });
  await patchFileSystemUpdateAccess({
    id: workingAppDirItem?.id,
    itemType: workingAppDirItem?.type,
    access: {
      userGroupId,
      accessLevel,
    },
  });

  return { data, error };
};

const ShareDirectoryItemModalTrigger = ({
  directoryItem,
  isPipelineItem = false,
  trigger = (
    <ActionOption>
      <ShareIcon /> Share
    </ActionOption>
  ),
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sharedWith, setSharedWith] = useState(directoryItem.sharedWith || []);
  const [isPatching, setIsPatching] = useState(null);
  const [error, setError] = useState(null);
  const [membersAndTeams, setMembersAndTeams] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    doPopulateUserNamesAndGroupNames();
  }, [isOpen]);

  useEffect(() => {
    setSharedWith(directoryItem.sharedWith);
  }, [directoryItem.sharedWith]);

  const doPatchUpdateAccess = async ({ userGroupId, accessLevel }) => {
    setError(null);
    if (userGroupId === "") {
      return;
    }
    setIsPatching(userGroupId);

    if (isPipelineItem) {
      const { data, error } = await doSharePipeline(directoryItem?.id, {
        userGroupId,
        accessLevel,
      });

      setSharedWith(data?.sharedWith || sharedWith);
      setError(error);
      setIsPatching(null);
      return;
    }

    let itemType = directoryItem.type;
    if (itemType === "CHAT_DOC") {
      itemType = "WORD_DOC";
    }

    const { data, error } = await patchFileSystemUpdateAccess({
      id: directoryItem.id,
      itemType,
      access: {
        userGroupId,
        accessLevel,
      },
    });

    setSharedWith(data?.sharedWith || sharedWith);
    setError(error);
    setIsPatching(null);
  };

  const doPopulateUserNamesAndGroupNames = async () => {
    const { data } = await getMembersAndTeams();
    setMembersAndTeams(data || []);
  };

  const ownerId = sharedWith?.find(
    contributor => contributor?.accessLevel === "OWNER"
  )?.userGroupId;

  if (isOpen) {
    console.log({ directoryItem, membersAndTeams, sharedWith });
  }

  return (
    <>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <FileName>
            Share <BoldSpan>{directoryItem.fileName}</BoldSpan>
          </FileName>
          <SharedWithText>Add people:</SharedWithText>
          <InputAndAddButton>
            <StyledSearchablePopoverItems
              items={membersAndTeams?.filter(
                member => member?.userGroupId !== ownerId
              )}
              onFilter={(item, searchText) =>
                item?.userGroupId?.includes(searchText) ||
                item?.name?.includes(searchText)
              }
              placeholder="Name or email"
              renderItem={item => (
                <ItemContainer
                  key={item?.userGroupId}
                  onMouseDown={() => {
                    doPatchUpdateAccess({
                      userGroupId: item?.userGroupId,
                      accessLevel: "CONTRIBUTOR",
                    });
                  }}
                >
                  {item?.type === "TEAM" ? <PeopleIcon /> : <PersonBlankIcon />}
                  <ShortDiv>{item?.name || item?.userGroupId}</ShortDiv>
                </ItemContainer>
              )}
            />
          </InputAndAddButton>

          <ContirbutorsContainer>
            {sharedWith?.map(contributor => {
              const memberOrTeam = membersAndTeams.find(
                item => item?.userGroupId === contributor?.userGroupId
              );

              return (
                <Contributor
                  key={contributor.userGroupId}
                  isDisabled={isPatching === contributor?.userGroupId}
                >
                  {memberOrTeam?.type === "TEAM" ? (
                    <PeopleIcon />
                  ) : (
                    <PersonBlankIcon />
                  )}
                  <ShortDiv>
                    {memberOrTeam?.name ||
                      memberOrTeam?.userGroupId ||
                      contributor?.userGroupId}
                  </ShortDiv>
                  <GreySpan>{contributor?.accessLevel}</GreySpan>
                  <StyledCrossIcon
                    onClick={() =>
                      doPatchUpdateAccess({
                        userGroupId: contributor?.userGroupId,
                        accessLevel: "NO_ACCESS",
                      })
                    }
                    isHidden={contributor?.accessLevel === "OWNER"}
                  />
                </Contributor>
              );
            })}
          </ContirbutorsContainer>
          {error && <ErrorMsg>{error?.message}</ErrorMsg>}
        </ModalContent>
      </Modal>
      <div onClick={() => setIsOpen(true)}>{trigger}</div>
    </>
  );
};

export default ShareDirectoryItemModalTrigger;
