import { ChatIcon } from "components/IconsNew";
import { ExcelIcon, PdfIcon, SlidesIcon, WordIcon } from "components/ui/Icons";
import useSearchParamsState from "hooks/useSearchParamsState";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const ACTIVE_COLOR = "#ffffff88";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;

  svg {
    cursor: pointer;
    padding: 2px;
    border-radius: 4px;
    height: 20px;

    :hover {
      background-color: #f0f0f055;
    }
  }
`;

const StyledInput = styled.input`
  width: 100%;
  color-scheme: dark;
  outline: none;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #757575;
  border-radius: 4px;
  background-color: transparent;
  padding: 4px;
  :focus {
    border: 1px solid #0191ff;
  }
  font-weight: 500;
`;

const StyledWordIcon = styled(WordIcon)`
  ${props => props?.isActive && `background-color: ${ACTIVE_COLOR};`}
`;

const StyledExcelIcon = styled(ExcelIcon)`
  ${props => props?.isActive && `background-color: ${ACTIVE_COLOR};`}
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  ${props => props?.isActive && `background-color: ${ACTIVE_COLOR};`}
`;

const StyledPdfIcon = styled(PdfIcon)`
  ${props => props?.isActive && `background-color: ${ACTIVE_COLOR};`}
`;

const DirItemTypesFilter = ({ isPdfEnabled = false }) => {
  const [sidebar, setSidebar] = useSearchParamsState({
    paramName: "sidebar",
    initialValue: {},
  });

  const toggleFilterType = type => {
    const filterTypes = sidebar?.filterTypes || [];
    const newFilterTypes = filterTypes.includes(type)
      ? filterTypes.filter(t => t !== type)
      : [...filterTypes, type];

    setSidebar({ ...sidebar, filterTypes: newFilterTypes });
  };

  return (
    <Container>
      <StyledInput
        value={sidebar?.filterText || ""}
        onChange={e => setSidebar({ ...sidebar, filterText: e.target.value })}
        placeholder="File name"
      />
      <StyledWordIcon
        isActive={sidebar?.filterTypes?.includes("WORD_DOC")}
        onClick={() => toggleFilterType("WORD_DOC")}
      />
      <StyledExcelIcon
        isActive={sidebar?.filterTypes?.includes("EXCEL_MODEL")}
        onClick={() => toggleFilterType("EXCEL_MODEL")}
      />
      <StyledSlidesIcon
        isActive={sidebar?.filterTypes?.includes("SLIDE_DOC")}
        onClick={() => toggleFilterType("SLIDE_DOC")}
        style={{ fill: "#d4aa00" }}
      />
      {isPdfEnabled && (
        <StyledPdfIcon
          isActive={sidebar?.filterTypes?.includes("FILE")}
          onClick={() => toggleFilterType("FILE")}
        />
      )}
    </Container>
  );
};

export default DirItemTypesFilter;
