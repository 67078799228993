import { MagnifyingGlassIcon } from "components/IconsNew";
import Modal from "components/ui/Modal";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import styled from "styled-components";
import Highcharts from "highcharts";

const Trigger = styled.div`
  opacity: 0.4;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px;

  svg {
    height: 14px;
    fill: black;
  }
`;

const OPTIONS = {
  chart: {
    type: "pie",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: [
        {
          enabled: true,
          distance: 20,
        },
      ],
    },
  },
  tooltip: {
    valueSuffix: "%",
  },
  series: [
    {
      name: "",
      colorByPoint: true,
      data: [
        // {
        //   name: "Water",
        //   y: 55.02,
        // },
        // {
        //   name: "Fat",
        //   y: 26.71,
        // },
        // {
        //   name: "Carbohydrates",
        //   y: 1.09,
        // },
        // {
        //   name: "Protein",
        //   y: 15.5,
        // },
        // {
        //   name: "Ash",
        //   y: 1.68,
        // },
      ],
    },
  ],
};

const ModalContent = styled.div`
  background-color: white;
  min-width: 600px;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  gap: 20px;
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
  }
`;

const Tr = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const InpsectModal = ({
  columnNames = [],
  rowValues = [],
  data = [],
  title = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let options = OPTIONS;
  options.title.text = title;
  options.series[0].data = data;

  return (
    <>
      <Trigger onClick={() => setIsOpen(true)}>
        <MagnifyingGlassIcon />
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title>Inspect result</Title>
          <table>
            <thead>
              <tr>
                {columnNames?.map((name, index) => {
                  if (name === "inspect") {
                    return null;
                  }

                  return <Th key={index}>{name}</Th>;
                })}
              </tr>
            </thead>
            <tbody>
              <Tr>
                {rowValues?.map((value, index) => {
                  if (columnNames[index] === "inspect") {
                    return null;
                  }

                  return <Td key={index}>{value}</Td>;
                })}
              </Tr>
            </tbody>
          </table>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default InpsectModal;
