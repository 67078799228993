import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { getColorFromString } from "utils/common";
import { useState } from "react";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const Name = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

const NameAndInput = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 8px;
`;

export const ANIMATION_MS = 1800;

export const PieParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, valueColumnName } = params;

  if (isEditing) {
    return (
      <div>
        <NameAndInput>
          <Name>Category:</Name>
          <StyledSelect
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose category column --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>

        <NameAndInput>
          <Name>Value:</Name>
          <StyledSelect
            value={valueColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                valueColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose value column --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>
      </div>
    );
  }

  return (
    <WrapContainer>
      <Label
        style={{ backgroundColor: `${getColorFromString(xColumnName)}55` }}
      >
        {valueColumnName}
      </Label>
    </WrapContainer>
  );
};

const OPTIONS = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  legend: {
    align: "right",
    verticalAlign: "top",
    layout: "vertical",
    itemStyle: {
      fontSize: "0.8em",
    },
  },
  title: {
    text: "",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },

  series: [
    {
      name: "",
      colorByPoint: true,
      data: [],
    },
  ],
};

const PieChartSmallNew = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
}) => {
  const { xColumnName, valueColumnName } = params;
  const [options, setOptions] = useState(OPTIONS);

  useEffect(() => {
    const seriesData = records?.map(record => {
      return {
        name: record?.[xColumnName]?.value,
        y: parseFloat(record?.[valueColumnName]?.value),
      };
    });

    const newOptions = cloneDeep(options);
    newOptions.series[0].data = seriesData;
    newOptions.chart.height = 100;

    setOptions(newOptions);
  }, [xColumnName, valueColumnName, JSON.stringify(records)]);

  return (
    <div style={{ marginBottom: 0 }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PieChartSmallNew;
