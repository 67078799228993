import { useState } from "react";
import styled from "styled-components";

import { CrossIcon, GearIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import ButtonWord from "components/ui/ButtonWord";
import { useEffect } from "react";
import { Gap } from "components/Layout";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { isEmpty } from "lodash";

const ModalTrigger = styled.div`
  cursor: pointer;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  width: 600px;
  height: 400px;

  display: grid;
  grid-template-rows: 1fr auto;
`;

const BottomBar = styled.div`
  display: grid;
  justify-content: end;
  padding-top: 10px;
  border-top: 1px solid ${props => props.theme.color.closer1};
`;

const Container = styled.div`
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Categories = styled.div`
  display: flex;
  gap: 8px;
`;

const Category = styled.div`
  background-color: #f3f5f7;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 500;

  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;

  svg {
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const KanbanConfigModal = ({
  value = {},
  columns = [],
  columnValues = [],
  onPressSave = newValue => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedValue, setEditedValue] = useState(value || []);
  const [kanbanColumnValues, setKanbanColumnValues] = useState([]);

  useEffect(() => {
    setEditedValue(value || "");
  }, [JSON.stringify(value)]);

  const categoricalColumns = columns.filter(
    column => column?.formatType === "Categorical"
  );
  const selectedColumn = columns.find(column => column?.dbName === editedValue);

  useEffect(() => {
    setKanbanColumnValues(selectedColumn?.categories || []);
  }, [JSON.stringify(selectedColumn)]);

  useEffect(() => {
    if (isEmpty(columnValues)) {
      setKanbanColumnValues(selectedColumn?.categories || []);
    }
  }, [JSON.stringify(columnValues)]);

  const moveColumn = (index, direction) => {
    const newValues = [...kanbanColumnValues];
    const temp = newValues[index];

    let newIndex = index + direction;
    if (newIndex < 0 || newIndex >= newValues.length) {
      return;
    }

    newValues[index] = newValues[newIndex];
    newValues[newIndex] = temp;

    setKanbanColumnValues(newValues);
  };

  return (
    <>
      <ModalTrigger onClick={() => setIsOpen(true)}>
        <GearIcon />
      </ModalTrigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Container>
            <Title>Choose kanban column</Title>
            <Gap />
            <div style={{ display: "flex", gap: 10 }}>
              <StyledSelect
                value={editedValue}
                onChange={e => setEditedValue(e.target.value)}
              >
                <option value="">Select a column</option>
                {categoricalColumns.map((column, i) => (
                  <option key={column?.dbName} value={column?.dbName}>
                    {column?.displayName}
                  </option>
                ))}
              </StyledSelect>
            </div>
            <Gap />
            {selectedColumn?.categories?.length && (
              <Title>Kanban columns</Title>
            )}
            <Gap />
            <Categories>
              {kanbanColumnValues?.map((category, i) => (
                <Category key={category}>
                  {category?.split(",")?.[0]}
                  <ArrowLeft onClick={() => moveColumn(i, -1)} />
                  <ArrowRight onClick={() => moveColumn(i, 1)} />
                </Category>
              ))}
            </Categories>
          </Container>
          <BottomBar>
            <ButtonWord
              onClick={() => {
                onPressSave(editedValue, kanbanColumnValues);
                setIsOpen(false);
              }}
            >
              Save
            </ButtonWord>
          </BottomBar>
        </ModalContent>
      </Modal>
    </>
  );
};

export default KanbanConfigModal;
