import { CircularProgress } from "@material-ui/core";
import { Audiotrack } from "@material-ui/icons";
import { postPromptRecommendation } from "api/backend/searchServiceEndpoints";
import { decodeStoredJwt } from "api/services/authenticationService";
import DocSourcesModalTriggerNew from "components/DocSourcesModalTriggerNew";
import {
  DocSearchIcon,
  PlusInCircleIcon,
  SourcesIcon,
} from "components/IconsNew";
import TextAreaExpanding from "components/TextAreaExpanding";
import TickableDirItems from "components/TickableDirItems";
import { ButtonIconTransparent } from "components/ui/ButtonIcon";
import {
  ExcelIcon,
  FolderIcon,
  InternetIcon,
  PdfIcon,
  PlusIcon,
  SigmaIcon,
  SlidesIcon,
  WordIcon,
} from "components/ui/Icons";
import useClickOutside from "hooks/useClickOutside";
import { isNil } from "lodash";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

export const BG_INPUT = "#eeeeee";

const StyledWordIcon = styled(WordIcon)`
  height: 14px;
`;

const StyledExcelIcon = styled(ExcelIcon)`
  height: 14px;
`;

const StyledSlidesIcon = styled(SlidesIcon)`
  height: 14px;
  fill: #d4aa00;
`;

const StyledFolderIcon = styled(FolderIcon)`
  width: 12px;
`;

const StyledPdfIcon = styled(PdfIcon)`
  width: 12px;
`;

const SuggestionsContainer = styled.div`
  position: fixed;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 3;

  /* border: 2px solid #e8ecef; */
  display: grid;
  grid-template-columns: 1fr 300px;
  ${props => props.isOneColumn && "grid-template-columns: 1fr;"}
  border-top: none;
  border-radius: 0px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  max-height: 400px;
  overflow: auto;
  /* box-shadow: 0px 0px 5px #b7b7b7; */
  padding-bottom: 0px;
  background-color: white;

  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;

  /* border-top: none; */
  /* border-radius: 0px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px; */

  ::-webkit-scrollbar {
    display: none;
  }
  background-color: white;

  @media (max-width: 1240px) {
    grid-template-columns: 1fr 0px;
    display: none;
  }
`;

const DropShadow = styled.div`
  position: fixed;
  z-index: -1;
  /* box-shadow: 0px 0px 5px #b7b7b7; */
  pointer-events: none;
  border-radius: 12px;
`;

const SuggestedQueries = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-content: start;

  min-height: 100px;
  max-height: 210px;
  overflow-y: scroll;
`;

const Suggestions = styled.div`
  min-height: calc(45px * 4);
`;

const Suggestion = styled.div`
  padding: 14px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.2;
  align-items: center;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;

  :hover {
    background-color: #eaeaea;
  }
`;

const DividerLineHorizontal = styled.div`
  height: 1px;
  width: calc(100% - 20px);
  background-color: #00000022;
  margin: 0 auto;
`;

const SourcesContainer = styled.div`
  border-left: 1px solid #00000022;
  background-color: white;
  height: 214px;
  /* overflow-y: auto; */
`;

const SmallTitle = styled.div`
  padding: 8px 10px;
  font-weight: 500;
  font-size: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr auto auto;
  gap: 10px;
  padding-top: 16px;
  color: #00000055;

  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

const SmallSuggestionsTitle = styled.div`
  padding: 8px 12px;
  font-weight: 500;
  font-size: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 10px;
  background-color: white;
  color: #00000055;
  padding-top: 16px;
  /* font-style: italic; */

  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  svg {
    color: #0191ff;
  }
`;

const SUGGESTIONS = [];

const StyledSigmaIcon = styled(SigmaIcon)`
  width: 10px;
  margin-right: 2px;
`;

const StyledAudioIcon = styled(Audiotrack)``;

const StyledPlusIcon = styled(PlusIcon)`
  width: 12px;
`;

const StyledTextAreaExpanding = styled(TextAreaExpanding)`
  width: 100%;
  padding: 12px 20px;
  padding-right: 80px;
  /* padding-left: 48px; */
  font-weight: 400;
  border-radius: 12px;
  border: none;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
  z-index: 1;
  max-height: 200px;
  line-height: 1.2;
  background-color: ${BG_INPUT};

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

const PlusContainer = styled.div`
  height: 18px;
  width: 18px;
  top: 0;
  right: 4px;
  z-index: 2;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 2;
`;

const INACTIVE_COLOR = "#7e7e7e";
const InternetLabel = styled.div`
  background-color: ${props =>
    props?.isActive ? props?.bgColor : INACTIVE_COLOR}22;
  width: max-content;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;

  :hover {
    background-color: ${props =>
      props?.isActive ? props?.bgColor : INACTIVE_COLOR}44;
  }
  svg {
    height: 16px;
    fill: ${props => (props?.isActive ? props?.bgColor : INACTIVE_COLOR)};
  }
`;

const INTERNET_COLOR = "#0191ff";
const DB_COLOR = "#d18f00";
const FILES_COLOR = "#d9534f";

export const getItemUrl = item => {
  if (item?.type === "SIGMA_TEMPLATE") {
    return `/trial-dashboards/${item?.fileId}/source`;
  }

  if (item?.type === "FILE") {
    return `/files/${item?.fileId}`;
  }

  if (item?.type === "WORD_DOC") {
    return `/word-docs/${item?.fileId}`;
  }

  if (item?.type === "EXCEL_MODEL") {
    return `/excel-views/${item?.fileId}`;
  }

  if (item?.type === "SLIDE_DOC") {
    return `/slide-docs/${item?.fileId}`;
  }

  return "";
};

const TYPE_TO_ICON = {
  WORD_DOC: <StyledWordIcon />,
  EXCEL_MODEL: <StyledExcelIcon />,
  SLIDE_DOC: <StyledSlidesIcon />,
  DIRECTORY: <StyledFolderIcon />,
  FILE: <StyledPdfIcon />,
  SIGMA_TEMPLATE: <StyledSigmaIcon />,
};

export const getItemIcon = item => {
  if (item?.isAudioFile) {
    return (
      <StyledAudioIcon
        style={{ width: "12px", height: "12px", marginTop: "2px" }}
      />
    );
  }

  return TYPE_TO_ICON[item?.type];
};

const ButtonsBackground = styled.div`
  background: linear-gradient(to right, #ffffff00, #ffffff 24%);
  height: 100%;
`;

const BarPlusIconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 27px;
  transform: translateY(-50%);
  left: 16px;
  z-index: 2;
  width: 22px;
  height: 22px;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  :hover {
    background-color: #b6b6b6;
  }

  @media (max-width: 1240px) {
    display: none;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;

  border: 2px solid #e8ecef;
  border-radius: 12px;
  background: linear-gradient(${BG_INPUT}, ${BG_INPUT}) padding-box,
    linear-gradient(to right, ${COLOR2}, ${COLOR1}) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
`;

const StyledButtonIconTransparent = styled(ButtonIconTransparent)`
  height: 16px;
  width: 16px;
  top: 0;
  right: 4px;
  z-index: 2;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: span 2;
`;

const ScrollingItemsContainer = styled.div`
  overflow: auto;
  height: 130px;
`;

const SourcesActions = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 6px;
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #d1d1d1;
  background-color: white;
  padding: 2px;
  border-radius: 4px;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const BottomIcons = styled.div`
  z-index: 2;
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 4px 16px;
  padding-top: 0;
`;

const APP_NAMES = [
  "Syndicates",
  "Transaction",
  "Glimpse Sample Data",
  "Transactional Data",
  "Liquidnet Syndicates",
];

const InputWithBottomSuggestionsAndSources = ({
  blocks = [],
  className,
  value,
  onChange = e => {},
  placeholder,
  disabled,
  onKeyDown = e => {},
  onClickSuggestion = text => {},
  id = "",
  sources = null,
  setSources = () => {},
  userSources = [],
  setUserSources = () => {},
  externalSuggestions = [],
  shouldSearchInternet = false,
  setShouldSearchInternet = () => {},
  shouldUseFiles = false,
  setShouldUseFiles = () => {},
  shouldUsePipeline = false,
  setShouldUsePipeline = () => {},
  canUseDb = true,
  suggestionsPosition = "top",
}) => {
  const [searchParams] = useSearchParams();

  const [isShowingSourcesHelp, setIsShowingSourcesHelp] = useState(false);
  const [isShowingSuggestionsHelp, setIsShowingSuggestionsHelp] =
    useState(false);

  const tokenObj = decodeStoredJwt();
  const isTrialUser = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");

  const [suggestions, setSuggestions] = useState(SUGGESTIONS);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sourcesContainerRef = useRef(null);
  const suggestionsContainerRef = useRef(null);
  // const containerRef = useRef(null);

  const [filesFilter, setFilesFilter] = useState("");
  const ref = useRef(null);

  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);

  const [rect, setRect] = useState(null);

  useClickOutside(ref, () => {
    setAreSuggestionsVisible(false);
  });

  useEffect(() => {
    // close suggestions when resizing, to prevent them getting stuck
    const onResize = e => {
      setRect(ref?.current?.getBoundingClientRect());
      ref.current.blur();
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref?.current]);

  useEffect(() => {
    setIsShowingSourcesHelp(false);
    setIsShowingSuggestionsHelp(false);
  }, [value]);

  useEffect(() => {
    if (searchParams.get("isShowingSourcesHelp") === "true") {
      setIsShowingSourcesHelp(true);
    }
    if (searchParams.get("isShowingSuggestionsHelp") === "true") {
      setIsShowingSuggestionsHelp(true);
    }
  }, [
    searchParams.get("isShowingSourcesHelp"),
    searchParams.get("isShowingSuggestionsHelp"),
  ]);

  const doPopulateSuggestions = async () => {
    setIsFetchingSuggestions(true);
    const { data } = await postPromptRecommendation(
      {},
      {
        blocks,
        shouldSearchInternet,
        sources: userSources || [],
      }
    );
    setIsFetchingSuggestions(false);
    setSuggestions(data || []);
  };

  const suggestionsContainerRect =
    suggestionsContainerRef.current?.getBoundingClientRect();

  let suggestionsToUse = suggestions || [];
  if (externalSuggestions?.length > 0) {
    suggestionsToUse = externalSuggestions;
  }

  if (value?.endsWith("app:")) {
    suggestionsToUse = APP_NAMES;
  }

  useEffect(() => {
    const newRect = ref.current?.getBoundingClientRect();
    setRect(newRect);
  }, [
    ref.current,
    value,
    searchParams?.get("sidebar"),
    isModalOpen,
    areSuggestionsVisible,
  ]);

  useEffect(() => {
    setAreSuggestionsVisible(false);
  }, [searchParams?.get("sidebar")]);

  const onTextAreaKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      ref.current.blur();
      setAreSuggestionsVisible(false);
    }
    onKeyDown(e);
  };

  let suggestionsStyle = {
    visibility: areSuggestionsVisible || isModalOpen ? "visible" : "hidden",
    top: rect?.top - 218,
    left: rect?.left,
    width: rect?.width,
    zIndex: 1,
  };
  if (suggestionsPosition === "bottom") {
    suggestionsStyle = {
      visibility: areSuggestionsVisible || isModalOpen ? "visible" : "hidden",
      top: rect?.top + rect?.height,
      left: rect?.left,
      width: rect?.width,
      zIndex: 1,
    };
  }

  return (
    <Container ref={ref}>
      <StyledTextAreaExpanding
        id={id}
        // className={className}
        // externalRef={ref}
        value={value}
        onChange={onChange}
        onKeyDown={onTextAreaKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => {
          doPopulateSuggestions();
          setAreSuggestionsVisible(true);
        }}
      />
      <BottomIcons>
        <InternetLabel
          isActive={shouldSearchInternet}
          bgColor={INTERNET_COLOR}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setShouldSearchInternet(!shouldSearchInternet);
          }}
          onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <InternetIcon />
        </InternetLabel>
        {!isTrialUser && (
          <InternetLabel
            isActive={shouldUseFiles}
            bgColor={FILES_COLOR}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShouldUseFiles(prev => !prev);
            }}
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SourcesIcon />
          </InternetLabel>
        )}
      </BottomIcons>

      <SuggestionsContainer
        id="suggestions"
        style={suggestionsStyle}
        isOneColumn={isTrialUser}
      >
        <DropShadow
          style={{
            width: rect?.width,
            height: rect?.height + suggestionsContainerRect?.height,
            top: rect?.top,
            left: rect?.left,
          }}
        />
        <DividerLineHorizontal style={{ gridColumn: "span 2" }} />
        <SuggestedQueries ref={suggestionsContainerRef}>
          <SmallSuggestionsTitle>
            Recommendations
            {isFetchingSuggestions && (
              <CircularProgress size={12} thickness={10} />
            )}
          </SmallSuggestionsTitle>
          <Suggestions id="suggestions-list">
            {suggestionsToUse?.map((suggestion, i) => {
              return (
                <Suggestion
                  style={{ width: isTrialUser ? "100%" : "495px" }}
                  className="suggestion"
                  data-index={i}
                  key={suggestion}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (value?.endsWith("app:")) {
                      onClickSuggestion(`${value} ${suggestion}`);
                      return;
                    }

                    onClickSuggestion(suggestion);
                  }}
                >
                  {suggestion}
                </Suggestion>
              );
            })}
          </Suggestions>
        </SuggestedQueries>
        <SourcesContainer
          ref={sourcesContainerRef}
          style={{ display: isTrialUser ? "none" : "block" }}
        >
          <SmallTitle
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto 1fr",
              justifyItems: "start",
            }}
          >
            <InternetLabel
              isActive={shouldUseFiles}
              bgColor={FILES_COLOR}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShouldUseFiles(prev => !prev);
              }}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SourcesIcon />
              <DocSourcesModalTriggerNew
                alreadyAddedSources={sources}
                onAddSources={newSources => {
                  setSources([...sources, ...newSources]);
                }}
                onClose={() => setIsModalOpen(false)}
                trigger={
                  <StyledButtonIconTransparent
                    onClick={e => {
                      setIsModalOpen(true);
                    }}
                    isActive={sources?.length > 0}
                  >
                    <StyledPlusIcon height="12px" />
                  </StyledButtonIconTransparent>
                }
              />
            </InternetLabel>

            <InternetLabel
              isActive={shouldSearchInternet}
              bgColor={INTERNET_COLOR}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShouldSearchInternet(!shouldSearchInternet);
              }}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <InternetIcon />
            </InternetLabel>

            {canUseDb && (
              <InternetLabel
                isActive={shouldUsePipeline}
                bgColor={DB_COLOR}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShouldUsePipeline(prev => !prev);
                }}
                onMouseDown={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <DocSearchIcon />
              </InternetLabel>
            )}
          </SmallTitle>
          <SourcesActions style={{ display: shouldUseFiles ? "flex" : "none" }}>
            <input
              checked={isNil(sources)}
              onChange={e => {
                if (e.target.checked) {
                  setSources(null);
                  return;
                }
                setSources([]);
              }}
              type="checkbox"
            />
            <Input
              placeholder="Filter files"
              value={filesFilter}
              onChange={e => setFilesFilter(e.target.value)}
            />
          </SourcesActions>
          <ScrollingItemsContainer
            style={{ display: shouldUseFiles ? "block" : "none" }}
            onClick={e => {
              e.preventDefault();
            }}
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <TickableDirItems
              filesFilter={filesFilter}
              isAlwaysOpen
              directoryPath="/source-files"
              sources={sources}
              areSuggestionsVisible={areSuggestionsVisible}
              onItemInputChange={item => {
                const isInSources = !!sources.find(
                  source => source?.fileId === item?.id
                );

                if (!isInSources) {
                  const newSource = {
                    fileId: item?.id,
                    fileName: item?.fileName,
                    type: item?.type,
                  };
                  setSources(prev => [...(prev || []), newSource]);
                  return;
                }
                setSources(
                  sources.filter(source => source?.fileId !== item?.id)
                );
              }}
            />
          </ScrollingItemsContainer>
        </SourcesContainer>
      </SuggestionsContainer>
    </Container>
  );
};

export default InputWithBottomSuggestionsAndSources;
