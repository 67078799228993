import { Save } from "@material-ui/icons";
import {
  BoldIcon,
  BulletedListIcon,
  DecreaseIndentIcon,
  HeighlighterIcon,
  ImageIcon,
  IncreaseIndentIcon,
  ItalicIcon,
  LinkIcon,
  NumberedListIcon,
  StrikethroughIcon,
  TableIcon,
  TextColorIcon,
  UnderlineIcon,
} from "components/IconsNew";
import ButtonWord from "components/ui/ButtonWord";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import { fi } from "date-fns/locale";
import useClickOutside from "hooks/useClickOutside";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  BULLET_1,
  FONT_SIZE,
  getListParagraphIndex,
  getPrevNewlineIndex,
  getStartEnd,
  isListParagraph,
} from "utils/word-utils-refactor";

const getSelectionStyle = doc => {
  const [start, end] = getStartEnd(doc);
  const selectedStyles = doc?.styles.slice(start, end);
  const isBullet = isListParagraph({ text: doc?.text, i: start });

  if (!selectedStyles?.length) {
    return { isBold: false, isItalic: false, fontSize: 16, isBullet };
  }

  return {
    isBold: selectedStyles?.every(style => style?.isBold),
    isItalic: selectedStyles?.every(style => style?.isItalic),
    isUnderlined: selectedStyles?.every(style => style?.isUnderlined),
    isStrikethrough: selectedStyles?.every(style => style?.isStrikethrough),
    isBullet,
    //
    textColor:
      selectedStyles?.find(style => style?.textColor)?.textColor || "#000000",
    bgColor:
      selectedStyles?.find(style => style?.bgColor)?.bgColor || "transparent",
    url: selectedStyles?.find(style => style?.url),
    fontSize: selectedStyles?.find(style => style?.fontSize)?.fontSize || 16,
  };
};

const toggleStyle = (doc, styleField) => {
  const [start, end] = getStartEnd(doc);
  const selectedStyles = doc?.styles.slice(start, end);
  const isActive = selectedStyles?.every(style => style?.[styleField]);

  const newDoc = cloneDeep(doc);
  let i = start;
  while (i < end) {
    if (isActive) {
      delete newDoc.styles[i][styleField];
      if (isEmpty(newDoc.styles[i])) {
        newDoc.styles[i] = null;
      }
    } else {
      newDoc.styles[i] = {
        ...newDoc.styles[i],
        [styleField]: true,
      };
    }
    i++;
  }

  return newDoc;
};

const addStyleField = (doc, fieldName, fieldValue) => {
  const newDoc = cloneDeep(doc);

  if (fieldName === "fontSize") {
    const fontSize = parseInt(fieldValue);

    const [start, end] = getStartEnd(doc);
    let i = start;
    while (i < end) {
      if (fontSize === FONT_SIZE) {
        delete newDoc.styles[i]?.fontSize;
        if (isEmpty(newDoc.styles[i])) {
          newDoc.styles[i] = null;
        }
      } else {
        newDoc.styles[i] = {
          ...newDoc.styles[i],
          fontSize,
        };
      }
      i++;
    }
  }

  if (fieldName === "url") {
    const [start, end] = getStartEnd(doc);
    let i = start;
    while (i < end) {
      if (!fieldValue) {
        delete newDoc.styles[i]?.url;
        if (isEmpty(newDoc.styles[i])) {
          newDoc.styles[i] = null;
        }
      } else {
        newDoc.styles[i] = {
          ...newDoc.styles[i],
          url: fieldValue,
        };
      }
      i++;
    }
  }

  if (fieldName === "bgColor") {
    const [start, end] = getStartEnd(doc);
    let i = start;
    while (i < end) {
      if (fieldValue === "transparent") {
        delete newDoc.styles[i]?.bgColor;
        if (isEmpty(newDoc.styles[i])) {
          newDoc.styles[i] = null;
        }
      } else {
        newDoc.styles[i] = {
          ...newDoc.styles[i],
          bgColor: fieldValue,
        };
      }
      i++;
    }
  }

  if (fieldName === "textColor") {
    const [start, end] = getStartEnd(doc);
    let i = start;
    while (i < end) {
      if (fieldValue === "black") {
        delete newDoc.styles[i]?.bgColor;
        if (isEmpty(newDoc.styles[i])) {
          newDoc.styles[i] = null;
        }
      } else {
        newDoc.styles[i] = {
          ...newDoc.styles[i],
          textColor: fieldValue,
        };
      }
      i++;
    }
  }

  return newDoc;
};

const toggleBullet = doc => {
  const [start, end] = getStartEnd(doc);
  const bi = getListParagraphIndex(doc);

  if (!isNil(bi)) {
    const newDoc = cloneDeep(doc);
    const textWithoutBullets = doc?.text
      ?.slice(bi, end)
      ?.replaceAll(BULLET_1, "\n");
    newDoc.text =
      newDoc.text.slice(0, bi) + textWithoutBullets + newDoc.text.slice(end);

    return newDoc;
  }

  const newDoc = cloneDeep(doc);

  let i = start;
  while (doc?.text[i] !== "\n" && i > 0) {
    i--;
  }

  let cutFrom = i;
  if (i === 0) {
    cutFrom = 0;
  }

  const textWithBullets = doc?.text
    ?.slice(cutFrom, end)
    ?.replaceAll("\n", BULLET_1);

  newDoc.text =
    newDoc.text.slice(0, cutFrom) + textWithBullets + newDoc.text.slice(end);

  newDoc.styles[i] = null;

  return newDoc;
};

const increaseIndent = doc => {
  const [, end] = getStartEnd(doc);
  const firstPrefixIndex = getPrevNewlineIndex(doc);

  let prefixIndices = [firstPrefixIndex];
  let i = firstPrefixIndex + 1;
  while (i < end) {
    if (doc.text[i] === "\n") {
      prefixIndices.push(i);
    }
    i++;
  }

  const newDoc = cloneDeep(doc);
  prefixIndices.forEach(i => {
    let currentIndent = newDoc.styles?.[i]?.indent || 0;
    newDoc.styles[i] = {
      indent: currentIndent + 100,
    };
  });

  return newDoc;
};

const decreaseIndent = doc => {
  const [, end] = getStartEnd(doc);
  const firstPrefixIndex = getPrevNewlineIndex(doc);

  let prefixIndices = [firstPrefixIndex];
  let i = firstPrefixIndex + 1;
  while (i < end) {
    if (doc.text[i] === "\n") {
      prefixIndices.push(i);
    }
    i++;
  }

  const newDoc = cloneDeep(doc);
  prefixIndices.forEach(i => {
    let currentIndent = newDoc.styles?.[i]?.indent || 0;
    const newIndent = currentIndent - 100;
    if (newIndent <= 0) {
      newDoc.styles[i] = null;
      return;
    }
    newDoc.styles[i] = {
      indent: newIndent,
    };
  });

  return newDoc;
};

const StyledBulletedListIcon = styled(BulletedListIcon)``;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    fill: black;
    height: 28px;
    width: 28px;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    :hover {
      background-color: #eaeaea;
    }

    ${props => props.isActive && `background-color: #0191ff;`}
  }

  ${StyledBulletedListIcon} {
    padding: 4px;
  }
`;

let ACTIVE_STYLE = {
  backgroundColor: "#0191ff22",
};

const Label = styled.label`
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  gap: 4px;
  cursor: pointer;
`;

const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

const PopoverRect = styled.div`
  position: absolute;

  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;

  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  outline: none;
  font-family: "Montserrat", sans-serif;
`;

const LinkInput = ({
  style = {},
  isActive = false,
  onClickApply = url => {},
  onToggleOff = () => {},
}) => {
  const [url, setUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setUrl("");
  }, [isOpen]);

  useClickOutside(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <LinkIcon
        style={style}
        onClick={() => {
          if (isActive) {
            onToggleOff();
            return;
          }
          setIsOpen(!isOpen);
        }}
      />
      <PopoverRect
        style={{
          display: isOpen ? "flex" : "none",
          top: 28,
          left: 0,
        }}
      >
        <StyledInput
          placeholder="Enter URL"
          value={url}
          onChange={e => setUrl(e.target.value)}
          type="text"
        />
        <ButtonWord
          onClick={() => {
            setIsOpen(false);
            onClickApply(url);
          }}
        >
          Apply
        </ButtonWord>
      </PopoverRect>
    </div>
  );
};

const DocToolbar = ({
  doc = {},
  onDocChange = newDoc => {},
  onClickSave = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const doToggleStyle = styleField => {
    const newDoc = toggleStyle(doc, styleField);
    onDocChange(newDoc);
  };

  const doAddStyleField = (doc, fieldName, fieldValue) => {
    const newDoc = addStyleField(doc, fieldName, fieldValue);
    onDocChange(newDoc);
  };

  const doToggleBullet = doc => {
    const newDoc = toggleBullet(doc);
    onDocChange(newDoc);
  };

  const doIncreaseIndent = doc => {
    const newDoc = increaseIndent(doc);
    onDocChange(newDoc);
  };

  const doDecreaseIndent = doc => {
    const newDoc = decreaseIndent(doc);
    onDocChange(newDoc);
  };

  const factchecking = searchParams.get("factchecking") === "true";

  const onCheckChange = e => {
    if (factchecking) {
      searchParams.delete("factchecking");
    } else {
      searchParams.set("factchecking", "true");
    }

    navigate({ search: searchParams.toString() });
  };

  const selectionStyle = getSelectionStyle(doc);

  const {
    isBold,
    isItalic,
    fontSize,
    url,
    isUnderlined,
    isStrikethrough,
    bgColor,
    textColor,
    isBullet,
  } = selectionStyle;

  return (
    <Container>
      <Save onClick={onClickSave} style={{ padding: 4 }} />
      <BoldIcon
        style={isBold ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isBold")}
      />
      <ItalicIcon
        style={isItalic ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isItalic")}
      />
      <UnderlineIcon
        style={isUnderlined ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isUnderlined")}
      />
      <StrikethroughIcon
        style={isStrikethrough ? ACTIVE_STYLE : {}}
        onClick={() => doToggleStyle("isStrikethrough")}
      />
      <ColorPickerTooltip
        triggerIcon={<HeighlighterIcon style={{ padding: 7 }} />}
        selectedColor={bgColor}
        onNewColor={color => doAddStyleField(doc, "bgColor", color)}
      />
      <ColorPickerTooltip
        triggerIcon={<TextColorIcon style={{ padding: 7 }} />}
        selectedColor={textColor || "#000000"}
        onNewColor={color => doAddStyleField(doc, "textColor", color)}
      />
      <StyledBulletedListIcon
        style={isBullet ? ACTIVE_STYLE : {}}
        onClick={() => doToggleBullet(doc)}
      />
      <NumberedListIcon style={{ padding: 4 }} />
      <IncreaseIndentIcon onClick={() => doIncreaseIndent(doc)} />
      <DecreaseIndentIcon onClick={() => doDecreaseIndent(doc)} />
      <ImageIcon />
      <LinkInput
        isActive={url}
        style={url ? ACTIVE_STYLE : {}}
        onClickApply={url => doAddStyleField(doc, "url", url)}
        onToggleOff={() => doAddStyleField(doc, "url", "")}
      />
      <TableIcon />
      <Select
        value={fontSize}
        onChange={e => doAddStyleField(doc, "fontSize", e.target.value)}
      >
        <option value={16}>Normal text</option>
        <option value={20}>Sub title</option>
        <option value={28}>Title</option>
      </Select>
      {/* <Label>
        <input
          style={{ margin: 0 }}
          onChange={onCheckChange}
          type="checkbox"
          checked={factchecking}
        />
        Check
      </Label> */}
    </Container>
  );
};

export default DocToolbar;
