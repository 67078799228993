import { Visibility } from "@material-ui/icons";
import FileView from "components/FileView";
import FileViewModal from "components/FileViewModal";
import ReferenceModalSpan from "components/ReferenceModalSpan";
import ButtonWord from "components/ui/ButtonWord";
import { ChevronDownIcon, PdfIcon } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow: auto;
  display: grid;
  padding-bottom: 0px;
`;

const SourcesAndBtn = styled.div`
  height: ${props => (props.isExpanded ? `${props?.expandedHeight}` : "0px")};
  display: grid;
  transition: height 0.2s;
  overflow: hidden;
  max-height: 200px;
`;

const SourcesContainer = styled.div`
  padding: 8px 0;
  margin-left: 0px;
  padding-right: 4px;
  width: 100%;
  overflow-x: auto;
`;

const SourceCard = styled.div`
  position: relative;
  width: calc(100% / 4);
  flex-shrink: 0;
  height: 120px;
  background-color: white;
  border-radius: 18px;
  padding: 12px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid
    ${props => (props?.isSelected ? props.theme.color.primary : "transparent")};
  transition: background-color 0.2s;
  :hover {
    background-color: #f3f3f3;
    box-shadow: ${props => props.theme.shadowWeak};
  }
  font-weight: 500;
  padding-bottom: 0;

  display: grid;
  grid-template-rows: auto auto 1fr;
`;

const EyeContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 10px;
  opacity: 0.5;
  svg {
    height: 18px;
  }
  :hover {
    opacity: 1;
  }
`;

const CardLabel = styled.div`
  background-color: #e1e1e1;
  padding: 4px 8px;
  border-radius: 8px;
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TEXT = `This document contains information about the financial status of all subsidiary companies under Morgan Stanley`;

const Description = styled.div`
  padding-top: 8px;
  height: 100%;
  overflow: hidden;
  font-size: 10px;
  font-weight: 400;
  opacity: 0.8;
`;

const TwoColumns = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 500;
  justify-self: start;
  padding: 4px;
  cursor: pointer;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 18px;
  fill: none;
  stroke: black;
  transition: transform 0.2s;
`;

const DirItem = styled.div`
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 24px 18px auto 1fr;
  ${props => props.isThreeColumns && "grid-template-columns: 18px auto 1fr;"}
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  .trigger {
    opacity: 1;
    margin-left: 8px;
  }

  :hover {
    .trigger {
      opacity: 1;
    }
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

const SourcesCards = ({
  sourceOptions = [],
  userSources = [],
  setUserSources = () => {},
  isExpandable = true,
  isEditingDisabled = false,
  isInitiallyOpen = false,
  expandedHeight = "130px",
}) => {
  const [isExpanded, setIsExpanded] = useState(
    isExpandable && !isInitiallyOpen ? false : true
  );

  let expandText = `${userSources?.length} sources`;
  // if (isExpanded) {
  //   expandText = "Hide sources";
  // }

  const noSourcesSourceId = sourceOptions?.find(
    source => source?.needSearch === false
  );

  if (!userSources?.length) {
    return null;
  }

  return (
    <Container>
      {isExpandable && (
        <TwoColumns onClick={() => setIsExpanded(prev => !prev)}>
          <div>{expandText}</div>
          <StyledChevronDownIcon
            style={{
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </TwoColumns>
      )}

      <SourcesAndBtn isExpanded={isExpanded} expandedHeight={expandedHeight}>
        <SourcesContainer>
          {sourceOptions?.map(sourceOption => {
            const isSourceSelected =
              !!userSources?.find(
                userSource => userSource?.fileId === sourceOption?.fileId
              ) || false;

            return (
              <DirItem
                key={sourceOption.fileId}
                isThreeColumns={isEditingDisabled}
                onClick={() => {
                  if (isSourceSelected) {
                    setUserSources(prev =>
                      prev.filter(
                        userSource =>
                          userSource?.fileId !== sourceOption?.fileId
                      )
                    );
                    return;
                  }

                  setUserSources(prev => [...prev, sourceOption]);
                }}
              >
                {!isEditingDisabled && (
                  <input type="checkbox" checked={isSourceSelected} />
                )}
                <PdfIcon style={{ fill: "#ed1c24" }} />
                <FileViewModal
                  fileId={sourceOption?.fileId}
                  trigger={
                    <div
                      style={{
                        overflow: "hidden",
                        width: "100%",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {sourceOption?.fileName}
                    </div>
                  }
                />
                <FileViewModal fileId={sourceOption?.fileId} />
              </DirItem>
            );
          })}
        </SourcesContainer>
      </SourcesAndBtn>
    </Container>
  );
};

export default SourcesCards;
