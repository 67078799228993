import { useState } from "react";
import styled from "styled-components";

import {
  ChevronDownIcon,
  CrossIcon,
  FolderIcon,
  InformationIcon,
  PdfIcon,
  UploadCloudIcon,
  WarningIcon,
} from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { getDirectoryItems } from "api/services/fileSystem";
import { useEffect } from "react";
import ButtonIcon from "components/ui/ButtonIcon";
import { last, uniqBy } from "lodash";
import FileInputWithProgress from "components/FileInputWithProgress";
import { Gap } from "components/Layout";
import FileFromUrlInput from "components/FileFromUrlInput";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowLeft,
  Link,
  Visibility,
} from "@material-ui/icons";
import FileInputArea from "components/FileInputArea";
import FilesFromUrlsArea from "components/FilesFromUrlsArea";
import ButtonWord from "components/ui/ButtonWord";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignupModal from "components/SignupModal";
import { decodeStoredJwt } from "api/services/authenticationService";
import FileViewModal from "components/FileViewModal";
import {
  getItemIcon,
  getItemUrl,
} from "components/InputWithBottomSuggestionsAndSources";
import { ITEM_TYPE_TO_NAME_KEY } from "components/DirectoryItemsList";

const ModalTrigger = styled.div`
  display: grid;
  column-gap: 16px;
  row-gap: 0px;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;  
  `}
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;

  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`;

const SourcesTextAndIcons = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  justify-content: end;
  gap: 5px;
  border-radius: 0px;
  padding: 1px 8px;
  font-weight: 600;
  background-color: ${props => props.theme.color.closer0};
  border: 2px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const ModalContent = styled.div`
  border-radius: 4px;
  padding: 40px;
  height: 600px;
  width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
  align-items: start;
  grid-template-rows: auto auto 1fr auto;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  cursor: pointer;
  padding: 8px 20px;
  border-bottom: 2px solid
    ${props =>
      props?.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer0};
  ${props => props.isSelected && `color: ${props.theme.color.primary};`}
`;

const DirItems = styled.div`
  overflow: auto;
`;

const DirItem = styled.label`
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 24px 18px auto 1fr;
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}

  .trigger {
    opacity: 0;
    margin-left: 8px;
  }

  :hover {
    .trigger {
      opacity: 1;
    }
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

/*
            fontWeight: "500",
            justifySelf: "end",
            padding: "4px 20px",
            marginTop: "20px",
*/
const BlueButton = styled(ButtonIcon)`
  font-weight: 500;
  justify-self: end;
  align-self: end;
  padding: 4px 20px;
  margin-top: 20px;
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 8px;
  fill: none;
  stroke: black;
`;

const Card = styled.div`
  width: calc(100% / 2 - 10px);
  height: 100px;
  background-color: #e8ecefbf;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  display: grid;
  gap: 8px;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

const CardTitle = styled.div`
  line-height: 1.2;
  font-weight: 600;
`;

const BackAndInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const BackContainer = styled.div`
  opacity: 0.8;
  cursor: pointer;
  width: max-content;
  :hover {
    opacity: 1;
  }
`;

export const InfoMsg = styled.div`
  padding-top: 8px;
  display: flex;
  line-height: 1.2;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  color: ${props => props.theme.color.in_progress};
  * {
    fill: ${props => props.theme.color.in_progress};
  }
  a {
    color: ${props => props.theme.color.in_progress};
  }
`;

const Input = styled.input`
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: 1px solid #d1d1d1;
  background-color: white;
  padding: 4px;
  width: 200px;
  border-radius: 4px;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ITEM_TYPE_TO_ICON = {
  FILE: <PdfIcon style={{ fill: "#ed1c24" }} />,
  DIRECTORY: <FolderIcon />,
};

const DropArea = styled.div`
  width: 100%;
  height: 312px;
  border: 2px dashed #cdcdcd;
  color: #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  svg {
    height: 32px;
    margin-bottom: 16px;
    fill: #cdcdcd;
  }
  border-radius: 10px;
`;

const DirItemName = styled.a`
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000bf;
  font-weight: 500;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0;
  height: 12px;
  padding-right: 4px;
  fill: #666666;
  cursor: pointer;
  :hover {
    fill: #000;
  }
`;

const ModalCloseCrossIcon = styled(CrossIcon)`
  background-color: #eaeaea;
  padding: 6px;
  border-radius: 50%;
  height: 28px;
  cursor: pointer;
  :hover {
    background-color: #dcdcdc;
  }
`;

const getIconFromItem = item => {
  return ITEM_TYPE_TO_ICON?.[item?.type];
};

// const LAUNCH_STATES = [
//   {
//     id: "choose",
//     choices: [
//       "upload-register",
//       "gdrive-register",
//       "from-url",
//       "existing-register",
//     ],
//   },
//   {
//     id: "upload-register",
//     label: "Upload from local drive",
//     message:
//       "With a registered account, you can upload unlimited multiple PDFs",
//     skipTo: "upload",
//     icon: <UploadCloudIcon style={{ stroke: "black" }} />,
//   },
//   {
//     id: "upload",
//     label: "Upload from local drive",
//     icon: <UploadCloudIcon style={{ stroke: "black" }} />,
//   },
//   {
//     id: "gdrive-register",
//     label: "Upload from Google Drive",
//     message: "With a registered account, you can add files from Google Drive",
//     icon: <UploadCloudIcon style={{ stroke: "black" }} />,
//   },
//   { id: "from-url", label: "Scan webpage URL", icon: <Link /> },
//   { id: "from-url-register" },
//   {
//     id: "existing-register",
//     message:
//       "With a registered account, you can choose previously uploaded files here",
//     label: "Choose previously uploaded files",
//     icon: <FolderIcon />,
//   },
// ];

const LAUNCH_STATES = [
  {
    id: "choose",
    label: "Add sources",
    choices: ["upload", "googledrive", "from-url", "existing"],
  },
  {
    id: "upload",
    label: "Upload from local drive",
    signupMsg:
      "You are a trial user. Uploaded files cannot be reused for other chats. If you want to reuse files, <a href='/signup'>sign up</a> or <a href='/login'>login</a>.",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  {
    id: "googledrive",
    label: "Upload from Google Drive",
    msg: "Google Drive integration unavailable",
    signupMsg:
      "You are a trial user. Google Drive integration is available to logged in users only. <a href='/signup'>Sign up</a> or <a href='/login'>login</a>.",
    infoMsg: "Coming soon",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  {
    id: "from-url",
    label: "Scan webpage URL",
    signupMsg:
      "You are a trial user. Adding multiple URLs is only allowed for logged in user. Please <a href='/signup'>sign up</a> or <a href='/login'>login</a>.",
    icon: <Link />,
  },
  {
    id: "existing",
    label: "Choose uploaded files",
    msg: "Log in to see your uploaded files here",
    signupMsg:
      "You are a trial user. To use previously uploaded files please <a href='/signup'>sign up</a> or <a href='/login'>login</a>.",
    icon: <FolderIcon />,
  },
  {
    id: "confirm-exit",
    label: "Are you sure you want to exit without adding sources?",
  },
];

const STATES = [
  { id: "choose", choices: ["upload", "from-url", "existing"] },
  {
    id: "upload",
    label: "Upload from local drive",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  { id: "from-url", label: "Scan webpage URL", icon: <Link /> },
  {
    id: "existing",
    label: "Choose previously uploaded files",
    icon: <FolderIcon />,
  },
];

const SourceDirectoryItem = styled.div`
  position: relative;
  text-decoration: none;
  line-height: 1.2;
  display: grid;
  grid-template-columns: auto auto 1fr;
  padding: 2px 10px;
  gap: 8px;
  align-items: center;
  ${props => props.isDisabled && "opacity: 0.5;"}
  background-color: transparent;
  transition: background-color 0.1s;

  .trigger {
    opacity: 0;
    margin-right: 20px;
  }

  :hover {
    .trigger {
      opacity: 1;
    }
    background-color: #ededed;
    ${StyledCrossIcon} {
      background-color: #ededed;
      padding-left: 4px;
      opacity: 1;
    }
  }
`;

const SourcesContainer = styled.div`
  max-height: 100px;
  overflow: auto;
  border-bottom: 1px solid #eaeaea;
`;

const DirectoryItem = ({
  directoryPath = "",
  sources = [],
  onItemInputChange = (e, item) => {},
  isAlwaysOpen = false,
  alreadyAddedSources = [],
  filesFilter = "",
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [directoryItems, setDirectoryItems] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setDirectoryItems([]);
      return;
    }

    doPopulateRootDirectoryItems();
  }, [directoryPath, isOpen]);

  const doPopulateRootDirectoryItems = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setDirectoryItems(data?.items || []);
  };

  return (
    <>
      <DirItem
        style={{
          display: isAlwaysOpen ? "none" : "grid",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <StyledChevronDownIcon
          style={{
            justifySelf: "center",
            transform: `rotate(${isOpen ? "0deg" : "-90deg"})`,
            transition: "transform 0.1s",
          }}
        />
        <FolderIcon />
        {last(directoryPath?.split("/"))}
      </DirItem>
      <div style={{ paddingLeft: isAlwaysOpen ? 0 : 20 }}>
        {directoryItems
          ?.filter(item => {
            if (!filesFilter) {
              return true;
            }

            const doesNameMatch = item?.[ITEM_TYPE_TO_NAME_KEY[item?.type]]
              ?.toLowerCase()
              ?.includes(filesFilter?.toLowerCase());

            const doesPathMatch = item?.directoryPath
              ?.toLowerCase()
              ?.includes(filesFilter?.toLowerCase());

            return doesNameMatch || doesPathMatch;
          })
          ?.map(item => {
            if (item?.type === "DIRECTORY") {
              return (
                <DirectoryItem
                  key={item.id}
                  directoryPath={item?.path}
                  onItemInputChange={onItemInputChange}
                  sources={sources}
                  filesFilter={filesFilter}
                />
              );
            }

            const isChecked = sources?.find(
              source => source?.fileId === item?.id
            );
            const isAlreadyAdded = alreadyAddedSources?.find(
              source => source?.fileId === item?.id
            );

            return (
              <DirItem key={item.id} isDisabled={isAlreadyAdded}>
                <input
                  type="checkbox"
                  checked={isChecked || isAlreadyAdded}
                  disabled={isAlreadyAdded}
                  onChange={e => onItemInputChange(e, item)}
                />
                {getIconFromItem(item)}
                {item?.fileName}
                <FileViewModal fileId={item?.id} />
              </DirItem>
            );
          })}
      </div>
    </>
  );
};

const DocSourcesModalTriggerNew = ({
  alreadyAddedSources = [],
  onAddSources = () => {},
  onModifyAlreadyAddedSources = () => {},
  canRemoveSources = false,
  onClose = () => {},
  trigger = (
    <SourcesTextAndIcons style={{ gridRow: "1 / 3", gridColumn: 2 }}>
      <PdfIcon />
    </SourcesTextAndIcons>
  ),
  doneBtnText = "Add to chat",
  onOpen = () => {},
}) => {
  let ALL_STATES = LAUNCH_STATES;

  const [sources, setSources] = useState([]);
  const [stateId, setStateId] = useState(ALL_STATES[0].id);
  const [isOpen, setIsOpen] = useState(false);
  const [filesFilter, setFilesFilter] = useState("");

  const state = ALL_STATES.find(state => state.id === stateId);

  const tokenObj = decodeStoredJwt();
  const isTrialUser = tokenObj?.authorities?.includes("ROLE_TRIAL_USER");

  useEffect(() => {
    if (!isOpen) {
      setStateId(ALL_STATES[0].id);
      setSources([]);
      onClose();
    }

    if (isOpen) {
      onOpen();
    }
  }, [isOpen]);

  let modalContent = null;
  if (stateId === "choose") {
    modalContent = (
      <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        {state?.choices?.map(cardStateId => {
          const choiceState = ALL_STATES.find(
            state => state.id === cardStateId
          );
          return (
            <Card onClick={() => setStateId(choiceState?.id)}>
              {choiceState?.icon}
              <CardTitle>{choiceState?.label}</CardTitle>
            </Card>
          );
        })}
      </div>
    );
  }

  if (stateId === "googledrive") {
    modalContent = (
      <>
        <DropArea>{state?.msg}</DropArea>
        <InfoMsg>
          <WarningIcon style={{ fill: "black" }} />
          <div
            dangerouslySetInnerHTML={{
              __html: isTrialUser ? state?.signupMsg : state?.infoMsg,
            }}
          />
        </InfoMsg>
        <BlueButton
          isDisabled
          isActive
          onClick={() => {
            setIsOpen(false);
            onAddSources(sources);
          }}
        >
          {doneBtnText}
        </BlueButton>
      </>
    );
  }

  if (stateId === "confirm-exit") {
    modalContent = (
      <div
        style={{
          display: "grid",
          gap: 20,
          gridTemplateRows: "auto auto 1fr",
          height: "100%",
        }}
      >
        <div>
          <span style={{ fontWeight: "500" }}>
            {sources?.length} source{sources?.length > 1 && "s"}
          </span>{" "}
          to be added to chat:
        </div>
        <SourcesContainer>
          {sources?.map((source, i) => (
            <SourceDirectoryItem
              key={source.id}
              className="source"
              data-index={i}
            >
              {getItemIcon(source)}
              <DirItemName
                onMouseDown={e => e.preventDefault()}
                href={getItemUrl(source)}
                target="_blank"
              >
                {source?.fileName}
              </DirItemName>
              <FileViewModal fileId={source?.fileId} />
              <StyledCrossIcon
                style={{ position: "absolute", right: 8, opacity: 1 }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSources(
                    sources?.filter(item => item.fileId !== source.fileId)
                  );
                }}
                onMouseDown={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </SourceDirectoryItem>
          ))}
        </SourcesContainer>
        <div
          style={{
            display: "flex",
            gap: 10,
            alignSelf: "end",
            justifySelf: "end",
          }}
        >
          <BlueButton
            isActive
            onClick={() => {
              setIsOpen(false);
              onAddSources(sources);
            }}
          >
            Add sources to chat
          </BlueButton>
          <BlueButton
            onClick={() => setIsOpen(false)}
            style={{ backgroundColor: "#d9534f88" }}
          >
            Cancel sources
          </BlueButton>
        </div>
      </div>
    );
  }

  if (stateId === "existing" && isTrialUser) {
    modalContent = <DropArea>{state?.msg}</DropArea>;
  }

  if (stateId === "from-url") {
    modalContent = (
      <FilesFromUrlsArea
        isTrialUser={isTrialUser}
        infoMsg={isTrialUser ? state?.signupMsg : ""}
        sources={sources}
        setSources={setSources}
        onClickDone={newSources => {
          setIsOpen(false);
          onAddSources(newSources);
        }}
      />
    );
  }

  if (stateId === "upload") {
    modalContent = (
      <FileInputArea
        sources={sources}
        setSources={setSources}
        onClickDone={newSources => {
          setIsOpen(false);
          onAddSources(newSources);
        }}
      />
    );
  }

  if (stateId === "existing" && !isTrialUser) {
    modalContent = (
      <DirItems style={{ height: "100%" }}>
        <div
          style={{
            position: "sticky",
            top: 0,
            width: "100%",
            backgroundColor: "white",
            paddingBottom: 8,
            zIndex: 1,
          }}
        >
          <Input
            placeholder="Filter file name"
            value={filesFilter}
            onChange={e => setFilesFilter(e.target.value)}
          />
        </div>
        <DirectoryItem
          filesFilter={filesFilter}
          alreadyAddedSources={alreadyAddedSources}
          isAlwaysOpen
          directoryPath="/source-files"
          sources={sources}
          onItemInputChange={(e, item) => {
            if (e.target.checked) {
              const newSource = {
                fileId: item?.id,
                fileName: item?.fileName,
                type: item?.type,
              };
              setSources([...sources, newSource]);
              return;
            }
            setSources(sources.filter(source => source?.fileId !== item?.id));
          }}
        />
      </DirItems>
    );
  }

  let sourcesComponent = <div />;
  if (canRemoveSources) {
    sourcesComponent = (
      <SourcesContainer>
        {alreadyAddedSources?.map((source, i) => (
          <SourceDirectoryItem
            key={source.id}
            className="source"
            data-index={i}
          >
            {getItemIcon(source)}
            <DirItemName
              onMouseDown={e => e.preventDefault()}
              href={getItemUrl(source)}
              target="_blank"
            >
              {source?.fileName}
            </DirItemName>
            <FileViewModal fileId={source?.fileId} />
            <StyledCrossIcon
              style={{ position: "absolute", right: 8, opacity: 1 }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onModifyAlreadyAddedSources(
                  alreadyAddedSources?.filter(
                    item => item.fileId !== source.fileId
                  )
                );
                // setSources(
                //   sources?.filter(item => item.fileId !== source.fileId)
                // );
              }}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </SourceDirectoryItem>
        ))}
      </SourcesContainer>
    );
  }

  return (
    <>
      <ModalTrigger
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => setIsOpen(true)}
      >
        {trigger}
      </ModalTrigger>

      <Modal open={isOpen}>
        <ModalContent>
          <Title>
            {state?.label}
            <ModalCloseCrossIcon
              onClick={() => {
                if (stateId !== "confirm-exit" && sources?.length > 0) {
                  setStateId("confirm-exit");
                  return;
                }

                setIsOpen(false);
              }}
            />
          </Title>
          {/* <BackContainer
            style={{
              visibility: stateId === "choose" ? "hidden" : "visible",
              marginLeft: -4,
            }}
            onClick={() => setStateId("choose")}
          >
            <ArrowBack />
          </BackContainer> */}
          {sourcesComponent}
          {modalContent}
          {stateId === "existing" && isTrialUser && (
            <InfoMsg>
              <WarningIcon style={{ fill: "black" }} />
              <div dangerouslySetInnerHTML={{ __html: state?.signupMsg }} />
            </InfoMsg>
          )}
          {stateId === "existing" && (
            <BlueButton
              isDisabled={isTrialUser}
              isActive
              onClick={() => {
                setIsOpen(false);
                onAddSources(sources);
              }}
            >
              {doneBtnText}
            </BlueButton>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocSourcesModalTriggerNew;
