import { useState } from "react";
import styled from "styled-components";

import ButtonWord from "components/ui/ButtonWord";
import Modal from "components/ui/Modal";
import { postAppTemplates } from "api/backend/appTemplatesEndpoints";

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
`;

const NextButton = styled(ButtonWord)``;

const SaveTemplateModal = ({ name = "", description = "", config = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const doSaveTemplate = async isPublic => {
    setIsLoading(true);
    const template = JSON.stringify({ config, description });
    await postAppTemplates({ isPublic }, { appName: name, template });
    window.location = "/apps/new/settings";
  };

  return (
    <>
      <ButtonWord
        style={{ justifySelf: "start" }}
        onClick={() => setIsOpen(true)}
      >
        Publish template
      </ButtonWord>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          Publish for you only, or everyone?
          <div style={{ paddingTop: 30, display: "flex", gap: 10 }}>
            <NextButton
              disabled={isLoading}
              onClick={() => doSaveTemplate(false)}
            >
              Me only
            </NextButton>
            <NextButton
              disabled={isLoading}
              onClick={() => doSaveTemplate(true)}
            >
              Everyone
            </NextButton>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SaveTemplateModal;
