import { cloneDeep } from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
  /* width: 200px; */
`;

const Name = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

const NameAndInput = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 8px;
`;

export const ANIMATION_MS = 1800;

export const ParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, yColumnNames } = params;

  if (isEditing) {
    return (
      <div>
        <NameAndInput>
          <Name>X axis:</Name>
          <StyledSelect
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose X Axis --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>
        <WrapContainer>
          {tableColumns
            ?.filter(col => col?.name !== xColumnName)
            ?.map(column => {
              const checked = yColumnNames?.includes(column?.name) || false;

              const onChange = e => {
                const newParams = cloneDeep(params || {});
                let newYColumnNames = [];

                if (e.target.checked) {
                  newYColumnNames = [
                    ...(newParams.yColumnNames || []),
                    column?.name,
                  ];
                }
                if (!e.target.checked) {
                  newYColumnNames = newParams.yColumnNames?.filter(
                    col => col !== column?.name
                  );
                }

                // throw away column names from previous plots
                newYColumnNames = newYColumnNames?.filter(yColumnName =>
                  tableColumns?.find(col => col?.name === yColumnName)
                );

                newParams.yColumnNames = newYColumnNames;
                onChangeParams(newParams);
              };

              return (
                <Label
                  key={column?.name}
                  style={{
                    backgroundColor: `${getColorFromString(
                      `c${column?.name}`
                    )}55`,
                  }}
                >
                  <input
                    // TODO https://stackoverflow.com/questions/43476729/react-checkbox-does-not-update
                    key={Math.random()}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                  />
                  <span>{column?.name}</span>
                </Label>
              );
            })}
        </WrapContainer>
      </div>
    );
  }

  return (
    <WrapContainer>
      {yColumnNames?.map(columnName => {
        return (
          <Label
            key={columnName}
            style={{
              backgroundColor: `${getColorFromString(`c${columnName}`)}55`,
            }}
          >
            <span>{columnName}</span>
          </Label>
        );
      })}
    </WrapContainer>
  );
};

const BarChartSmall = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
  height = 180,
}) => {
  const { xColumnName, yColumnNames } = params;

  const data = records?.map(record => {
    let dataPoint = {
      [xColumnName]: record?.[xColumnName]?.value,
    };
    yColumnNames?.forEach(yColumnName => {
      dataPoint = {
        ...dataPoint,
        [yColumnName]: record?.[yColumnName]?.value,
      };
    });

    return dataPoint;
  });

  return (
    <div>
      {/* <ParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      /> */}
      <ResponsiveContainer width="99%" height={100}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            interval={0}
            style={{ fontSize: "6px" }}
            dataKey={xColumnName}
          />
          {/* <YAxis /> */}
          {yColumnNames?.map(yColumnName => (
            <Bar
              animationDuration={ANIMATION_MS}
              key={yColumnName}
              dataKey={yColumnName}
              fill={getColorFromString(`c${yColumnName}`)}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          ))}
          <Legend
            align="top"
            layout="horizontal"
            fontSize={6}
            margin={{ top: 10 }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartSmall;
