import ColumnFieldInput from "components/ColumnFieldInput";
import { Gap } from "components/Layout";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { cloneDeep } from "lodash";
import {
  getDbTypeFromFormatType,
  getFormatTypeFromDbType,
} from "pages/apps/:pipelineConfigId/settings-new";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const Container = styled.div`
  padding: 10px;
  background-color: #eaeaea;
  padding-left: 50px;
  padding-top: 20px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.6;"}
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const Table = styled.table`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 12px;
  border-collapse: separate;
  background-color: white;
  /* width: 100%; */
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props?.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const CenteringDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const INNER_COLUMN_FIELDS = [
  { label: "Category", name: "name" },
  { label: "Color", name: "color", type: "color" },
  { label: "Is Default", name: "isDefault", type: "checkbox" },
];

const getColumns = columnsStrings => {
  return columnsStrings?.map(columnString => {
    const [name, color, isDefault] = columnString?.split(",");

    return {
      id: uuidv4(),
      name,
      color,
      isDefault,
    };
  });
};

const getStrings = columns => {
  return columns?.map(column => {
    let columnStr = column?.name;
    if (column?.color) {
      columnStr += `,${column?.color}`;
    }
    if (column?.isDefault) {
      columnStr += `,${column?.isDefault}`;
    }

    return columnStr;
  });
};

const InnerCategoriesEditor = ({
  isDisabled = false,
  savedColumnStrings = [],
  columnsStrings = [],
  onChangeColumns = newColumns => {},
  isFullDeletionAllowed = true,
}) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(getColumns(columnsStrings));
  }, [JSON.stringify(columnsStrings)]);

  const addColumn = () => {
    const newColumns = [
      ...(columns || []),
      {
        id: uuidv4(),
        color: "extraction",
        name: "",
        isDefault: false,
      },
    ];
    if (newColumns?.length === 1) {
      newColumns[0].isDefault = true;
    }
    onChangeColumns(getStrings(newColumns));
  };

  const removeColumn = columnId => {
    const newColumns = columns.filter(column => column.id !== columnId);
    onChangeColumns(getStrings(newColumns));
  };

  const onChangeColumn = (columnId, newValue, key) => {
    const newColumns = columns.map(column => {
      if (column.id === columnId) {
        let newColumn = { ...column, [key]: newValue };
        return newColumn;
      }

      if (key === "isDefault") {
        const cleanedColumn = cloneDeep(column);
        delete cleanedColumn?.isDefault;
        return cleanedColumn;
      }

      return column;
    });

    onChangeColumns(getStrings(newColumns));
  };

  return (
    <Container isDisabled={isDisabled}>
      <Table>
        <thead>
          {INNER_COLUMN_FIELDS.map(field => (
            <Th key={field?.name}>{field?.label}</Th>
          ))}
          <Th />
        </thead>
        <tbody>
          {columns.map((column, index) => {
            const hasColumnBeenSaved =
              savedColumnStrings?.[index] === columnsStrings?.[index];

            return (
              <tr key={index}>
                <Td>
                  <StyledInput
                    value={column?.name}
                    onChange={e =>
                      onChangeColumn(column?.id, e.target.value, "name")
                    }
                  />
                </Td>
                <Td>
                  <CenteringDiv>
                    <input
                      type="color"
                      value={column?.color}
                      onChange={e =>
                        onChangeColumn(column?.id, e.target.value, "color")
                      }
                    />
                  </CenteringDiv>
                </Td>
                <Td>
                  <CenteringDiv>
                    <input
                      type="radio"
                      checked={column?.isDefault === "true"}
                      onChange={e =>
                        onChangeColumn(
                          column?.id,
                          e.target.checked,
                          "isDefault"
                        )
                      }
                    />
                  </CenteringDiv>
                </Td>
                <Td>
                  {(!hasColumnBeenSaved || isFullDeletionAllowed) && (
                    <StyledTrashIcon onClick={() => removeColumn(column?.id)} />
                  )}
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Gap height="10px" />
      <StyledPlusIcon onClick={() => addColumn()} />
    </Container>
  );
};

export default InnerCategoriesEditor;
