import { apiDelete, apiGet, apiPost, apiPut } from "api/api-http-methods";
import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getDirectory = async params => {
  const { data, error } = await zapiGet(
    `/solutions/ocr/api/v1/file-system/directory`,
    params
  );
  return { data, error };
};

export const postDirectories = async body => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/file-system/directories`,
    {},
    body
  );
  return { data, error };
};

export const postMoveItem = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/file-system/move-item`,
    params,
    body
  );
  return { data, error };
};

export const deleteDirectory = async params => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/file-system/directory`,
    params
  );
  return { data, error };
};

export const patchFileSystemUpdateAccess = async (params, body) => {
  const { data, error } = await zapiPatch(
    `/solutions/ocr/api/v1/file-system/update-access`,
    params,
    body
  );
  return { data, error };
};

export const deleteItem = async (params = {}, body = {}) => {
  const { data, error } = await zapiDelete(
    `/solutions/ocr/api/v1/file-system/delete-item`,
    params,
    body
  );
  return { data, error };
};

export const postRenameDirectory = async (params = {}, body = {}) => {
  const { data, error } = await zapiPost(
    `/solutions/ocr/api/v1/file-system/rename-directory`,
    params,
    body
  );
  return { data, error };
};

// ====== pipeline configs endpoints ======

export const getPipelineConfigs = async (params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v1/pipeline-configs`, params);
  return { data, error };
};

export const getPipelineConfig = async (id = "", params = {}) => {
  const { data, error } = await apiGet(
    `/bz-api/v1/pipeline-configs/${id}`,
    params
  );
  return { data, error };
};

export const deletePipelineConfig = async (id = "", params = {}) => {
  const { data, error } = await apiDelete(
    `/bz-api/v1/pipeline-configs/${id}`,
    params
  );
  return { data, error };
};

export const putPipelineConfig = async (id = "", params = {}, body = {}) => {
  const { data, error } = await apiPut(
    `/bz-api/v1/pipeline-configs/${id}`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigs = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigsExtraction = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/extraction`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigsShare = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/${id}/share`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigsUnshare = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/${id}/unshare`,
    params,
    body
  );
  return { data, error };
};

export const pipelineConfigExecuteNlc = async (
  pipelineId = "",
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/${pipelineId}/execute-nlc`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigSearch = async (
  id = "",
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/${id}/search`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigsCsvPreview = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/preview-csv`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigsUploadCsv = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/pipeline-configs/upload-csv`,
    params,
    body
  );
  return { data, error };
};

export const postPipelineConfigsUploadCsvV2 = async (
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/bz-api/v2/pipeline-configs/upload-csv`,
    params,
    body
  );
  return { data, error };
};

// ======= sigmas =======
export const getSigmas = async (params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v1/sigmas`, params);
  return { data, error };
};

export const postSigmas = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(`/bz-api/v1/sigmas`, params, body);
  return { data, error };
};

export const putSigmas = async (id = "", params = {}, body = {}) => {
  const { data, error } = await apiPut(`/bz-api/v1/sigmas/${id}`, params, body);
  return { data, error };
};

export const deleteSigmas = async (id = "", params = {}) => {
  const { data, error } = await apiDelete(`/bz-api/v1/sigmas/${id}`, params);
  return { data, error };
};

// ======= sigmas v2 =======
export const getSigmasV2 = async (params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v2/sigmas`, params);
  return { data, error };
};

export const postListSigmas = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(`/bz-api/v2/sigmas/list`, params, body);
  return { data, error };
};

export const getSigmaV2 = async (id = "", params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v2/sigmas/${id}`, params);
  return { data, error };
};

export const postSigmasV2 = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(`/bz-api/v2/sigmas`, params, body);
  return { data, error };
};

export const putSigmasV2 = async (id = "", params = {}, body = {}) => {
  const { data, error } = await apiPut(`/bz-api/v2/sigmas/${id}`, params, body);
  return { data, error };
};

export const deleteSigmasV2 = async (id = "", params = {}) => {
  const { data, error } = await apiDelete(`/bz-api/v2/sigmas/${id}`, params);
  return { data, error };
};

export const postSigmasFillupData = async (recordId = "", params = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/sigmas/${recordId}/fillup-data`,
    params
  );
  return { data, error };
};
