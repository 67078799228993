import AreaChartNewSmall from "components/AreaChartNewSmall";
import AreaChartPercentageSmall from "components/AreaChartPercentageSmall";
import BarChartSmall from "components/BarChartSmall";
import BarChartSmallNew from "components/BarChartSmallNew";
import PieChartSmall from "components/PieChartSmall";
import PieChartSmallNew from "components/PieChartSmallNew";
import RecordsPlotSql from "components/RecordsPlotSql";
import { getPlotComponent } from "components/plots";
import { reshapeRecords } from "pages/apps/dashboard";
import styled from "styled-components";
import { getColorFromString } from "utils/common";

const Container = styled.div`
  display: grid;
  row-gap: 4px;
  position: relative;
  width: 100%;
  grid-column: span 2;
  overflow: hidden;

  /* svg {
    transform-origin: top left;
    transform: scale(0.2);
  } */
`;

const formatLabel = labelStr => {
  try {
    const parts = labelStr.split("_");
    return parts.map(part => part[0].toUpperCase() + part.slice(1)).join(" ");
  } catch (e) {
    return labelStr;
  }
};

const CardsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  background-color: #eaeaea;
  padding: 12px;
  border-radius: 8px;
  display: grid;
  gap: 4px;
  background-color: ${props => props.color}44;
`;

const CardLabel = styled.div`
  font-weight: 500;
  text-align: center;
  font-size: 8px;
`;

const CardValue = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;

const Title = styled.div`
  line-height: 1.5;
  font-weight: 500;
  font-size: 14px;
`;

const AggregateComponentSmallCard = ({
  pipelineConfig = {},
  aggregationComponent = {},
}) => {
  const execResult = aggregationComponent?.data?.execResult;
  const tableColumns = Object.entries(execResult?.columnTypes || {}).map(
    ([key, value]) => ({
      name: key,
    })
  );

  const plotsContent = (
    <div>
      {aggregationComponent?.data?.plots?.slice(0, 1)?.map((plot, index) => {
        const plotType = plot?.type;
        let plotProps = {
          params: plot || {},
          isEditing: false,
          onChangeParams: () => {},
          tableColumns,
          records: reshapeRecords(execResult?.records),
        };

        let plotContent = (
          <RecordsPlotSql
            type={plot?.type}
            records={execResult?.records}
            columnTypes={execResult?.columnTypes}
            isLoading={!execResult?.records}
          />
        );
        if (plotType !== "Table") {
          plotContent = getPlotComponent(plotType, plotProps);
        }
        if (plotType === "Pie Chart") {
          plotContent = <PieChartSmallNew {...plotProps} />;
        }
        if (plotType === "Bar Chart") {
          plotContent = <BarChartSmall {...plotProps} />;
        }
        if (plotType === "Bar Chart New") {
          plotContent = <BarChartSmallNew {...plotProps} />;
        }
        if (plotType === "Area Chart New") {
          plotContent = <AreaChartNewSmall {...plotProps} />;
        }
        if (plotType === "Area Chart Percentage New") {
          plotContent = <AreaChartPercentageSmall {...plotProps} />;
        }
        if (plotType === "Pie Chart New") {
          plotContent = <PieChartSmallNew {...plotProps} />;
        }
        if (plotType === "Key Figures") {
          const cards = Object.entries(
            reshapeRecords(execResult?.records)?.[0]?.fields || {}
          );

          plotContent = (
            <CardsContainer>
              {cards?.map(([key, field]) => (
                <Card key={key} color={getColorFromString(key)}>
                  <CardValue>{field?.value}</CardValue>
                  <CardLabel>{formatLabel(key)}</CardLabel>
                </Card>
              ))}
            </CardsContainer>
          );
        }

        if (
          [
            "Key Figures",
            "Pie Chart",
            "Pie Chart New",
            "Bar Chart",
            "Bar Chart New",
            "Area Chart New",
            "Area Chart Percentage New",
          ].includes(plotType)
        ) {
          return <div key={index}>{plotContent}</div>;
        }

        if (plotType === "Table") {
          return (
            <div
              style={{
                transform: "scale(0.8)",
                transformOrigin: "top left",
              }}
              key={index}
            >
              {plotContent}
            </div>
          );
        }

        return (
          <div
            style={{
              transform: "scale(0.3)",
              transformOrigin: "top left",
            }}
            key={index}
          >
            {plotContent}
          </div>
        );
      })}
    </div>
  );

  return (
    <Container>
      <Title>{aggregationComponent?.label}</Title>
      {plotsContent}
    </Container>
  );
};

export default AggregateComponentSmallCard;
