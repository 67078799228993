import { cloneDeep, isNil } from "lodash";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { postAndStreamResponse } from "api/api-http-methods";
import { getWordDoc, patchWordDoc } from "api/backend/wordDocsEndpoints";
import DocArea from "components/DocArea";
import ButtonWord from "components/ui/ButtonWord";
import { getBlocksFromDoc, getDocFromBlocks } from "utils/word-coversion";
import DocToolbar from "components/DocToolbar";
import DocSourcesModalTriggerNew from "components/DocSourcesModalTriggerNew";
import ReferenceModalSpanWithContext from "components/ReferenceModalSpanWithContext";
import { DownloadIcon, PdfIcon, WordIcon } from "components/ui/Icons";
import { ChatIcon } from "components/IconsNew";
import ChatView from "components/ChatView";
import InputExpanding from "components/InputExpanding";
import { Editor } from "@monaco-editor/react";
import ChatViewNew from "components/ChatViewNew";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";

const GEN_CONFIG = {
  do_sample: false,
  max_new_tokens: 100,
};

const Container = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;
`;

const OuterContainer = styled.div`
  padding: 20px;
  background-color: #131718;
  height: 100vh;
`;

const TopBar = styled.div`
  grid-column: span 2;
  padding: 8px 20px;
  border-bottom: 1px solid #ccc;
  align-items: center;

  display: flex;
  gap: 10px;
`;

const StyledDocArea = styled(DocArea)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const LeftFixedContent = styled.div`
  position: fixed;
  left: 40px;
  top: 151px;

  display: grid;
  align-content: start;
  justify-content: start;
  gap: 10px;
`;

const PromptContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  z-index: 1;
  justify-content: start;
  background-color: white;

  box-shadow: 0px 9px 27px 0px #00000022;
  border-radius: 8px;
  padding: 16px;
`;

const TextArea = styled.textarea`
  grid-column: span 2;
  resize: none;
  padding: 8px;
  height: 100px;
  width: 100%;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  border: 2px solid #e8ecef;
  border-radius: 6px;
  padding: 11px 15px;
  font-weight: 400;
  font-family: "Montserrat";
  outline: none;
  :disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  :focus {
    border: 2px solid ${props => props.theme.color.primary};
  }
  ::placeholder {
    color: ${props => props.theme.color.closer1_5};
  }
`;

const IconAndFileName = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 9px 27px 0px #00000022;
  font-weight: 500;
`;

const Debug = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  width: 300px;
  overflow: auto;
`;

const ToolbarContainer = styled.div`
  position: fixed;
  top: 90px;
  left: 50vw;
  transition: left 0.2s;
  transform: translateX(-50%);
  background-color: white;

  width: 900px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 9px 27px 0px #00000022;
`;

const Slideout = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: ${props => (props?.isOpen ? "10px" : 0)};
  height: calc(100vh - 40px - 50px);
  width: ${props => (props.isOpen ? "300px" : "0px")};
  transition: width 0.2s;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-bottom-right-radius: 24px;
  z-index: 10;
`;

const FixedButton = styled.div`
  position: absolute;
  transform: translateX(-100%);
  background-color: white;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const BottomDebug = styled.div`
  position: fixed;
  bottom: 0%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

const StyledInputExpanding = styled(InputExpanding)`
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: transparent;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  fill: black;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  BASE_URL = "https://ocr.boltztest.com";
  // BASE_URL = "https://flow.boltzbit.com";
}

const getPayloadFromDoc = (
  doc,
  prompt,
  wordDoc = {},
  isTabComplete = false
) => {
  const docCopy = cloneDeep(doc);

  const { blocks, cursor } = getBlocksFromDoc(docCopy);
  if (isTabComplete) {
    const payload = {
      genContext: "tab_complete",
      blocks,
      cursor: {
        blockIndex: cursor?.blockIndex,
        letterIndex: cursor?.letterIndex,
      },
      sources: wordDoc?.content?.sources || [],
      generation_config: GEN_CONFIG,
    };
    return payload;
  }

  const caretStyle = doc?.styles?.[doc?.selStart];
  if (caretStyle?.queryId) {
    docCopy.styles = docCopy?.styles?.map(style => {
      if (style?.queryId === caretStyle.queryId) {
        return { ...style, prompt };
      }
      return style;
    });
  }

  const payloadBlocks = cloneDeep(blocks);
  if (!caretStyle?.queryId) {
    payloadBlocks.splice(cursor.blockIndex, 0, { text: prompt, isQuery: true });
  }

  let cursorBlockIndex = cursor.blockIndex;
  if (caretStyle?.queryId) {
    cursorBlockIndex = payloadBlocks?.findIndex(
      block => block?.queryId === caretStyle.queryId && block?.isQuery
    );
  }

  // set query to latest prompt
  payloadBlocks[cursorBlockIndex].text = prompt;
  const payload = {
    blocks: payloadBlocks,
    cursor: {
      blockIndex: cursorBlockIndex,
      letterIndex: prompt?.length - 1,
    },
    sources: wordDoc?.content?.sources || [],
    generation_config: GEN_CONFIG,
  };

  return payload;
};

const WordDocPageRefactor = () => {
  const { wordDocId } = useParams();
  const [prompt, setPrompt] = useState("");
  const [doc, setDoc] = useState(null);
  const [wordDoc, setWordDoc] = useState(null);
  const [aiResponse, setAiResponse] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [doc?.selStart, doc?.selEnd]);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      doSendDoc();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [doc?.text?.length, doc?.styles]);

  const caretStyle = doc?.styles?.[doc?.selStart];

  useEffect(() => {
    setPrompt(caretStyle?.prompt || "");
  }, [JSON.stringify(caretStyle)]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);

    setWordDoc(data);
    setDoc(getDocFromBlocks(data?.content?.blocks));
  };

  const doSendDoc = async () => {
    const { blocks } = getBlocksFromDoc(doc);
    const newWordDoc = cloneDeep(wordDoc);
    newWordDoc.content.blocks = blocks;
    await patchWordDoc(wordDocId, {}, newWordDoc);
  };

  const doSaveDoc = async () => {
    const { blocks } = getBlocksFromDoc(doc);
    const newWordDoc = cloneDeep(wordDoc);
    newWordDoc.content.blocks = blocks;
    const { data } = await patchWordDoc(wordDocId, {}, newWordDoc);

    setWordDoc(data);
    setDoc(getDocFromBlocks(data?.content?.blocks));
  };

  const doSendQuery = async (isTabComplete = false) => {
    setIsGenerating(true);
    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}/bz-api/v1/ai/streamed-chat-queries`,
      reqBody: getPayloadFromDoc(doc, prompt, wordDoc, isTabComplete),
      abortController,
      onDataReceived: data => {
        setAiResponse(data);
        if (!data?.blocks) {
          return;
        }

        setDoc(getDocFromBlocks(data?.blocks, data?.cursor));
      },
    });

    setIsGenerating(false);
  };

  const onKeyDown = e => {
    if (e.key === "Tab") {
      if (document?.activeElement?.tagName !== "BODY") {
        return;
      }
      e.preventDefault();
      doSendQuery(true);
    }
  };

  const abortGeneration = () => {
    abortController.abort();
    setAbortController(new AbortController());
    setIsGenerating(false);
  };

  const onAddSources = newSources => {
    const docSources = [...(wordDoc?.content?.sources || []), ...newSources];
    const newWordDoc = {
      ...wordDoc,
      content: { ...wordDoc?.content, sources: docSources },
    };
    setWordDoc(newWordDoc);
    patchWordDoc(wordDocId, {}, newWordDoc);
  };

  const onModifyAlreadyAddedSources = newSources => {
    const newWordDoc = {
      ...wordDoc,
      content: { ...wordDoc?.content, sources: newSources },
    };
    setWordDoc(newWordDoc);
    patchWordDoc(wordDocId, {}, newWordDoc);
  };

  const caretMetas = doc?.styles?.[doc?.selStart]?.metas || [];

  return (
    <OuterContainer>
      <Container>
        <TopBar>
          <Link to="/">
            <WordIcon
              height="32px"
              style={{
                fill: "#2b579a",
              }}
            />
          </Link>
          <StyledInputExpanding
            value={wordDoc?.fileName}
            onChange={e => setWordDoc({ ...wordDoc, fileName: e.target.value })}
            onBlur={doSaveDoc}
          />
          <DocSourcesModalTriggerNew
            canRemoveSources
            alreadyAddedSources={wordDoc?.content?.sources}
            onAddSources={onAddSources}
            onModifyAlreadyAddedSources={onModifyAlreadyAddedSources}
            trigger={
              <ButtonWord style={{ marginLeft: "8px" }}>Sources</ButtonWord>
            }
          />
          <StyledDownloadIcon
            onClick={() =>
              triggerDownloadOfFile(wordDocId, { fileType: "WORD_DOC" })
            }
          />
        </TopBar>
        <ToolbarContainer isChatOpen={isChatOpen}>
          <DocToolbar
            onClickSave={doSaveDoc}
            doc={doc}
            onDocChange={newDoc => setDoc(newDoc)}
          />
        </ToolbarContainer>

        <LeftFixedContent>
          <PromptContainer
            style={{
              visibility: isNil(doc?.selStart) ? "hidden" : "visible",
            }}
          >
            <TextArea
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
              placeholder="Enter prompt"
            />
            <ButtonWord
              isPrimary
              disabled={!prompt || isGenerating}
              onClick={() => doSendQuery(false)}
            >
              Send
            </ButtonWord>
            <ButtonWord
              isPrimary
              disabled={!isGenerating}
              onClick={abortGeneration}
            >
              Cancel
            </ButtonWord>
          </PromptContainer>

          {!!caretMetas?.length && (
            <ReferenceModalSpanWithContext isTooltipDisabled metas={caretMetas}>
              <IconAndFileName>
                <PdfIcon />
                {caretMetas?.[0]?.fileName}
              </IconAndFileName>
            </ReferenceModalSpanWithContext>
          )}
        </LeftFixedContent>
        <StyledDocArea doc={doc} onDocChange={newDoc => setDoc(newDoc)} />
        {/* <Debug>
          char: {JSON.stringify(doc?.text?.[doc?.selStart])}
          <br />
          selStart: {doc?.selStart}, selEnd: {doc?.selEnd}
          <br />
          style: {JSON.stringify(doc?.styles?.[doc?.selStart])}
          <br />
          {JSON.stringify(doc?.text?.[doc?.selStart - 1])}
          <mark>{JSON.stringify(doc?.text?.[doc?.selStart])}</mark>
          {JSON.stringify(doc?.text?.[doc?.selStart + 1])}
          <br />
          text:
          <br />
          {JSON.stringify(doc?.text?.slice(doc?.selStart, doc?.selEnd))}
        </Debug>
        {doc && (
          <BottomDebug>
            <Editor
              value={JSON.stringify(doc, null, 2)}
              language="json"
              height="300px"
              options={{
                fontSize: 10,
              }}
            />
            <Editor
              value={JSON.stringify(getBlocksFromDoc(doc), null, 2)}
              language="json"
              options={{
                fontSize: 10,
              }}
              height="300px"
            />
            <Editor
              value={JSON.stringify(aiResponse, null, 2)}
              language="json"
              height="300px"
              options={{
                fontSize: 10,
              }}
            />
          </BottomDebug>
        )} */}
        <Slideout isOpen={isChatOpen}>
          <FixedButton onClick={() => setIsChatOpen(prev => !prev)}>
            <ChatIcon />
          </FixedButton>
          {isChatOpen && (
            <ChatViewNew
              marginTopBlocks={20}
              genContext="word_doc"
              isSmallInput
              workingDocs={[{ id: wordDocId }]}
            />
            // <ChatView
            //   workingDocs={[{ id: wordDocId }]}
            //   abortController={abortController}
            //   setAbortController={setAbortController}
            //   onChangeBlocks={newBlocks => {
            //     setDoc(getDocFromBlocks(newBlocks));
            //   }}
            //   isGenerating={isGenerating}
            //   setIsGenerating={setIsGenerating}
            //   onFocus={doSaveDoc}
            // />
          )}
        </Slideout>
      </Container>
    </OuterContainer>
  );
};

export default WordDocPageRefactor;
