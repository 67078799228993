import { useState } from "react";
import styled from "styled-components";

import { ChevronDownIcon, FolderIcon, PdfIcon } from "components/ui/Icons";
import { getDirectoryItems } from "api/services/fileSystem";
import { useEffect } from "react";
import { isNil, last } from "lodash";
import FileViewModal from "components/FileViewModal";
import { ITEM_TYPE_TO_NAME_KEY } from "components/DirectoryItemsList";

const DirItem = styled.label`
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 24px 18px auto 1fr;
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}

  .trigger {
    opacity: 0;
    margin-left: 8px;
  }

  :hover {
    .trigger {
      opacity: 1;
    }
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 8px;
  fill: none;
  stroke: black;
`;

const ITEM_TYPE_TO_ICON = {
  FILE: <PdfIcon style={{ fill: "#ed1c24" }} />,
  DIRECTORY: <FolderIcon />,
};

const getIconFromItem = item => {
  return ITEM_TYPE_TO_ICON?.[item?.type];
};

const isItemAllowed = (item, filesFilter) => {
  if (!filesFilter) {
    return true;
  }

  const doesNameMatch = item?.[ITEM_TYPE_TO_NAME_KEY[item?.type]]
    ?.toLowerCase()
    ?.includes(filesFilter?.toLowerCase());

  const doesPathMatch = item?.directoryPath
    ?.toLowerCase()
    ?.includes(filesFilter?.toLowerCase());

  return doesNameMatch || doesPathMatch;
};

/*

allSelected: sources = null
noneSelected: sources = []

*/
const TickableDirItems = ({
  directoryPath = "",
  sources = [],
  onItemInputChange = (e, item) => {},
  isAlwaysOpen = false,
  filesFilter = "",
  areSuggestionsVisible = false,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [directoryItems, setDirectoryItems] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setDirectoryItems([]);
      return;
    }

    doPopulateRootDirectoryItems();
  }, [directoryPath, isOpen, areSuggestionsVisible]);

  const doPopulateRootDirectoryItems = async () => {
    const { data } = await getDirectoryItems({
      directoryPath,
    });
    setDirectoryItems(data?.items || []);
  };

  return (
    <>
      <DirItem
        style={{
          display: isAlwaysOpen ? "none" : "grid",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <StyledChevronDownIcon
          style={{
            justifySelf: "center",
            transform: `rotate(${isOpen ? "0deg" : "-90deg"})`,
            transition: "transform 0.1s",
          }}
        />
        <FolderIcon />
        {last(directoryPath?.split("/"))}
      </DirItem>
      <div style={{ paddingLeft: isAlwaysOpen ? 0 : 20 }}>
        {directoryItems
          ?.filter(item => isItemAllowed(item, filesFilter))
          ?.map(item => {
            if (item?.type === "DIRECTORY") {
              return (
                <TickableDirItems
                  key={item.id}
                  directoryPath={item?.path}
                  onItemInputChange={onItemInputChange}
                  sources={sources}
                  filesFilter={filesFilter}
                />
              );
            }

            const isChecked =
              isNil(sources) ||
              !!sources?.find(source => source?.fileId === item?.id);

            return (
              <DirItem key={item.id} onClick={e => onItemInputChange(item)}>
                <input
                  style={{ pointerEvents: "none" }}
                  type="checkbox"
                  readOnly
                  checked={isChecked}
                />
                {getIconFromItem(item)}
                {item?.fileName}
                <FileViewModal fileId={item?.id} />
              </DirItem>
            );
          })}
      </div>
    </>
  );
};

export default TickableDirItems;
