import { putSigmasV2 } from "api/backend/fileSystemEndpoints";
import RecordFieldInput from "components/RecordFieldInput";
import { FilesIcon } from "components/ui/Icons";
import useClickOutside from "hooks/useClickOutside";
import { cloneDeep, set } from "lodash";
import { useRef } from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";

const CellActions = styled.div`
  position: absolute;
  right: 0;

  opacity: 0;
  background-color: white;

  display: flex;
  gap: 0px;
  align-items: center;

  svg {
    padding: 2px;
    height: 18px !important;
    width: 18px !important;
    border-radius: 2px;

    :hover {
      background-color: #cecece;
    }
  }
`;

const Td = styled.td`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
    ${CellActions} {
      opacity: 1;
    }
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }

  :hover {
    background-color: #f3f5f7;
    ${CellActions} {
      opacity: 1;
    }
  }
`;

const InputAndActions = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
  align-items: center;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const InlineFieldInputCell = ({
  style = {},
  record,
  column,
  onRecordPatchComplete = (recordId, fields) => {},
  additionalActions = [],
  leadIcon = null,
  isHeader = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const ref = useRef();

  useClickOutside(ref, () => {
    if (isEditMode) {
      setIsEditMode(false);
      doPatchRecordField();
    }
  });

  useEffect(() => {
    const value = record?.fields?.[column?.dbName]?.value;
    setFieldValue(value);
  }, [JSON.stringify({ record, column })]);

  const doPatchRecordField = async () => {
    setIsLoading(true);
    const newRecord = cloneDeep(record);

    let valueToSend = fieldValue;
    if (column?.type === "NUMBER") {
      valueToSend = parseFloat(fieldValue);
    }

    set(newRecord, `fields.${column.dbName}.value`, valueToSend);
    const { data } = await putSigmasV2(record?.id, {}, newRecord);
    onRecordPatchComplete(record?.id, data);
    setIsLoading(false);
  };

  let Cell = Td;
  if (isHeader) {
    Cell = Th;
  }

  return (
    <Cell style={style} onClick={() => setIsEditMode(true)}>
      <InputAndActions ref={ref} isDisabled={isLoading}>
        {leadIcon}
        <RecordFieldInput
          // onBlur={() => {
          //   setIsEditMode(false);
          //   doPatchRecordField();
          // }}
          rawValue={record?.fields?.[column?.dbName]?.rawValue}
          value={fieldValue}
          onChange={e => setFieldValue(e.target.value)}
          column={column}
          isEditMode={isEditMode}
          isInline
        />
        {!isEditMode && (
          <CellActions>
            <FilesIcon
              onClick={e => {
                e.stopPropagation();
                navigator.clipboard.writeText(fieldValue);
              }}
            />
            {additionalActions}
          </CellActions>
        )}
      </InputAndActions>
    </Cell>
  );
};

export default InlineFieldInputCell;
