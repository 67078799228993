import { postAndStreamResponse } from "api/api-http-methods";
import { postEmailSend } from "api/backend/emailServiceEndpoints";
import { getOauthToken } from "api/backend/projectServiceEndpoints";
import ButtonWord from "components/ui/ButtonWord";
import { EmailIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { uniqBy } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 600px;
  height: 600px;
  border-radius: 24px;
  padding: 20px;

  ${props => props?.isDisabled && "opacity: 0.5; pointer-events: none;"}

  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  align-items: end;
`;

const VerticalFields = styled.div`
  display: grid;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  justify-content: end;
  justify-items: end;
`;

const StyledInput = styled.input`
  background-color: transparent;
  color: black;
  padding: 4px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledTextArea = styled.textarea`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  resize: none;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  height: 300px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledEmailIcon = styled(EmailIcon)`
  width: 40px;
  height: 38px;
  opacity: 1;
  padding: 10px;
  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;

const SuccessMsg = styled.div`
  background-color: ${props => props.theme.color.success}22;
  color: ${props => props.theme.color.success};
  width: max-content;
  padding: 2px;
`;

const QuillContainer = styled.div`
  border: 1px solid #eaeaea;
  .ql-editor {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    height: 200px;
  }
`;

const LogoButton = styled.div`
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${props => props?.isActive && "#0191ff44"};
`;

const LogoImg = styled.img`
  height: 14px;
`;

// TODO: show success message

const PROVIDER_TO_LOGO = {
  microsoft365: "/images/logo-outlook.png",
  gmail: "/images/logo-gmail.png",
};

let BASE_URL = "";
if (process.env.REACT_APP_IS_LOCAL_DEV === "true") {
  // BASE_URL = "https://ocr.boltztest.com";
  BASE_URL = "https://flow.boltzbit.com";
}
let chatQueriesUrl = "/bz-api/v1/ai/streamed-chat-queries";

const SendEmailModal = ({
  record = {},
  recordNameColumn = {},
  isSummariseChat = false,
  wordDoc = {},
}) => {
  const [prompt, setPrompt] = useState(
    "Write a simple email to contact this fund."
  );
  const [isGenerating, setIsGenerating] = useState(false);

  const [payload, setPayload] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [from, setFrom] = useState("boltzbit");
  const [fromOptions, setFromOptions] = useState([]);

  useEffect(() => {
    setPayload({});
    setError(null);
    setIsLoading(false);
    doPopulateFromOptions();
  }, [isOpen]);

  const name = record?.fields?.[recordNameColumn?.dbName]?.value;

  useEffect(() => {
    if (!name) {
      return;
    }
    const newPrompt = `The LP name is ${name}. Write a simple email to contact this fund. My name is Jenny from Molten.`;
    setPrompt(newPrompt);
  }, [name]);

  const doSendEmail = async () => {
    const reqPayload = {
      wordDocContent: wordDoc.content,
      emailRecord: {
        from,
        to: [payload?.recipient],
        subject: payload?.subject,
        bodyText: payload?.message,
      },
    };
    setIsLoading(true);
    const { data, error } = await postEmailSend(
      { isSummariseChat },
      reqPayload
    );
    setStatus(data);
    setIsLoading(false);
    setError(error);
  };

  const doPopulateFromOptions = async () => {
    const { data: gmailRes } = await getOauthToken("gmail");
    const { data: microsoft365Res } = await getOauthToken("microsoft365");

    if (gmailRes) {
      setFromOptions(prev => uniqBy([...prev, { value: "gmail" }], "value"));
      setFrom("gmail");
    }
    if (microsoft365Res) {
      setFromOptions(prev =>
        uniqBy([...prev, { value: "microsoft365" }], "value")
      );
      setFrom("microsoft365");
    }
  };

  const doGenerateDraft = async () => {
    setIsGenerating(true);

    const payload = {
      cursor: { blockIndex: 0, letterIndex: prompt?.length },
      blocks: [{ text: prompt }],
      sources: [],
    };
    const { error } = await postAndStreamResponse({
      url: `${BASE_URL}${chatQueriesUrl}`,
      reqBody: payload,
      abortController: {},
      onDataReceived: data => {
        if (!data?.blocks) {
          return;
        }
        let newBody = data?.blocks?.[1]?.text;
        newBody = newBody?.replaceAll(/\n/g, "<br>");

        setPayload(prev => ({ ...prev, message: newBody }));
      },
    });
    setIsGenerating(false);
  };

  return (
    <>
      <StyledEmailIcon
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <ModalTitle>Send Email</ModalTitle>

          <VerticalFields>
            {fromOptions?.length > 1 && (
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                From:
                {fromOptions.map(option => (
                  <LogoButton
                    isActive={from === option?.value}
                    key={option.value}
                    onClick={() => setFrom(option.value)}
                  >
                    <LogoImg
                      src={PROVIDER_TO_LOGO[option.value]}
                      alt={option.value}
                    />
                  </LogoButton>
                ))}
              </div>
            )}
            <StyledInput
              placeholder="Recipient"
              value={payload?.recipient || ""}
              onChange={e =>
                setPayload({ ...payload, recipient: e.target.value })
              }
            />

            <StyledInput
              placeholder="Subject"
              value={payload?.subject || ""}
              onChange={e =>
                setPayload({ ...payload, subject: e.target.value })
              }
            />

            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr auto",
                alignItems: "start",
                gap: 10,
              }}
            >
              <StyledTextArea
                style={{ height: "40px" }}
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
                placeholder="Instructions for draft generation"
              />
              <ButtonWord
                onClick={doGenerateDraft}
                disabled={isGenerating}
                isPrimary
              >
                Generate draft
              </ButtonWord>
            </div>
            {/* <StyledTextArea
              placeholder="Message"
              value={payload?.message || ""}
              onChange={e =>
                setPayload({ ...payload, message: e.target.value })
              }
            /> */}
            <QuillContainer key={isGenerating}>
              <ReactQuill
                theme="snow"
                defaultValue={payload?.message || ""}
                onChange={newValue => {
                  setPayload({ ...payload, message: newValue });
                }}
              />
            </QuillContainer>
          </VerticalFields>

          <TwoColumns>
            <ButtonWord disabled={isLoading} isPrimary onClick={doSendEmail}>
              Send
            </ButtonWord>
            <ButtonWord onClick={() => setIsOpen(false)}>Cancel</ButtonWord>
          </TwoColumns>

          {error && <div>{error?.message}</div>}
          {status?.status && <SuccessMsg>{status?.status}</SuccessMsg>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendEmailModal;
