import { useState } from "react";
import styled from "styled-components";

import ChatAssistant from "components/ChatAssistant";
import GridDraggable from "components/GridDraggable";
import Modal from "components/ui/Modal";
import TutorialOverlay, {
  startTutorial,
} from "components/widgets/TutorialOverlay";
import TutorialOverlayModal, {
  useIsOverlayVisible,
} from "components/widgets/TutorialOverlayModal";
import useSearchParamsState from "hooks/useSearchParamsState";
import {
  postAndStreamResponse,
  postAndStreamResponseOld,
} from "api/api-http-methods";
import DirectoryItems from "components/DirectoryItems";
import { CenteredWithTopNavLayout } from "components/Layout";
import InputExpanding from "components/InputExpanding";
import InputFocusDropdown from "components/InputFocusDropdown";
import DirectoryItemsList from "components/DirectoryItemsList";
import DirectoryItemsListPipelines from "components/DirectoryItemsListPipelines";
import TextAreaExpanding from "components/TextAreaExpanding";
import { SLOW_PAYLOAD } from "pages/chat-docs/slow-payload";
import { useRef } from "react";
import { useEffect } from "react";
import GridDraggableConnect from "components/GridDraggableConnect";
import InputWithBottomSuggestionsAndSources from "components/InputWithBottomSuggestionsAndSources";

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const SideBar = styled.div`
  border-right: 1px solid #eaeaea;
  height: 100vh;
`;

const ComponentArea = styled.div`
  width: 800px;
  height: 700px;
  resize: both;
  overflow: auto;
  border: 1px dashed lightgrey;
  border-left: none;
  border-top: none;
`;

const StoryNavItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? "#eaeaea" : "transparent")};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RedComponent = styled.div`
  background-color: salmon;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

const GreenComponent = styled.div`
  background-color: lightgreen;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

const BlueComponent = styled.div`
  background-color: lightblue;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

const JsonContainer = styled.div`
  line-height: 1.2;
  font-size: 10px;
  white-space: pre-wrap;
  position: relative;
  border: 1px dashed grey;
`;

const GridDraggableStory = () => {
  return (
    <GridDraggable onClickEmptyCell={coords => console.log(coords)}>
      <RedComponent key="red">
        SMS Prinz Adalbert ('His Majesty's Ship Prince Adalbert')[a] was an
        armored cruiser built in the early 1900s for the Imperial German Navy,
        named after Prince Adalbert of Prussia, former Commander-in-Chief of the
        Prussian Navy. She was the lead ship of her class, which included a
        second ship, Friedrich Carl. Prinz Adalbert was built at the Imperial
        Dockyard in Kiel. Her keel was laid in April 1900, and she was launched
        in June 1901. Her completion in January 1904 had been delayed by a
        surplus of construction projects at the Imperial Dockyard. She was armed
        with a main battery of four 21 cm (8.3 in) guns, a significant
        improvement over the previous armored cruiser, Prinz Heinrich, which
        carried only two 24 cm (9.4 in) guns. The ship was capable of a top
        speed of 20 knots (37 km/h; 23 mph). Upon commissioning, Prinz Adalbert
        served as a gunnery training ship, a role she held for the majority of
        her career. She trained with the Home Fleet, later renamed the High Seas
        Fleet, throughout the early 1900s, and she made several visits to
        foreign countries. After the outbreak of World War I in July 1914, she
        was assigned to the reconnaissance forces in the Baltic and was tasked
        with protecting the German coast from Russian attacks. After her sister
        ship was sunk in November 1914, she became the flagship of the cruiser
        squadron in the Baltic. She conducted operations against Russian forces,
        including bombarding the port of Libau in support of the German Army.
        She was torpedoed by a British submarine in July 1915, but was able to
        return to port and was repaired. She was torpedoed a second time on 23
        October 1915; the torpedo detonated her ammunition magazines and
        destroyed the ship. She sank quickly with heavy loss of life; only three
        men were rescued from a crew of 675. This proved to be the worst German
        naval loss in the Baltic during the war.
      </RedComponent>
      <GreenComponent key="green">green</GreenComponent>
      <BlueComponent key="blue">blue</BlueComponent>
    </GridDraggable>
  );
};

const GridDraggableConnectStory = () => {
  const [items, setItems] = useState([
    { key: "red" },
    { key: "green" },
    { key: "blue" },
  ]);

  return (
    <GridDraggableConnect
      isEditingDisabled
      onClickEmptyCell={coords => console.log(coords)}
      onNewKey={key => setItems(prev => [...prev, { key }])}
    >
      {items.map(item => (
        <RedComponent key={item.key}>{item.key}</RedComponent>
      ))}
    </GridDraggableConnect>
  );
};

const buttonsTutorial = {
  firstStepId: "CLICK_BUTTON_ONE",
  steps: {
    CLICK_BUTTON_ONE: [
      {
        elementTutorialId: "btn-one",
        nextStepId: "CLICK_BUTTON_TWO",
        nextStepTriggerEvent: "click",
      },
    ],
    CLICK_BUTTON_TWO: [
      {
        elementTutorialId: "btn-two",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_MODAL_BUTTON",
      },
    ],
    CLICK_MODAL_BUTTON: [
      {
        elementTutorialId: "btn-modal-open",
        nextStepTriggerEvent: "click",
        nextStepId: "ENTER_MODAL_TEXT",
      },
    ],
    ENTER_MODAL_TEXT: [
      {
        elementTutorialId: "input-modal",
      },
      {
        elementTutorialId: "btn-modal-close",
        nextStepTriggerEvent: "click",
        nextStepId: "ENTER_TEXT",
      },
    ],
    ENTER_TEXT: [
      {
        elementTutorialId: "input-one",
        nextStepTriggerEvent: "input",
        nextStepId: "CLICK_BUTTON_THREE",
      },
    ],
    CLICK_BUTTON_THREE: [
      {
        elementTutorialId: "btn-three",
        nextStepTriggerEvent: "click",
      },
      {
        elementTutorialId: "input-one",
      },
    ],
  },
};

const ModalWithText = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isOverlayVisible = useIsOverlayVisible(isOpen);

  return (
    <>
      <button data-tutorial-id="btn-modal-open" onClick={() => setIsOpen(true)}>
        open modal
      </button>
      <Modal
        open={isOpen}
        handleClose={() => {
          if (isOverlayVisible) {
            return;
          }
          setIsOpen(false);
        }}
      >
        <TutorialOverlayModal isVisible={isOverlayVisible} />
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "start",
          }}
        >
          This is a modal for something
          <input data-tutorial-id="input-modal" placeholder="Enter anything" />
          <button
            data-tutorial-id="btn-modal-close"
            onClick={() => setIsOpen(false)}
          >
            done
          </button>
        </div>
      </Modal>
    </>
  );
};

const TutorialStory = () => {
  return (
    <div>
      <TutorialOverlay />
      <button onClick={() => startTutorial(buttonsTutorial)}>start</button>
      <div
        style={{
          display: "flex",
          gap: "20px",
          padding: "40px",
        }}
      >
        <button data-tutorial-id="btn-one">step one</button>
        <button data-tutorial-id="btn-two">step two</button>
        <ModalWithText />
        <input data-tutorial-id="input-one" placeholder="Enter anything" />
        <button data-tutorial-id="btn-three">step three</button>
      </div>
    </div>
  );
};

const StreamStory = () => {
  const [initialText, setInitialText] = useState("");
  const [statusText, setStatusText] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());
  const [res, setRes] = useState({});
  const ref = useRef();

  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    // scroll to bottom
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [res, ref?.current]);

  const doGenerate = async (funcType = "new") => {
    setStartTime(Date.now());

    setStatusText("generating...");
    const { error } = await postAndStreamResponse({
      url: "https://flow.boltzbit.com/solutions/ocr/chat-service/api/v1/chatflow/generate-streamed",
      reqBody: SLOW_PAYLOAD,
      abortController,
      onDataReceived: data => {
        setRes(data);
      },
    });
    setStatusText("done");
    setEndTime(Date.now());
    console.log({ error });
  };

  return (
    <div style={{ overflow: "auto", height: "800px" }} ref={ref}>
      <input
        style={{ width: "400px" }}
        value={initialText}
        onChange={e => setInitialText(e.target.value)}
      />
      <span>{statusText}</span>

      <div style={{ position: "sticky", top: 0, right: 0, zIndex: 1 }}>
        <button onClick={doGenerate}>Start</button>
        <button
          onClick={() => {
            abortController.abort();
            setAbortController(new AbortController());
          }}
        >
          Stop
        </button>
        {startTime && endTime && `generated in ${endTime - startTime}ms`}
      </div>
      <JsonContainer>{JSON.stringify(res, null, 2)}</JsonContainer>
    </div>
  );
};

const FilesContainer = styled.div`
  height: 600px;
  border: 1px solid #eaeaea;
  overflow: auto;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: width 0.2s;
`;

const FilesStory = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)}>toggle</button>
      <FilesContainer style={{ width: isOpen ? 300 : 0 }}>
        <DirectoryItemsList
          directoryPath="/working-files"
          isInitiallyOpen
          renderHeaderComponent={() => (
            <div
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
                padding: "20px",
              }}
            >
              <span style={{ fontSize: "16px" }}>Documents</span>
            </div>
          )}
        />
      </FilesContainer>
    </>
  );
};

const PipelinesStory = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)}>toggle</button>
      <FilesContainer style={{ width: isOpen ? 300 : 0 }}>
        <DirectoryItemsListPipelines
          renderHeaderComponent={({ tipContent }) => (
            <div
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
                padding: "20px",
              }}
            >
              <span style={{ fontSize: "16px" }}>Pipelines</span>
            </div>
          )}
          isInitiallyOpen
          isDeleteActionHidden
          directoryPath="/template-files"
          isIconDisabled
        />
      </FilesContainer>
    </>
  );
};

const CONFIG = {
  job: {
    name: "Programmer",
    numYearsNeeded: 3,
  },
  onPressGreeting: () => {
    console.log("hey");
  },
};

const Json = styled.div`
  white-space: pre;
`;

const Scratch = () => {
  return (
    <Json
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          CONFIG,
          (key, value) => {
            if (key?.startsWith("on")) {
              return `<button>${key}</button>`;
            }
            return value;
          },
          2
        ),
      }}
    />
  );
};

const InputExpandingStory = () => {
  const [value, setValue] = useState("");

  return (
    <div
      style={{
        padding: "10px",
        borderBottom: "1px solid #eaeaea",

        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <InputExpanding
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder="Enter anything"
      />
      <div style={{ width: "200px", backgroundColor: "lightgrey" }}>
        Some other content over here
      </div>
    </div>
  );
};

const InputFocusDropdownStory = () => {
  const [value, setValue] = useState("");

  return (
    <div style={{ padding: "20px" }}>
      <InputFocusDropdown
        placeholder="Enter anything"
        value={value}
        onChange={e => setValue(e.target.value)}
        dropContent={
          <div
            style={{
              padding: "0px",
            }}
          >
            Drop content
            <button>click</button>
          </div>
        }
      />
      <div>This stuff is underneath</div>
      <div>Next line</div>
    </div>
  );
};

const TextAreaExpandingStory = () => {
  const [value, setValue] = useState("");

  return (
    <div>
      <TextAreaExpanding
        style={{ padding: "20px" }}
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder="Enter anything"
      />
    </div>
  );
};

const InputWithBottomSuggestionsAndSourcesStory = () => {
  const [value, setValue] = useState("");
  const [shouldSearchInternet, setShouldSearchInternet] = useState(false);

  return (
    <div
      style={{
        padding: 40,
        display: "grid",
        height: "100%",
        gridTemplateRows: "1fr auto",
      }}
    >
      <div
        style={{ height: "100%", width: 20, backgroundColor: "salmon" }}
      ></div>
      <InputWithBottomSuggestionsAndSources
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder="Enter anything"
        //
        shouldSearchInternet={shouldSearchInternet}
        setShouldSearchInternet={setShouldSearchInternet}
      />
    </div>
  );
};

const STORIES = [
  {
    name: "ChatAssistant",
    component: <ChatAssistant initialPrompts={["Tell me a story"]} />,
  },
  {
    name: "Grid",
    component: <GridDraggableStory />,
  },
  {
    name: "GridConnect",
    component: <GridDraggableConnectStory />,
  },
  {
    name: "Tutorial",
    component: <TutorialStory />,
  },
  {
    name: "Word doc streaming",
    component: <StreamStory />,
  },
  {
    name: "Files",
    component: <FilesStory />,
  },
  {
    name: "Pipelines",
    component: <PipelinesStory />,
  },
  {
    name: "Scratch",
    component: <Scratch />,
  },
  {
    name: "InputExpanding",
    component: <InputExpandingStory />,
  },
  {
    name: "InputFocusDropdown",
    component: <InputFocusDropdownStory />,
  },
  {
    name: "TextAreaExpanding",
    component: <TextAreaExpandingStory />,
  },
  {
    name: "InputWithBottomSuggestionsAndSources",
    component: <InputWithBottomSuggestionsAndSourcesStory />,
  },
];

const LibraryPage = () => {
  const [selectedIndex, setSelectedIndex] = useSearchParamsState({
    paramName: "selectedIndex",
    initialValue: 0,
  });

  return (
    <Container>
      <SideBar>
        {STORIES?.map((story, i) => (
          <StoryNavItem
            key={i}
            isSelected={selectedIndex === i}
            onClick={() => setSelectedIndex(i)}
          >
            {story?.name}
          </StoryNavItem>
        ))}
      </SideBar>
      <ComponentArea>{STORIES[selectedIndex].component}</ComponentArea>
    </Container>
  );
};

export default LibraryPage;
