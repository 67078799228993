import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  gap: 4px;
  max-width: 200px;
`;

const FieldSelect = styled.select`
  background-color: transparent;
  color: black;
  padding: 0px 2px;
  font-size: 14px;

  min-width: 0;
  outline: none;
  border-radius: 0;
  border: none;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const FlexItems = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  gap: 2px;
  font-weight: 500;
`;

const RecordsTagsInput = ({
  value = "",
  column = {},
  onChange = e => {},
  onBlur = e => {},
  isEditMode = false,
  isInline = false,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    const firstValue = column?.categories?.[0]?.split(",")?.[0];
    setSelectedValue(firstValue);
  }, [JSON.stringify(column?.categories)]);

  const onClickAdd = () => {
    let newValue = `${value},${selectedValue}`;
    if (!value) {
      newValue = selectedValue;
    }

    onChange({ target: { value: newValue } });
  };

  const onRemoveTag = tagIndex => {
    const tags = value?.split(",") || [];
    tags.splice(tagIndex, 1);
    const newValue = tags.join(",");
    onChange({ target: { value: newValue } });
  };

  return (
    <Container>
      <FlexItems style={{ display: isEditMode ? "flex" : "none" }}>
        <FieldSelect
          value={selectedValue}
          onChange={e => setSelectedValue(e.target.value)}
          onBlur={onBlur}
        >
          {column?.categories?.map(option => {
            const categoryName = option?.split(",")?.[0];
            return (
              <option key={option} value={categoryName}>
                {categoryName}
              </option>
            );
          })}
        </FieldSelect>
        <ButtonWord onClick={onClickAdd}>Add</ButtonWord>
      </FlexItems>
      <FlexItems
        style={{ flexWrap: isInline && !isEditMode ? "nowrap" : "wrap" }}
      >
        {value?.split(",")?.map((tagName, i) => {
          const tagColor =
            column?.categories
              ?.find(c => c?.split(",")?.[0] === tagName)
              ?.split(",")?.[1] || "transparent";
          const tagStyle = { backgroundColor: `${tagColor}44` };

          return (
            <Tag key={tagName} style={tagStyle}>
              {tagName}
              {isEditMode && <CrossIcon onClick={() => onRemoveTag(i)} />}
            </Tag>
          );
        })}
      </FlexItems>
    </Container>
  );
};

export default RecordsTagsInput;
