import { Code } from "@material-ui/icons";
import { Editor } from "@monaco-editor/react";
import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  width: 800px;
  height: 600px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px 0;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 4px;
  svg {
    height: 16px;
    padding: 0;
    cursor: pointer;
    opacity: 0.8;
    :hover {
      opacity: 1;
    }
  }
`;

const StyledEditor = styled(Editor)`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;

const RecordCodeModal = ({ record }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconContainer onClick={() => setIsOpen(true)}>
        <Code />
      </IconContainer>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <StyledEditor
            value={JSON.stringify(record?.fields, null, 2)}
            language="json"
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default RecordCodeModal;
