import { getJobsV2 } from "api/backend/jobServiceEndpoints";
import { useState, useEffect } from "react";

const usePollJobs = (jobIds = []) => {
  const [jobs, setJobs] = useState([]);
  const [pollIntervalId, setPollIntervalId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!jobIds?.length) {
      setJobs([]);
      return;
    }
    // if some jobIds removed
    const newjobs = jobs?.filter(job => jobIds.includes(job.id)) || [];
    setJobs(newjobs);

    doPopulateJobs();
    const intervalId = setInterval(doPopulateJobs, 2000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [JSON.stringify(jobIds)]);

  useEffect(() => {
    const isEveryJobDone = jobs?.every(
      job => job.status === "DONE" || job.status?.includes("FAIL")
    );

    if (error || isEveryJobDone) {
      clearInterval(pollIntervalId);
    }
  }, [JSON.stringify(jobs), JSON.stringify(error)]);

  const doPopulateJobs = async () => {
    if (!jobIds?.length) {
      return;
    }
    setError(null);
    const { data, error } = await getJobsV2({ ids: jobIds });
    setJobs(data?.filter(job => jobIds.includes(job.id) || []));
    setError(error);
  };

  return [jobs, setJobs];
};

export default usePollJobs;
