import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import {
  deleteEntity,
  getEntitesWithContext,
  postEntities,
} from "api/services/searchService";
import AnnoCell from "components/AnnoCell";
import { parseJson } from "utils/common";
import AddCellLabelModalTrigger from "components/widgets/AddCellLabelModalTrigger";
import FileRerunButtonsAndProgress from "components/RerunButtonsAndProgress";
import SolrRecordEditorSimple from "components/widgets/SolrRecordEditorSimple";
import { Delete, Save } from "@material-ui/icons";
import Tooltip from "components/ui/Tooltip";
import { range } from "lodash";
import AddLabelForMLModalTrigger from "components/widgets/AddLabelForMLModalTrigger";
import { DownloadIcon } from "components/ui/Icons";
import { postDownloadExcelFileOfTable } from "api/services/projectService";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto 1fr;
  align-content: start;
  height: 100%;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const ToolbarContainer = styled.div`
  padding: 0 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  height: 48px;
  z-index: 90;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numCols}, auto);
  align-content: start;
  overflow: auto;
  grid-auto-flow: dense;
  padding-right: 20px;
  padding-bottom: 20px;
  justify-content: start;
`;

const TableName = styled.div`
  padding-left: ${props => (props.hasPadding ? "20px" : "0px")};
  padding-top: ${props => (props.hasPadding ? "20px" : "0px")};
  font-weight: 600;

  display: flex;
  gap: 4px;
`;

const StyledAnnoCell = styled(AnnoCell)`
  font-weight: ${props => (props.isBold ? 600 : "normal")};
  outline: ${props =>
    props.isSelected ? `1.5px solid ${props.theme.color.primary}` : "none"};
`;

const AreaTypeContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1_5};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledSelect = styled.select`
  border: 2px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  outline: none;
  cursor: pointer;
  margin-right: 5px;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const IconButton = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }
`;

const BottomLeftAlignedTooltip = styled(Tooltip)`
  transform: translateX(-15px) translateY(10%);
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closest};
  padding: 2px 5px;
`;

const HeaderSelectorCell = styled.div`
  border: 1px solid ${props => props.theme.color.closer1};
  min-height: 20px;
  min-width: 20px;
  background-color: ${props => props.theme.color.closer0};
  opacity: 0;
  ${props => props.gridColumn && `grid-column: ${props.gridColumn};`}
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const getHumanLineItemMapFromEntities = (entities = []) => {
  const humanLineItemMap = {};
  entities?.forEach(entity => {
    const entityValue = parseJson(entity?.value);
    const entityAnnotation = parseJson(entityValue?.annotation);

    humanLineItemMap[entityAnnotation?.rowColStr] = {
      entityId: entity?.id,
      labelProperties: entityAnnotation?.labelProperties,
    };
  });

  return humanLineItemMap;
};

const getRowColStrs = (rowColStr, numRows, numCols) => {
  const [row, col] = rowColStr.split(",");
  if (!row && !col) {
    return [];
  }
  if (!row) {
    return range(numRows).map(r => `${r},${col}`);
  }
  if (!col) {
    return range(numCols).map(c => `${row},${c}`);
  }
  return [rowColStr];
};

const StyledDownloadIcon = styled(DownloadIcon)`
  fill: black;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const OutputItemTable = ({
  tableName = "",
  tableColumns = ["A", "B"],
  tablePreview = [{ A: "", B: "" }],
  automaticLineItemNameMap = { "0,0": "" },
}) => {
  const [searchParams] = useSearchParams();

  const [selectedCellRowColStr, setSelectedCellRowColStr] = useState("");
  const [selectedCellValue, setSelectedCellValue] = useState("");

  const doDownloadTable = async () => {
    const bodyTablePreview = tablePreview?.map(row => {
      const newRow = {};
      Object.entries(row).forEach(([colName, value]) => {
        newRow[colName] = {
          RawValue: value,
          Value: value,
        };
      });

      return newRow;
    });

    const body = {
      tableColumns: tableColumns,
      tablePreview: bodyTablePreview,
    };

    await postDownloadExcelFileOfTable(body);
  };

  return (
    <Container isDisabled={false}>
      <TableName hasPadding={!!tableName}>
        {tableName}
        <StyledDownloadIcon onClick={doDownloadTable} />
      </TableName>
      <Table numCols={tableColumns?.length + 1}>
        {range(0, tableColumns?.length + 1).map(colIndex => (
          <HeaderSelectorCell
            style={{ visibility: colIndex === 0 ? "hidden" : "visible" }}
            key={`col-header-${colIndex}`}
            onClick={() => {
              setSelectedCellRowColStr(`,${colIndex - 1}`);
              setSelectedCellValue(`all cells in column ${colIndex}`);
            }}
          />
        ))}
        {range(0, tablePreview?.length).map(rowIndex => (
          <HeaderSelectorCell
            gridColumn={1}
            key={`row-header-${rowIndex}`}
            onClick={() => {
              setSelectedCellRowColStr(`${rowIndex},`);
              setSelectedCellValue(`all cells in row ${rowIndex + 1}`);
            }}
          />
        ))}
        {tablePreview?.map((rowData, rowIndex) => (
          <Fragment key={rowIndex}>
            {tableColumns?.map((colName, colIndex) => (
              <StyledAnnoCell
                onClick={() => {
                  setSelectedCellRowColStr(`${rowIndex},${colIndex}`);
                  setSelectedCellValue(rowData[colName]);
                }}
                onClickDelete={() => {}}
                isSelected={
                  selectedCellRowColStr === `${rowIndex},${colIndex}` ||
                  selectedCellRowColStr === `,${colIndex}` ||
                  selectedCellRowColStr === `${rowIndex},`
                }
                isBold={rowData?._is_header}
                key={`${rowIndex}-${colIndex}`}
                annotations={[
                  automaticLineItemNameMap?.[`${rowIndex},${colIndex}`],
                ]}
              >
                {rowData[colName]}
              </StyledAnnoCell>
            ))}
          </Fragment>
        ))}
      </Table>
    </Container>
  );
};

export default OutputItemTable;
