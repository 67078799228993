import { getSigmasV2 } from "api/backend/fileSystemEndpoints";
import { getSequence } from "api/backend/projectServiceEndpoints";
import { Gap } from "components/Layout";
import { StepOutput } from "pages/apps/:sequenceId";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { safeFormat } from "utils/common";

const Container = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  grid-column: span 2;
`;

const Title = styled.div`
  line-height: 1.5;
  font-weight: 500;
`;

const getStep = (outputRecord, sequence) => {
  const stepId = outputRecord?.fields?.StepId?.value;
  const step = sequence?.steps?.find(step => step?.id === stepId);
  return step;
};

const SequenceSmallCard = ({ pipelineConfigId = "", sequenceId = "" }) => {
  const [sequence, setSequence] = useState(null);
  const [outputRecords, setOutputRecords] = useState([]);

  useEffect(() => {
    doPopulateSequence();
    doPopulateOutputSigmaRecords();
  }, [sequenceId, pipelineConfigId]);

  const doPopulateSequence = async () => {
    const { data } = await getSequence(sequenceId);
    setSequence(data || {});
  };

  const doPopulateOutputSigmaRecords = async () => {
    const { data } = await getSigmasV2({
      pipelineId: pipelineConfigId,
      tableName: "source_step_output",
    });
    const sequenceOutputRecords =
      data?.items?.filter(
        record => record?.fields?.SeqId?.value === sequenceId
      ) || [];
    setOutputRecords(sequenceOutputRecords);
  };

  const outputRecordsMostRecentFirst = outputRecords?.sort(
    (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
  );

  const mostRecentRecord = outputRecordsMostRecentFirst?.[0];
  const executionTime = mostRecentRecord?.fields?.["Execution Time"]?.value;
  const stepName = getStep(mostRecentRecord, sequence)?.name;

  return (
    <Container>
      <Title>{sequence?.name}</Title>
      <Gap height="20px" />
      <div
        style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: 10 }}
      >
        <div
          style={{
            fontWeight: 500,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: 1.2,
            fontSize: 12,
          }}
        >
          {stepName}
        </div>
        <div style={{ justifySelf: "end", fontSize: 12 }}>
          {safeFormat(executionTime, "d MMM HH:mm:ss")}
        </div>
      </div>
      <Gap height="10px" />
      <StepOutput statusOnly outputRecord={mostRecentRecord} />
    </Container>
  );
};

export default SequenceSmallCard;
