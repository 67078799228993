import { getCubesFindByKey } from "api/backend/projectServiceEndpoints";
import { useEffect } from "react";
import { useState } from "react";

const useFlowUrl = () => {
  const [flowCubeId, setFlowCubeId] = useState(null);

  useEffect(() => {
    doPopulateFlowCubeId();
  }, []);

  const doPopulateFlowCubeId = async () => {
    const { data } = await getCubesFindByKey({ key: "boltzflow" });
    setFlowCubeId(data?.id);
  };

  if (flowCubeId) {
    return `https://hub.boltzbit.com/cube/${flowCubeId}?tab=Model`;
  }

  return "";
};

export default useFlowUrl;
