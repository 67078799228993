import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { isNil, range } from "lodash";

import {
  ALPHABET_EXTENDED,
  DEFAULT_CELL_HEIGHT,
  X_OFFSET,
  Y_OFFSET,
  drawHorizontalLine,
  drawVerticalLine,
  getArrayOfCellLocationsFromSelection,
  getCellLocationFromOffset,
  getColumnX,
  getNearestBoundaryColumnIndex,
  getNearestBoundaryRowIndex,
  getResizedColumnGrid,
  getResizedRowGrid,
  getRowY,
  isCellIdWithinSelection,
} from "utils/excel-utils";
import {
  AttachMoney,
  BorderBottom,
  BorderLeft,
  BorderRight,
  BorderTop,
  ChevronLeft,
  ChevronRight,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatItalic,
} from "@material-ui/icons";
import {
  CalculateIcon,
  ChatIcon,
  FormulaIcon,
  PdfIcon,
  PercentIcon,
} from "components/ui/Icons";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import {
  DATA_FORMAT_STR_TO_DATA_TYPE,
  DATA_TYPES,
  DATA_TYPE_TO_DATA_FORMAT_STR,
  SUPPORTED_FUNCTIONS,
} from "api/services/excelModelsService";
import ExcelTextInputWithFormulaDropdown, {
  getCellLocationToColorMap,
} from "components/ui/ExcelTextInputWithFormulaDropdown";
import SearchInput from "components/widgets/SearchInput";
import MultiPagePreviewTextAndTableModal from "components/widgets/MultiPagePreviewTextAndTableModal";
import useSearchParamsState from "hooks/useSearchParamsState";

/*

DRAWING CONVENTIONS

0,0 ---> x
|
|  canvas
↓
y

topY    * ------ *
        |        |
        | A CELL |
        |        |
bottomY * ------ *
      leftX     rightX



topY, bottomY, leftX, rightX are all in pixels

a row has an index 0, 1, 2, 3 ...    and topY and bottomY
a column has an index 0, 1, 2, 3 ... and leftX and rightX

*/

// scale factor, without it the canvas is blurry
const SF = 2;

const SCROLL_Y_SENSITIVITY = 18;
const SCROLL_X_SENSITIVITY = 50;

const CELL_PADDING = 4;
const FONT_SIZE_MULTIPLIER = 0.06;

const CONTROL_KEYS = [
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Tab",
  "Enter",
];

const drawRowHeader = ({ ctx, rowIndToTopY, rowInd, selectedCellLocation }) => {
  const rectY = Y_OFFSET * SF + rowIndToTopY[rowInd] * SF;
  const rectWidth = X_OFFSET * SF;
  const rectHeight = (rowIndToTopY[rowInd + 1] - rowIndToTopY[rowInd]) * SF;
  if (rectHeight === 0) {
    return;
  }
  const selectedRow = selectedCellLocation?.match(/\d+/)?.[0] - 1;

  ctx.fillStyle = `#fff`;
  if (selectedRow === rowInd) {
    ctx.fillStyle = "#d3e3fd";
  }
  ctx.fillRect(0, rectY, rectWidth, rectHeight);
  ctx.strokeStyle = "#5d5d5d";
  ctx.strokeRect(0, rectY, rectWidth, rectHeight);

  ctx.font = "500 22px Arial";
  ctx.fillStyle = "#444847";

  let rowText = `${rowInd + 1}`;
  if (rowText?.length === 1) {
    rowText = ` ${rowText}`;
  }

  ctx.fillText(
    rowText,
    X_OFFSET / 2 + 8,
    Y_OFFSET * SF + rowIndToTopY[rowInd] * SF + 32
  );
};

const drawColHeader = ({
  ctx,
  colIndToLeftX,
  colInd,
  selectedCellLocation,
}) => {
  const rectX = X_OFFSET * SF + colIndToLeftX[colInd] * SF;
  const rectWidth = (colIndToLeftX[colInd + 1] - colIndToLeftX[colInd]) * SF;
  if (rectWidth === 0) {
    return;
  }
  const rectHeight = Y_OFFSET * SF;
  const selectedCol = ALPHABET_EXTENDED.indexOf(
    selectedCellLocation?.match(/[A-Z]+/)?.[0]
  );

  ctx.fillStyle = `#fff`;
  if (selectedCol === colInd) {
    ctx.fillStyle = "#d3e3fd";
  }
  ctx.fillRect(rectX, 0, rectWidth, rectHeight);
  ctx.strokeStyle = "#5d5d5d";
  ctx.strokeRect(rectX, 0, rectWidth, rectHeight);

  ctx.font = "500 22px Arial";
  ctx.fillStyle = "#444847";
  ctx.fillText(
    ALPHABET_EXTENDED[colInd],
    X_OFFSET * SF + colIndToLeftX[colInd] * SF + rectWidth / 2 - 14,
    32
  );
};

const drawText = ({ ctx, cell, topY, leftX, cellWidth, cellHeight }) => {
  if (!cell) {
    return;
  }

  const fontSize = (cell?.fontSize || 200) * SF * FONT_SIZE_MULTIPLIER;

  ctx.font = `${cell?.fontItalic ? "italic" : ""} ${
    cell?.fontBold ? "600" : ""
  } ${fontSize}px Arial`;
  ctx.fillStyle = `#${cell?.fontColor?.slice(2) || "000000"}`;

  if (cell?.dataFormatString?.includes("[Red]")) {
    const isFValueNegative =
      cell?.["f-value"]?.startsWith("-") ||
      (cell?.["f-value"]?.includes("(") && cell?.["f-value"]?.includes(")"));
    if (isFValueNegative) {
      ctx.fillStyle = `#ff0000`;
    }
  }

  let [excelHorizAlign] = cell?.align?.split(";") || [];

  let ctxTextAlign = "left";
  if (excelHorizAlign === "RIGHT") {
    ctxTextAlign = "right";
  }
  if (excelHorizAlign === "GENERAL") {
    if (cell?.dataType === "NUMERIC" || cell?.dataType === "FORMULA") {
      ctxTextAlign = "right";
    }
  }
  if (excelHorizAlign === "CENTER") {
    ctxTextAlign = "center";
  }

  let cellValue = cell?.["f-value"] || cell?.value || "";
  const textMeasurement = ctx.measureText(cellValue);

  const textY = (topY + cellHeight) * SF - CELL_PADDING - 4;
  // ctx.moveTo(leftX * SF, textY);
  // ctx.lineTo(leftX * SF + cellWidth * SF - 40, textY);
  // ctx.strokeStyle = "red";
  // ctx.stroke();

  if (ctxTextAlign === "right" && textMeasurement.width <= cellWidth * SF) {
    ctx.textAlign = "right";
    ctx.fillText(
      cellValue,
      -CELL_PADDING * 1.5 + leftX * SF + cellWidth * SF,
      textY
    );
    ctx.textAlign = "left";
    return;
  }

  if (ctxTextAlign === "center" && textMeasurement.width <= cellWidth * SF) {
    ctx.textAlign = "center";
    ctx.fillText(cellValue, leftX * SF + (cellWidth * SF) / 2, textY);
    ctx.textAlign = "left";
    return;
  }

  ctx.textAlign = "left";
  ctx.fillText(cellValue, CELL_PADDING + leftX * SF + 4, textY);

  if (cell?.flowLink) {
    const prevFillStyle = ctx.fillStyle;
    ctx.fillStyle = "#0191ff";

    const [cornerX, cornerY] = [(leftX + cellWidth) * SF, topY * SF];

    ctx.beginPath();
    ctx.moveTo(cornerX, cornerY);
    ctx.lineTo(cornerX, cornerY + 10);
    ctx.lineTo(cornerX - 10, cornerY);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = prevFillStyle;
  }
};

const drawCellBorder = ({ ctx, cell, topY, leftX, cellWidth, cellHeight }) => {
  let [top, right, bottom, left] = cell?.boarder?.split(";") || [];
  if (top === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF, topY * SF);
    ctx.lineTo(leftX * SF + cellWidth * SF, topY * SF);
    ctx.stroke();
    return;
  }
  if (bottom === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF, topY * SF + cellHeight * SF - 1);
    ctx.lineTo(leftX * SF + cellWidth * SF, topY * SF + cellHeight * SF - 1);
    ctx.stroke();
    return;
  }
  if (left === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF + 1, topY * SF);
    ctx.lineTo(leftX * SF + 1, topY * SF + cellHeight * SF);
    ctx.stroke();
    return;
  }
  if (right === "BLACK1") {
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.moveTo(leftX * SF + cellWidth * SF - 1, topY * SF);
    ctx.lineTo(leftX * SF + cellWidth * SF - 1, topY * SF + cellHeight * SF);
    ctx.stroke();
    return;
  }
};

const drawBlueRectAroundSelectedCell = ({
  ctx,
  selectedCellLocation,
  rowIndToTopY,
  colIndToLeftX,
  grid,
}) => {
  if (!selectedCellLocation) {
    return;
  }

  const selectedRow = selectedCellLocation?.match(/\d+/)?.[0] - 1;
  const selectedCol = ALPHABET_EXTENDED.indexOf(
    selectedCellLocation?.match(/[A-Z]+/)?.[0]
  );

  const topY = Y_OFFSET + rowIndToTopY?.[selectedRow];
  const cellWidth = grid?.columnIndexToWidth?.[selectedCol] ?? 100;
  const leftX = X_OFFSET + colIndToLeftX?.[selectedCol];
  const cellHeight =
    grid?.rowIndexToHeight?.[selectedRow] ?? DEFAULT_CELL_HEIGHT;

  ctx.strokeStyle = "#0191ff";
  ctx.lineWidth = 4;
  ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
  ctx.lineWidth = 1;
};

const drawRectAroundHighlightedCell = ({
  ctx,
  highligtedCellLocation,
  rowIndToTopY,
  colIndToLeftX,
  grid,
  strokeStyle,
}) => {
  if (!highligtedCellLocation) {
    return;
  }

  const selectedRow = highligtedCellLocation?.match(/\d+/)?.[0] - 1;
  const selectedCol = ALPHABET_EXTENDED.indexOf(
    highligtedCellLocation?.match(/[A-Z]+/)?.[0]
  );

  const topY = Y_OFFSET + rowIndToTopY?.[selectedRow];
  const cellWidth = grid?.columnIndexToWidth?.[selectedCol] ?? 100;
  const leftX = X_OFFSET + colIndToLeftX?.[selectedCol];
  const cellHeight =
    grid?.rowIndexToHeight?.[selectedRow] ?? DEFAULT_CELL_HEIGHT;

  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = 4;
  ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
  ctx.lineWidth = 1;
};

const drawRegion = ({
  ctx,
  window,
  grid,
  dragStartLocation,
  dragEndLocation,
}) => {
  ctx.clearRect(0, 0, 100000, 100000);

  const { startRow, endRow, startCol, endCol } = window || {};

  const rowIndToTopY = getRowIndToTopY(startRow, endRow, grid);
  const colIndToLeftX = getColIndToLeftX(startCol, endCol, grid);

  const startRowIndex = dragStartLocation?.match(/\d+/)?.[0] - 1;
  const startColumnIndex = ALPHABET_EXTENDED.indexOf(
    dragStartLocation?.match(/[A-Z]+/)?.[0]
  );

  const x = X_OFFSET + (colIndToLeftX?.[startColumnIndex] || 0);
  const y = Y_OFFSET + (rowIndToTopY?.[startRowIndex] || 0);

  const endRowIndex = dragEndLocation?.match(/\d+/)?.[0] - 1;
  const endColumnIndex = ALPHABET_EXTENDED.indexOf(
    dragEndLocation?.match(/[A-Z]+/)?.[0]
  );

  let w = Math.max(colIndToLeftX?.[endColumnIndex + 1] - x + X_OFFSET, 0);
  if (dragEndLocation?.includes("ZZZZZZ")) {
    w = 99999;
  }

  let h = rowIndToTopY?.[endRowIndex + 1] - y + Y_OFFSET;
  if (endRowIndex === 99999) {
    h = 99999;
  }

  ctx.fillStyle = "rgba(1, 145, 255, 0.2)";
  ctx.fillRect(x * SF, y * SF, w * SF, h * SF);
};

const isMouseLocationColumnHeader = ({ mouseLocation, viewWindow }) => {
  const { startRow } = viewWindow || {};
  const mouseRow = mouseLocation?.match(/\d+/)?.[0] - 1;
  return startRow - 1 === mouseRow;
};

const isMouseLocationRowHeader = ({ mouseLocation, viewWindow }) => {
  const { startCol } = viewWindow || {};
  const mouseCol = ALPHABET_EXTENDED.indexOf(
    mouseLocation?.match(/[A-Z]+/)?.[0]
  );
  return mouseCol + 1 === startCol;
};

const fillBgOfRemainingRow = ({
  ctx,
  topY,
  rowInd,
  colIndToLeftX,
  colInd,
  endCol,
  cells,
  grid,
}) => {
  const cellHeight = grid?.rowIndexToHeight?.[rowInd] ?? DEFAULT_CELL_HEIGHT;

  range(colInd, endCol).forEach(colInd => {
    const leftX = X_OFFSET + colIndToLeftX[colInd];
    const cellWidth = grid?.columnIndexToWidth?.[colInd] ?? 100;

    if (cellWidth === 0 || cellHeight === 0) {
      return;
    }

    const cellId = `${ALPHABET_EXTENDED[colInd]}${rowInd + 1}`;
    const cell = cells?.[cellId];

    ctx.fillStyle = `#${cell?.bgColor?.slice(2) || "fff"}`;
    const pageArea = grid?.pageArea?.replace(/\$/g, "") || "";
    if (!!pageArea && !isCellIdWithinSelection(cellId, pageArea)) {
      ctx.fillStyle = `#ccc`;
    }
    ctx.fillRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
    ctx.strokeStyle = "#ccc";
    ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);

    // ctx.fillRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
  });
};

const isPreviousCellMergedAndThisOneIsnt = ({ cells, rowInd, colInd }) => {
  const prevCellId = `${ALPHABET_EXTENDED[colInd - 1]}${rowInd + 1}`;
  return (
    cells?.[prevCellId]?.merged &&
    !cells?.[`${ALPHABET_EXTENDED[colInd]}${rowInd + 1}`]?.merged
  );
};

const drawCells = ({
  ctx,
  window,
  grid,
  cells,
  selectedCellLocation,
  cellLocationToColor,
}) => {
  ctx.clearRect(0, 0, 100000, 100000);

  const { startRow, endRow, startCol, endCol } = window || {};

  const rowIndToTopY = getRowIndToTopY(startRow, endRow, grid);
  const colIndToLeftX = getColIndToLeftX(startCol, endCol, grid);

  // 1st pass: draw cell backgrounds
  range(startRow, endRow).forEach(rowInd => {
    drawRowHeader({ ctx, rowIndToTopY, rowInd, selectedCellLocation });
    range(startCol, endCol).forEach(colInd => {
      if (rowInd === startRow) {
        drawColHeader({ ctx, colIndToLeftX, colInd, selectedCellLocation });
      }

      const topY = Y_OFFSET + rowIndToTopY[rowInd];
      const cellWidth = grid?.columnIndexToWidth?.[colInd] ?? 100;
      const leftX = X_OFFSET + colIndToLeftX[colInd];
      const cellHeight =
        grid?.rowIndexToHeight?.[rowInd] ?? DEFAULT_CELL_HEIGHT;

      if (cellWidth === 0 || cellHeight === 0) {
        return;
      }

      const cellId = `${ALPHABET_EXTENDED[colInd]}${rowInd + 1}`;
      const cell = cells?.[cellId];

      ctx.fillStyle = `#${cell?.bgColor?.slice(2) || "fff"}`;
      const pageArea = grid?.pageArea?.replace(/\$/g, "") || "";
      if (!!pageArea && !isCellIdWithinSelection(cellId, pageArea)) {
        ctx.fillStyle = `#ccc`;
      }

      ctx.fillRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);
      ctx.strokeStyle = "#ccc";
      ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);

      drawCellBorder({ ctx, cell, topY, leftX, cellWidth, cellHeight });
    });
  });

  // 2nd pass: draw cell texts, and backgrounds if necessary to overlay previous cell text
  range(startRow, endRow).forEach(rowInd => {
    range(startCol, endCol).forEach(colInd => {
      const topY = Y_OFFSET + rowIndToTopY[rowInd];
      const cellWidth = grid?.columnIndexToWidth?.[colInd] ?? 100;
      const leftX = X_OFFSET + colIndToLeftX[colInd];
      const cellHeight =
        grid?.rowIndexToHeight?.[rowInd] ?? DEFAULT_CELL_HEIGHT;

      if (cellWidth === 0 || cellHeight === 0) {
        return;
      }

      const cellId = `${ALPHABET_EXTENDED[colInd]}${rowInd + 1}`;
      const cell = cells?.[cellId];

      // ctx.strokeStyle = "#ccc";
      // ctx.strokeRect(leftX * SF, topY * SF, cellWidth * SF, cellHeight * SF);

      if (
        cell?.["f-value"] ||
        cell?.value ||
        isPreviousCellMergedAndThisOneIsnt({ cells, rowInd, colInd })
      ) {
        drawCellBorder({ ctx, cell, topY, leftX, cellWidth, cellHeight });
        fillBgOfRemainingRow({
          ctx,
          topY,
          rowInd,
          colIndToLeftX,
          colInd,
          endCol,
          cells,
          grid,
        });
      }

      drawText({ ctx, cell, topY, leftX, cellWidth, cellHeight });
    });
  });

  drawBlueRectAroundSelectedCell({
    ctx,
    selectedCellLocation,
    rowIndToTopY,
    colIndToLeftX,
    grid,
  });
  Object.keys(cellLocationToColor || {}).forEach(highligtedCellLocation => {
    drawRectAroundHighlightedCell({
      ctx,
      highligtedCellLocation,
      rowIndToTopY,
      colIndToLeftX,
      grid,
      strokeStyle: cellLocationToColor[highligtedCellLocation],
    });
  });
};

const getRowIndToTopY = (startRow, endRow, grid) => {
  const rowIndToTopY = {};
  let y = 0;
  range(startRow, endRow).forEach(i => {
    rowIndToTopY[i] = y;
    y += grid?.rowIndexToHeight?.[i] ?? DEFAULT_CELL_HEIGHT;
  });
  return rowIndToTopY;
};

const getColIndToLeftX = (startCol, endCol, grid) => {
  const colIndToLeftX = {};
  let x = 0;
  range(startCol, endCol).forEach(i => {
    colIndToLeftX[i] = x;
    x += grid?.columnIndexToWidth?.[i] ?? 100;
  });
  return colIndToLeftX;
};

const getSegStartToSegLength = (hiddenRowIndices = []) => {
  const segStartToSegLength = {};

  let currentSegStart = null;
  const maxHiddenRowInd = hiddenRowIndices?.length
    ? Math.max(...hiddenRowIndices)
    : 0;
  range(0, maxHiddenRowInd).forEach(rowInd => {
    if (hiddenRowIndices.includes(rowInd)) {
      if (currentSegStart === null) {
        currentSegStart = rowInd;
        segStartToSegLength[currentSegStart] = 1;
        return;
      }
      segStartToSegLength[currentSegStart]++;
      return;
    }
    currentSegStart = null;
  });

  return segStartToSegLength;
};

const getWindow = (scrollTop, scrollLeft, grid = {}, canvasSize = {}) => {
  let startRow = Math.floor(scrollTop / SCROLL_Y_SENSITIVITY);
  const hiddenRowIndices = grid?.hiddenRowIndices?.map(i => parseInt(i)) || [];
  const rowsSegStartToSegLength = getSegStartToSegLength(hiddenRowIndices);
  Object.entries(rowsSegStartToSegLength).forEach(([segStart, segLength]) => {
    if (startRow >= segStart) {
      startRow += segLength;
    }
  });

  let endRow = startRow;
  let y = 0;
  while (y < canvasSize.height) {
    endRow++;
    y += grid?.rowIndexToHeight?.[endRow] ?? DEFAULT_CELL_HEIGHT;
  }

  let startCol = Math.floor(scrollLeft / SCROLL_X_SENSITIVITY);
  const hiddenColIndices = grid?.hiddenColIndices?.map(i => parseInt(i)) || [];
  const colsSegStartToSegLength = getSegStartToSegLength(hiddenColIndices);
  Object.entries(colsSegStartToSegLength).forEach(([segStart, segLength]) => {
    if (startCol >= segStart) {
      startCol += segLength;
    }
  });

  let endCol = startCol;
  let x = 0;
  while (x < canvasSize.width) {
    endCol++;
    x += grid?.columnIndexToWidth?.[endCol] ?? 100;
  }

  return { startRow, endRow, startCol, endCol: endCol + 2 };
};

const getSelectedCellStyle = (cellLocation, grid, viewWindow) => {
  if (!cellLocation) {
    return { display: "none" };
  }

  const rowIndex = cellLocation?.match(/\d+/)?.[0] - 1;
  const columnIndex = ALPHABET_EXTENDED.indexOf(
    cellLocation?.match(/[A-Z]+/)?.[0]
  );

  if (
    rowIndex < viewWindow?.startRow ||
    rowIndex > viewWindow?.endRow ||
    columnIndex < viewWindow?.startCol ||
    columnIndex > viewWindow?.endCol
  ) {
    return { display: "none" };
  }

  const topY =
    Y_OFFSET +
    getRowIndToTopY(viewWindow?.startRow, rowIndex + 1, grid)[rowIndex];
  const leftX =
    X_OFFSET +
    getColIndToLeftX(viewWindow?.startCol, columnIndex + 1, grid)[columnIndex];

  const width = grid?.columnIndexToWidth?.[columnIndex] ?? 100;
  const height = grid?.rowIndexToHeight?.[rowIndex] ?? DEFAULT_CELL_HEIGHT;

  return {
    top: `${134 + topY - 1}px`,
    left: `${leftX - 1}px`,
    width: `${width}px`,
    height: `${height}px`,
  };
};

const getUpdatedCellFromInputValue = (inputValue, cell) => {
  if (inputValue?.[0] === "=") {
    if (inputValue !== cell?.formula) {
      return { ...cell, dataType: "FORMULA", formula: inputValue, value: "" };
    }
    return cell;
  }

  if (inputValue !== cell?.value) {
    if (inputValue?.match(/^[-0-9]+$/)) {
      const parsedValue = parseFloat(inputValue);
      if (!!parsedValue && typeof parsedValue === "number") {
        return { ...cell, dataType: "NUMERIC", value: parseFloat(inputValue) };
      }
    }

    return {
      ...cell,
      dataType: "STRING",
      value: inputValue,
      dataFormatString: "",
    };
  }

  return cell;
};

const isInMiddleOfFormula = valueToEdit => {
  if (typeof valueToEdit !== "string") {
    return false;
  }

  if (valueToEdit?.match(/=.*\([^)]*/g)?.[0] === valueToEdit) {
    return true;
  }

  return false;
};

const CellsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  /* border-top: 1px solid ${props => props.theme.color.closer2}; */
  scroll-behavior: auto;
  grid-row: 3;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 40px 24px 1fr;
  width: 100%;
  height: 100%;
`;

const GridCanvas = styled.canvas`
  width: ${props => props.viewportWidth}px;
  height: ${props => props.viewportHeight}px;

  position: fixed;
  top: calc(45px + 24px + 48px);
  left: 0;
  pointer-events: none;
`;

const OverlayCanvas = styled(GridCanvas)``;

const LongEmptyDiv = styled.div`
  width: 1000000px;
`;

const TallEmptyDiv = styled.div`
  position: absolute;
  left: 0;
  height: 1000000px;
  width: 1px;
  visibility: none;
`;

const TopLeftCorner = styled.div`
  padding: 4px;
  overflow: hidden;
  position: fixed;
  top: calc(45px + 24px + 48px);
  left: 0;
  width: ${X_OFFSET}px;
  height: ${Y_OFFSET - 1}px;
  background-color: ${props => props.theme.color.furthest};
  z-index: 100;
  border: 1px solid ${props => props.theme.color.closer1_5};
`;

const FormulaBar = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  /* border-top: 1px solid ${props => props.theme.color.closer1_5}; */
  align-items: center;
  /* gap: 8px; */
  background-color: ${props => props.theme.color.furthest};
  box-shadow: ${props => props.theme.shadow};

  svg {
    opacity: 0.4;
  }
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const ColoredIconButton = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }

    path:nth-child(2) {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ColoredIconButtonBucket = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path:nth-child(2) {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }
  }
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${props => props.theme.color.closer1_5};
  height: 20px;
  padding: 10px 0;
`;

const ToolbarContainer = styled.div`
  position: relative;
  /* border-top: 1px solid ${props => props.theme.color.closer1}; */
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 0 8px;
  margin: 0px 16px;
  margin-bottom: 8px;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.closer0};
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Arial", sans-serif;
`;

const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Arial", sans-serif;
  width: 65px;
`;

const DataTypeContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  border-left: 1px solid ${props => props.theme.color.closer1_5};
  gap: 5px;
  font-weight: 600;
  padding: 0 10px;
  padding-right: 10px;
`;

const ExcelTextInputWithFormulaDropdownBorderLeft = styled(
  ExcelTextInputWithFormulaDropdown
)`
  // border-left: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
`;

const StyledSearchInput = styled(SearchInput)`
  padding: 0;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${props => props.theme.color.closer1_5};
`;

const CellLocSpan = styled.span`
  width: 80px;
  padding-left: 10px;
`;

const IconContainer = styled.div`
  padding: 2px;
  background-color: ${props => props.theme.color.furthest};
  width: max-content;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(100%, 100%);
  box-shadow: ${props => props.theme.shadow};
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const SrcTriggerContainer = styled.div`
  :hover {
    ${IconContainer} {
      opacity: 1;
    }
  }
`;

const ExcelViewSheet = ({
  viewWindow = { startRow: 0, startCol: 0, endRow: 30, endCol: 30 },
  onScrollViewWindow = ({ startRow, startCol, endRow, endCol }) => {},
  cells = {},
  grid = {},
  onNewCellToPatch = () => {},
  sheetName,
  isPatching,
  onNewGrid = () => {},
  isReadOnly = false,
}) => {
  const gridCanvasRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const containerRef = useRef(null);

  const [canvasSize, setCanvasSize] = useState({ width: 200, height: 200 });
  const [selectedCellLocation, setSelectedCellLocation] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [valueToEdit, setValueToEdit] = useState("");

  const [dragStartLocation, setDragStartLocation] = useState("");
  const [dragEndLocation, setDragEndLocation] = useState("");

  const [isMouseDown, setIsMouseDown] = useState(false);

  const [columnResizeIndex, setColumnResizeIndex] = useState(null);
  const [columnResizeAmount, setColumnResizeAmount] = useState(null);
  const [columnResizeStartX, setColumnResizeStartX] = useState(null);

  const [rowResizeIndex, setRowResizeIndex] = useState(null);
  const [rowResizeAmount, setRowResizeAmount] = useState(null);
  const [rowResizeStartX, setRowResizeStartX] = useState(null);

  const [isSidebarOpen, setIsSidebarOpen] = useSearchParamsState({
    paramName: "isSidebarOpen",
    initialValue: false,
  });

  const [modalTableDocumentLocation, setModalTableDocumentLocation] =
    useState(null);

  useEffect(() => {
    containerRef.current.scrollTo(0, 0);
  }, [sheetName]);

  useEffect(() => {
    if (grid?.sheetName) {
      const newWindow = getWindow(
        containerRef?.current?.scrollTop,
        containerRef?.current?.scrollLeft,
        grid,
        canvasSize
      );
      onScrollViewWindow(newWindow);
      return;
    }
    const { width, height } = containerRef.current.getBoundingClientRect();
    setCanvasSize({ width, height });

    const initialWindow = getWindow(0, 0, grid, { width, height });
    onScrollViewWindow(initialWindow);
  }, [JSON.stringify(grid)]);

  useEffect(() => {
    drawCells({
      ctx: gridCanvasRef.current.getContext("2d"),
      window: viewWindow,
      grid,
      cells,
      selectedCellLocation,
    });

    drawRegion({
      ctx: overlayCanvasRef.current.getContext("2d"),
      grid,
      window: viewWindow,
      dragStartLocation,
      dragEndLocation,
    });

    setIsEditing(false);
    setValueToEdit(selectedCell?.formula || selectedCell?.value || "");
  }, [
    JSON.stringify(cells),
    JSON.stringify(grid),
    JSON.stringify(viewWindow),
    selectedCellLocation,
  ]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [
    selectedCellLocation,
    isEditing,
    valueToEdit,
    isPatching,
    dragStartLocation,
    dragEndLocation,
  ]);

  useEffect(() => {
    if (!isEditing) {
      drawCells({
        ctx: gridCanvasRef.current.getContext("2d"),
        window: viewWindow,
        grid,
        cells,
        selectedCellLocation,
        cellLocationToColor: {},
      });
      return;
    }

    const cellLocationToColor = getCellLocationToColorMap(valueToEdit);
    drawCells({
      ctx: gridCanvasRef.current.getContext("2d"),
      window: viewWindow,
      grid,
      cells,
      selectedCellLocation,
      cellLocationToColor,
    });
  }, [isEditing, valueToEdit]);

  useEffect(() => {
    drawRegion({
      ctx: overlayCanvasRef.current.getContext("2d"),
      grid,
      window: viewWindow,
      dragStartLocation,
      dragEndLocation,
    });
  }, [dragStartLocation, dragEndLocation]);

  const onScrollDrawGridAndSetLocationsInView = e => {
    const { scrollTop, scrollLeft } = e.target;
    const newWindow = getWindow(scrollTop, scrollLeft, grid, canvasSize);
    onScrollViewWindow(newWindow);
  };

  const onCanvasMouseDown = e => {
    if (isReadOnly) {
      return;
    }

    setDragStartLocation("");
    setDragEndLocation("");
    setIsMouseDown(true);
    const mouseLocation = getCellLocationFromOffset(
      e.nativeEvent.offsetX,
      e.nativeEvent.offsetY,
      grid,
      viewWindow
    );

    const nearestBoundaryColumnIndex = getNearestBoundaryColumnIndex({
      e,
      grid,
      viewWindow,
    });
    if (!isNil(nearestBoundaryColumnIndex)) {
      setColumnResizeIndex(nearestBoundaryColumnIndex);
      setColumnResizeStartX(e.nativeEvent.offsetX);
    }

    const nearestBoundaryRowIndex = getNearestBoundaryRowIndex({
      e,
      grid,
      viewWindow,
    });
    if (!isNil(nearestBoundaryRowIndex)) {
      setRowResizeIndex(nearestBoundaryRowIndex);
      setRowResizeStartX(e.nativeEvent.offsetY);
    }

    if (isMouseLocationColumnHeader({ mouseLocation, viewWindow })) {
      setDragStartLocation(mouseLocation);
      setDragEndLocation(mouseLocation?.replace(/[0-9]/g, "100000"));
      return;
    }

    if (isMouseLocationRowHeader({ mouseLocation, viewWindow })) {
      setDragStartLocation(mouseLocation);
      setDragEndLocation(`${mouseLocation?.match(/\d+/)?.[0]}ZZZZZZ`);
      return;
    }

    if (
      typeof valueToEdit === "string" &&
      isInMiddleOfFormula(valueToEdit) &&
      mouseLocation !== selectedCellLocation
    ) {
      setIsEditing(true);
      setDragStartLocation(mouseLocation);
      return;
    }

    setSelectedCellLocation(mouseLocation);
    setDragStartLocation(mouseLocation);
  };

  const onCanvasMouseUp = e => {
    if (!isNil(columnResizeIndex)) {
      const newGrid = getResizedColumnGrid({
        grid,
        columnResizeIndex,
        columnResizeAmount: columnResizeAmount / SF,
      });
      onNewGrid(newGrid);
    }

    if (!isNil(rowResizeIndex)) {
      const newGrid = getResizedRowGrid({
        grid,
        rowResizeIndex,
        rowResizeAmount: rowResizeAmount / SF,
      });
      onNewGrid(newGrid);
    }

    setColumnResizeIndex(null);
    setColumnResizeAmount(null);
    setColumnResizeStartX(null);

    setRowResizeIndex(null);
    setRowResizeAmount(null);
    setRowResizeStartX(null);

    setIsMouseDown(false);
    const mouseLocation = getCellLocationFromOffset(
      e.nativeEvent.offsetX,
      e.nativeEvent.offsetY,
      grid,
      viewWindow
    );

    if (
      isInMiddleOfFormula(valueToEdit) &&
      mouseLocation !== selectedCellLocation &&
      mouseLocation !== "A0"
    ) {
      const formulaPart = valueToEdit?.match(/=.*\(/g)?.[0];
      let argsPart = mouseLocation;
      if (mouseLocation !== dragStartLocation) {
        argsPart = `${dragStartLocation}:${mouseLocation}`;
      }

      setValueToEdit(`${formulaPart}${argsPart}`);
    }

    if (
      dragStartLocation === mouseLocation &&
      !isInMiddleOfFormula(valueToEdit)
    ) {
      setSelectedCellLocation(mouseLocation);
      return;
    }
  };

  const onCanvasMouseMove = e => {
    containerRef.current.style.cursor = "default";
    const nearestBoundaryColumnIndex = getNearestBoundaryColumnIndex({
      e,
      grid,
      viewWindow,
    });
    if (!isNil(nearestBoundaryColumnIndex)) {
      containerRef.current.style.cursor = "col-resize";
    }
    if (!isNil(columnResizeIndex)) {
      const newResizeAmount = e.nativeEvent.offsetX - columnResizeStartX;
      setColumnResizeAmount(newResizeAmount);

      const boundaryX =
        getColumnX(columnResizeIndex + 1, grid, viewWindow) * SF;

      drawVerticalLine({
        ctx: overlayCanvasRef.current.getContext("2d"),
        x: boundaryX + newResizeAmount,
      });
    }

    const nearestBoundaryRowIndex = getNearestBoundaryRowIndex({
      e,
      grid,
      viewWindow,
    });
    if (!isNil(nearestBoundaryRowIndex)) {
      containerRef.current.style.cursor = "row-resize";
    }
    if (!isNil(rowResizeIndex)) {
      const newResizeAmount = e.nativeEvent.offsetY - rowResizeStartX;
      setRowResizeAmount(newResizeAmount);

      const boundaryY = getRowY(rowResizeIndex + 2, grid, viewWindow) * SF;

      drawHorizontalLine({
        ctx: overlayCanvasRef.current.getContext("2d"),
        y: boundaryY + newResizeAmount,
      });
    }

    if (!isMouseDown) {
      return;
    }

    const mouseLocation = getCellLocationFromOffset(
      e.nativeEvent.offsetX,
      e.nativeEvent.offsetY,
      grid,
      viewWindow
    );
    setDragEndLocation(mouseLocation);
  };

  const onKeyDown = e => {
    if (isEditing && e.key === "Escape") {
      setIsEditing(false);
      return;
    }

    if (e.key.length === 1 && !isEditing) {
      setIsEditing(true);
      return;
    }

    if (!isEditing && e.key === "Backspace") {
      let cellLocations = [selectedCellLocation];
      if (dragEndLocation !== "") {
        cellLocations = getArrayOfCellLocationsFromSelection(
          `${dragStartLocation}:${dragEndLocation}`
        );
      }
      const body = {};
      cellLocations.forEach(cellLocation => {
        body[cellLocation] = {
          value: "",
          dataType: "STRING",
        };
      });
      onNewCellToPatch(body);
      return;
    }

    if (
      !CONTROL_KEYS.includes(e.key) ||
      !selectedCellLocation ||
      (isEditing && e.key !== "Enter")
    ) {
      return;
    }

    if (e.key === "Enter") {
      if (isPatching) {
        return;
      }

      if (isEditing) {
        let valueToEditToPatch = valueToEdit;
        // if (isInMiddleOfFormula(valueToEdit)) {
        //   valueToEditToPatch = `${valueToEdit})`;
        // }
        const cellLocationToNewCell = {
          [selectedCellLocation]: getUpdatedCellFromInputValue(
            valueToEditToPatch,
            cells?.[selectedCellLocation]
          ),
        };
        onNewCellToPatch(cellLocationToNewCell);
        setIsEditing(false);
      }
    }

    e.preventDefault();

    let rowIndex = selectedCellLocation?.match(/\d+/)?.[0] - 1;
    let columnIndex = ALPHABET_EXTENDED.indexOf(
      selectedCellLocation?.match(/[A-Z]+/)?.[0]
    );

    if (e.key === "ArrowUp") {
      rowIndex--;
    }
    if (e.key === "ArrowDown" || e.key === "Enter") {
      rowIndex++;
    }
    if (e.key === "ArrowLeft") {
      columnIndex--;
    }
    if (e.key === "ArrowRight" || e.key === "Tab") {
      columnIndex++;
    }

    const newSelectedCellLocation = `${ALPHABET_EXTENDED[columnIndex]}${
      rowIndex + 1
    }`;
    setSelectedCellLocation(newSelectedCellLocation);
  };

  const updateAndPatchSelectedCell = fieldsToUpdate => {
    const cellLocationToNewCell = {
      [selectedCellLocation]: {
        ...cells?.[selectedCellLocation],
        ...fieldsToUpdate,
      },
    };
    onNewCellToPatch(cellLocationToNewCell);
  };

  const selectedCell = cells?.[selectedCellLocation];
  const dataTypeValue =
    DATA_FORMAT_STR_TO_DATA_TYPE[selectedCell?.dataFormatString] || "Custom";

  let selectedCellTextAlignment = selectedCell?.align?.split(";")?.[0];
  let selectedCellVerticalAlignment =
    selectedCell?.align?.split(";")?.[1] || "BOTTOM";

  if (selectedCellTextAlignment === "GENERAL") {
    if (
      selectedCell?.dataType === "NUMERIC" ||
      selectedCell?.dataType === "FORMULA"
    ) {
      selectedCellTextAlignment = "RIGHT";
    } else {
      selectedCellTextAlignment = "LEFT";
    }
  }

  const [borderTop, borderRight, borderBottom, borderLeft] =
    selectedCell?.boarder?.split(";") || [];

  return (
    <Container>
      <ToolbarContainer
        style={{
          opacity: isReadOnly ? 0.5 : 1,
          pointerEvents: isReadOnly ? "none" : "auto",
        }}
      >
        <IconButton
          isActive={selectedCell?.fontBold}
          onClick={() =>
            updateAndPatchSelectedCell({ fontBold: !selectedCell?.fontBold })
          }
        >
          <FormatBold />
        </IconButton>
        <IconButton
          isActive={selectedCell?.fontItalic}
          onClick={() =>
            updateAndPatchSelectedCell({
              fontItalic: !selectedCell?.fontItalic,
            })
          }
        >
          <FormatItalic />
        </IconButton>
        <ColorPickerTooltip
          selectedColor={`#${selectedCell?.fontColor?.slice(2) || "000000"}`}
          onNewColor={newFontColor =>
            updateAndPatchSelectedCell({
              fontColor: `FF${newFontColor.slice(1)}`,
            })
          }
          triggerIcon={
            <ColoredIconButton
              color={`#${selectedCell?.fontColor?.slice(2) || "000000"}`}
            >
              <FormatColorText />
            </ColoredIconButton>
          }
        />
        <StyledSelect
          value={selectedCell?.fontSize}
          onChange={e =>
            updateAndPatchSelectedCell({ fontSize: parseFloat(e.target.value) })
          }
        >
          <option value={200}>{200 / 20}</option>
          <option value={220}>{220 / 20}</option>
          <option value={240}>{240 / 20}</option>
          <option value={260}>{260 / 20}</option>
          <option value={280}>{280 / 20}</option>
          <option value={300}>{300 / 20}</option>
          <option value={320}>{320 / 20}</option>
          <option value={340}>{340 / 20}</option>
          <option value={360}>{360 / 20}</option>
          <option value={380}>{380 / 20}</option>
          <option value={400}>{400 / 20}</option>
        </StyledSelect>

        <VerticalLine style={{ marginLeft: "10px" }} />
        <IconButton
          onClick={() =>
            updateAndPatchSelectedCell({ dataFormatString: '"$"#,##0' })
          }
        >
          <AttachMoney />
        </IconButton>
        <IconButton
          onClick={() =>
            updateAndPatchSelectedCell({ dataFormatString: "0.00%" })
          }
        >
          <PercentIcon />
        </IconButton>

        <VerticalLine style={{ marginLeft: "0px" }} />
        <ColorPickerTooltip
          selectedColor={`#${selectedCell?.bgColor?.slice(2) || "FFFFFF"}`}
          onNewColor={newBgColor =>
            updateAndPatchSelectedCell({ bgColor: `FF${newBgColor.slice(1)}` })
          }
          triggerIcon={
            <ColoredIconButtonBucket
              color={`#${selectedCell?.bgColor?.slice(2) || "FFFFFF"}`}
            >
              <FormatColorFill />
            </ColoredIconButtonBucket>
          }
        />

        <IconButton
          isActive={borderTop === "BLACK1"}
          onClick={() =>
            updateAndPatchSelectedCell({
              boarder: `BLACK1;${borderRight};${borderBottom};${borderLeft}`,
            })
          }
        >
          <BorderTop />
        </IconButton>
        <IconButton
          isActive={borderRight === "BLACK1"}
          onClick={() =>
            updateAndPatchSelectedCell({
              boarder: `${borderTop};BLACK1;${borderBottom};${borderLeft}`,
            })
          }
        >
          <BorderRight />
        </IconButton>
        <IconButton
          isActive={borderBottom === "BLACK1"}
          onClick={() =>
            updateAndPatchSelectedCell({
              boarder: `${borderTop};${borderRight};BLACK1;${borderLeft}`,
            })
          }
        >
          <BorderBottom />
        </IconButton>
        <IconButton
          isActive={borderLeft === "BLACK1"}
          onClick={() =>
            updateAndPatchSelectedCell({
              boarder: `${borderTop};${borderRight};${borderBottom};BLACK1`,
            })
          }
        >
          <BorderLeft />
        </IconButton>

        <DataTypeContainer>
          <div>Cell format:</div>
          <StyledSelect
            value={dataTypeValue}
            onChange={e => {
              // if (e.target.value === "Text") {
              //   updateAndPatchSelectedCell({
              //     dataType: "STRING",
              //     dataFormatString: "Text",
              //   });
              //   return;
              // }
              updateAndPatchSelectedCell({
                dataFormatString: DATA_TYPE_TO_DATA_FORMAT_STR[e.target.value],
              });
            }}
          >
            {DATA_TYPES.map(dataType => (
              <option>{dataType}</option>
            ))}
          </StyledSelect>
        </DataTypeContainer>

        <VerticalLine />
        <IconButton
          isActive={selectedCellTextAlignment === "LEFT"}
          onClick={() =>
            updateAndPatchSelectedCell({
              align: `LEFT;${selectedCellVerticalAlignment}`,
            })
          }
        >
          <FormatAlignLeft />
        </IconButton>
        <IconButton
          isActive={selectedCellTextAlignment === "CENTER"}
          onClick={() =>
            updateAndPatchSelectedCell({
              align: `CENTER;${selectedCellVerticalAlignment}`,
            })
          }
        >
          <FormatAlignCenter />
        </IconButton>
        <IconButton
          isActive={selectedCellTextAlignment === "RIGHT"}
          onClick={() =>
            updateAndPatchSelectedCell({
              align: `RIGHT;${selectedCellVerticalAlignment}`,
            })
          }
        >
          <FormatAlignRight />
        </IconButton>

        <DataTypeContainer>
          <div>Page area:</div>
          <StyledInput
            value={grid?.pageArea?.replace(/\$/g, "") || ""}
            readOnly
          />
        </DataTypeContainer>

        <VerticalLine />
        <IconButton>
          <CalculateIcon />
          <select
            value="-"
            onChange={e => {
              setIsEditing(true);
              setValueToEdit(`=${e.target.value}(`);
            }}
          >
            <option>-</option>
            <optgroup label="Math">
              <option>SUM</option>
              <option>AVERAGE</option>
              <option>MIN</option>
              <option>MAX</option>
            </optgroup>
            <optgroup label="Lookup">
              <option>LOOKUP</option>
              <option>VLOOKUP</option>
              <option>HLOOKUP</option>
              <option>ADDRESS</option>
            </optgroup>
            <optgroup label="Others">
              {SUPPORTED_FUNCTIONS.map(funcName => (
                <option>{funcName}</option>
              ))}
            </optgroup>
          </select>
        </IconButton>
        <VerticalLine style={{ marginRight: "5px" }} />

        <IconButton
          style={{ position: "absolute", right: 10, width: "auto" }}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
          <ChatIcon />
        </IconButton>
      </ToolbarContainer>
      <FormulaBar>
        <CellLocSpan>{selectedCellLocation}</CellLocSpan>
        <VerticalDivider />
        <FormulaIcon height="16px" style={{ marginLeft: "4px" }} />
        <ExcelTextInputWithFormulaDropdownBorderLeft
          value={valueToEdit}
          isDisabled={!selectedCellLocation}
          onFocus={() => setIsEditing(true)}
          onBlur={() => setIsEditing(false)}
          onChangeValue={newValue => setValueToEdit(newValue)}
        />
      </FormulaBar>

      <CellsContainer
        ref={containerRef}
        onScroll={onScrollDrawGridAndSetLocationsInView}
        onDoubleClick={() => setIsEditing(true)}
        onMouseDown={onCanvasMouseDown}
        onMouseUp={onCanvasMouseUp}
        onMouseMove={onCanvasMouseMove}
      >
        <TopLeftCorner />
        <LongEmptyDiv />
        <TallEmptyDiv />

        {selectedCell?.flowLink && (
          <SrcTriggerContainer
            style={{
              ...getSelectedCellStyle(selectedCellLocation, grid, viewWindow),
              position: "fixed",
              zIndex: 1,
            }}
          >
            <IconContainer
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();

                const url = selectedCell?.flowLink || "";
                const fileId = url?.match(/file\/(.*)\?/)?.[1];
                const pageNumber = url?.match(/pageNumber=(.*)/)?.[1];

                setModalTableDocumentLocation({
                  fileId,
                  pageNumber,
                });
              }}
            >
              <PdfIcon />
            </IconContainer>
          </SrcTriggerContainer>
        )}

        {isEditing && !isPatching && valueToEdit?.[0] !== "/" && (
          <ExcelTextInputWithFormulaDropdown
            value={valueToEdit}
            onChangeValue={newValue => setValueToEdit(newValue)}
            style={{
              ...getSelectedCellStyle(selectedCellLocation, grid, viewWindow),
              position: "fixed",
              outline: "blue",
              zIndex: 1,
              border: "none",
            }}
            autoFocus={document?.activeElement?.tagName !== "INPUT"}
            onMouseDown={e => e.stopPropagation()}
            placeholder="/ for AI"
          />
        )}

        {isEditing && !isPatching && valueToEdit?.[0] === "/" && (
          <StyledSearchInput
            placeholder=""
            style={{
              ...getSelectedCellStyle(selectedCellLocation, grid, viewWindow),
              position: "fixed",
              outline: "blue",
              zIndex: 1,
              border: "none",
              width: "300px",
            }}
            dropdownOptionStyle={{
              padding: "4px",
              paddingLeft: "8px",
            }}
            bgColor="white"
            borderWidth={1}
            autoFocus
            onPressEnter={userNLCommand => {
              onNewCellToPatch({
                [selectedCellLocation]: {
                  value: `/${userNLCommand}`,
                },
              });
            }}
            recommendationType="excel"
          />
        )}

        <GridCanvas
          viewportWidth={canvasSize?.width}
          viewportHeight={canvasSize?.height}
          width={canvasSize?.width * SF}
          height={canvasSize?.height * SF}
          ref={gridCanvasRef}
        />

        <OverlayCanvas
          viewportWidth={canvasSize?.width}
          viewportHeight={canvasSize?.height}
          width={canvasSize?.width * SF}
          height={canvasSize?.height * SF}
          ref={overlayCanvasRef}
        />
      </CellsContainer>

      <MultiPagePreviewTextAndTableModal
        open={modalTableDocumentLocation?.fileId}
        handleClose={() => setModalTableDocumentLocation(null)}
        tableDocumentLocation={modalTableDocumentLocation}
      />
    </Container>
  );
};

export default ExcelViewSheet;
