import { getCookieByName } from "utils/common";

export const getLoginTokenFromClientStorage = () => {
  return getCookieByName("accessToken") || "PUBLIC";
};

export const setLoginTokenInClientStorage = newToken => {
  const isLocalhost = window.location.hostname === "localhost";
  if (isLocalhost) {
    document.cookie = `accessToken=${newToken};max-age=31536000;path=/`;
    return;
  }

  const isTestEnv = window.location.hostname?.includes(".boltztest.com");
  if (isTestEnv) {
    document.cookie = `accessToken=${newToken};domain=.boltztest.com;max-age=31536000;path=/`;
    return;
  }

  const isOcrProdEnv = window.location.hostname?.includes("boltzflow.com");
  if (isOcrProdEnv) {
    document.cookie = `accessToken=${newToken};domain=boltzflow.com;max-age=31536000;path=/`;
    return;
  }

  document.cookie = `accessToken=${newToken};domain=.boltzbit.com;max-age=31536000;path=/`;
};

export const setTrackIdInClientStorage = newToken => {
  if (!newToken) {
    return;
  }

  if (localStorage.getItem("trackId")) {
    return;
  }

  localStorage.setItem("trackId", newToken);
};
