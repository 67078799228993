import { apiDelete, apiGet, apiPost } from "api/api-http-methods";

export const getAppTemplates = async (params = {}) => {
  const { data, error } = await apiGet(`/bz-api/v1/app-templates`, params);
  return { data, error };
};

export const getAppTemplate = async (id = "", params = {}) => {
  const { data, error } = await apiGet(
    `/bz-api/v1/app-templates/${id}`,
    params
  );
  return { data, error };
};

export const postAppTemplates = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    `/bz-api/v1/app-templates`,
    params,
    body
  );
  return { data, error };
};

export const deleteAppTemplate = async (id, params = {}) => {
  const { data, error } = await apiDelete(
    `/bz-api/v1/app-templates/${id}`,
    params
  );
  return { data, error };
};
