import styled from "styled-components";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { merge } from "lodash";
import { CircularProgress } from "@material-ui/core";
import {
  CloudDownload,
  FileCopy,
  GroupAdd,
  KeyboardArrowDown,
  Web,
} from "@material-ui/icons";

import {
  deleteSheet,
  getDownloadExcelModelById,
  getExcelModelByIdAndSheetName,
  getExcelModelByIdAndSheetNameCachedData,
  getExcelModelSheetGrid,
  getExcelModelSheetNames,
  patchExcelModelById,
  patchExcelModelSheetGrid,
  postExcelFlowGenerate,
  postExcelModelOpen,
} from "api/services/excelModelsService";
import ExcelViewSheet from "components/widgets/ExcelViewSheet";
import useSearchParamsState from "hooks/useSearchParamsState";
import usePollExcelModelLoadingPercentage from "api/services/usePollExcelModelLoadingPercentage";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import {
  DownloadIcon,
  ExcelIcon,
  PlusIcon,
  WordIcon,
} from "components/ui/Icons";
import ItemListPopover from "components/ItemListPopover";
import NavHeader from "components/NavHeader";
import InputWithStateAdjusting from "components/InputWithStateAdjusting";
import PopoverFlexible from "components/PopoverFlexible";
import ChatView from "components/ChatView";
import ModelSelectDropdown from "components/ModelSelectDropdown";
import TooltipNew from "components/ui/TooltipNew";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import ExcelArea from "components/ExcelArea";
import { ChatIcon } from "components/IconsNew";

/*
const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  align-items: start;
  height: 100vh;
  background-color: ${props => props.theme.color.closer0}11;
`;
*/

const Container = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  /* grid-template-columns: 1fr auto; */
`;

const OuterContainer = styled.div`
  padding: 20px;
  background-color: #131718;
  height: 100vh;
`;

const Slideout = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: calc(100vh - 40px - 50px);
  width: ${props => (props.isOpen ? "300px" : "0px")};
  transition: width 0.2s;
  box-shadow: 0px 4px 12.6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-bottom-right-radius: 24px;
  z-index: 100;
`;

const FixedButton = styled.div`
  position: absolute;
  transform: translateX(-100%);
  background-color: white;
  top: 20px;
  height: 40px;
  width: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const EditableDocNameInput = styled.input`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
  width: 400px;
  background-color: transparent;

  display: block;
  align-self: center;
  border: none;
  outline: none;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const TopMenuBar = styled.div`
  display: flex;
`;

const TopMenuBarItem = styled.div`
  font-weight: normal;
  padding: 2px 8px;
  font-size: 14px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  cursor: pointer;
  z-index: 4;
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
  border: none;
  box-shadow: ${props => props.theme.shadow};
  left: 0;
  width: 200px;
  transform: translate(0, 4px);
`;

const PopoverItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 8px 14px;
  color: black;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PopoverItemBtn = styled(PopoverItemLink).attrs({ as: "div" })``;

const SheetNamesContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.closer0};
  padding-left: 41px;
  border: 1px solid ${props => props.theme.color.closer1};
  align-items: center;
  z-index: 12;

  /* width: 100vw; */
  overflow: auto;
`;

const SheetName = styled.div`
  padding: 15px;
  background-color: ${props =>
    props.isSelected ? props.theme.color.furthest : "transparent"};
  font-weight: 600;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  border-right: 0.5px solid ${props => props.theme.color.closer1};
  border-left: 0.5px solid ${props => props.theme.color.closer1};
  cursor: pointer;
  :hover {
    background-color: ${props =>
      !props.isSelected && props.theme.color.closer1};
  }
  white-space: nowrap;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const SheetOptions = styled.div`
  padding: 4px 0;
  box-shadow: ${props => props.theme.shadow};
`;

const DeleteItem = styled.div`
  font-weight: 400;
  padding: 4px 14px;
  color: ${props => props.theme.color.closer2};
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ChevronContainer = styled.div`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

// const StyledChevronIcon = styled(KeyboardArrowDown)``;

const SheetNameInput = styled(InputWithStateAdjusting)`
  font-weight: 600;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer2};
  background: transparent;
  ${props => !props.isSelected && "pointer-events: none;"}
`;

const ProgressBar = styled.div`
  width: 50%;
  height: 2px;
  background-color: ${props => props.theme.color.primary};
`;

const ModelNameAndWebLink = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  gap: 10px;
  align-items: center;
  justify-items: start;
  /* padding: 8px 10px; */
  /* padding-bottom: 8px; */
  height: 67px;

  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  position: relative;
  width: max-content;
  box-shadow: none;
  border-top-right-radius: 20px;
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  align-items: center;
  background-color: #ffffff;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 50px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 20px 20px 0 0;
  margin-bottom: 8px;
`;

const ModalTriggerWide = styled.div`
  cursor: pointer;
  border-radius: 0px;
  padding: 0 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000088;

  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;
const ModalTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000088;

  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const ExcelViewPage = () => {
  const { excelModelId } = useParams();
  const [searchParams] = useSearchParams();

  const [excelModel, setExcelModel] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [isPatching, setIsPatching] = useState(false);
  const [isPatchingSheetName, setIsPatchingSheetName] = useState(false);
  const [isSheetNameValid, setIsSheetNameValid] = useState(true);

  const [viewWindow, setViewWindow] = useState({
    startRow: 0,
    startCol: 0,
    endRow: 30,
    endCol: 30,
  });

  const [msSinceLastScroll, setMsSinceLastScroll] = useState(0);
  const [scrollIntervalId, setScrollIntervalId] = useState(null);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const [selectedSheetName, setSelectedSheetName] = useSearchParamsState({
    paramName: "selectedSheetName",
    initialValue: "",
  });

  const loadingPercentage = usePollExcelModelLoadingPercentage(excelModelId);

  const [grid, setGrid] = useState(undefined);

  const [isSidebarOpen] = useSearchParamsState({
    paramName: "isSidebarOpen",
    initialValue: false,
  });

  const isReadOnly = searchParams?.get("inSourceFiles") === "true";

  useEffect(() => {
    doOpenExcelModel();
  }, [excelModelId]);

  useEffect(() => {
    if (loadingPercentage !== 100 || !excelModelId) {
      return;
    }
    doPopulateSheetNamesAndSelectFirstSheet();
  }, [excelModelId, loadingPercentage]);

  useEffect(() => {
    if (!selectedSheetName) {
      return;
    }
    doPopulateCachedSheetData(selectedSheetName);
    doPopulateGridForSheet();
  }, [selectedSheetName]);

  useEffect(() => {
    if (msSinceLastScroll > 500 && loadingPercentage === 100 && !isPatching) {
      doPopulateSelectedSheetInWindow(selectedSheetName, viewWindow);
      setMsSinceLastScroll(0);
      clearInterval(scrollIntervalId);
    }
  }, [msSinceLastScroll, loadingPercentage, isPatching]);

  useEffect(() => {
    if (!selectedSheetName) {
      return;
    }

    const intervalId = setInterval(() => {
      setMsSinceLastScroll(prev => prev + 100);
    }, 100);
    setScrollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [viewWindow?.startRow, viewWindow?.startCol]);

  const doOpenExcelModel = async () => {
    await postExcelModelOpen(excelModelId);
  };

  const doPopulateGridForSheet = async () => {
    const { data: grid } = await getExcelModelSheetGrid(
      excelModelId,
      selectedSheetName
    );
    setGrid(grid);
  };

  const doPopulateSheetNamesAndSelectFirstSheet = async () => {
    const { data: sheetNames, error: errGettingSheets } =
      await getExcelModelSheetNames(excelModelId);
    if (errGettingSheets) {
      return;
    }
    setSheetNames(sheetNames);
    if (!selectedSheetName) {
      setSelectedSheetName(sheetNames?.[0]);
    }
  };

  const doPopulateSelectedSheetInWindow = async (sheetName, sheetWindow) => {
    const { data: newExcelModelWithSheet, error: errGettingSheetData } =
      await getExcelModelByIdAndSheetName(excelModelId, sheetName, sheetWindow);
    if (errGettingSheetData) {
      return;
    }
    setExcelModel(prevExcelModel => {
      if (
        newExcelModelWithSheet?.sheets?.[0]?.sheetName ===
        prevExcelModel?.sheets?.[0]?.sheetName
      ) {
        return merge({ ...prevExcelModel }, { ...newExcelModelWithSheet });
      }

      return newExcelModelWithSheet;
    });
  };

  const doPopulateCachedSheetData = async sheetName => {
    const { data: newExcelModelWithSheet, error: errGettingSheetData } =
      await getExcelModelByIdAndSheetNameCachedData(excelModelId, sheetName);
    if (errGettingSheetData) {
      return;
    }
    setExcelModel(newExcelModelWithSheet);
  };

  const doPatchExcelModel = async newFields => {
    setIsPatching(true);

    const fieldsWithSheets = {
      ...excelModel,
      ...newFields,
    };
    const { data: updatedExcelModel, error } = await patchExcelModelById(
      excelModelId,
      viewWindow,
      fieldsWithSheets
    );
    if (error) {
      setIsPatching(false);
      return;
    }

    setExcelModel(prevExcelModel => {
      const mergedExcelModel = merge({ ...prevExcelModel }, updatedExcelModel);
      mergedExcelModel.sources = updatedExcelModel?.sources || [];
      return mergedExcelModel;
    });
    setIsPatching(false);
  };

  return (
    <OuterContainer>
      <Container>
        <DocNameAndSourcesModal>
          <Link to="/">
            <ExcelIcon
              height="32px"
              style={{
                paddingLeft: "14px",
                paddingRight: "10px",
                fill: "#127e45",
              }}
            />
          </Link>
          <InputAndMenuBar>
            <EditableDocNameInput
              maxWidth={600}
              value={excelModel?.name}
              onChange={e => {
                setExcelModel({
                  ...excelModel,
                  name: e.target.value,
                });
              }}
              onBlur={() => doPatchExcelModel({ name: excelModel?.name })}
            />
            {/* <CircularProgress
              size={14}
              thickness={10}
              style={{
                opacity: false ? 1 : 0,
                color: "#0191ff",
              }}
            /> */}
          </InputAndMenuBar>

          <ModalTrigger
            style={{ marginRight: "10px" }}
            onClick={
              () => {}
              // triggerDownloadOfFile(excelModelId, { fileType: "WORD_DOC" })
            }
          >
            <DownloadIcon style={{ height: "14px", width: "14px" }} />
          </ModalTrigger>
          <ShareDirectoryItemModalTrigger
            trigger={
              <ModalTrigger style={{ marginRight: "10px" }}>
                <GroupAdd />
              </ModalTrigger>
            }
            directoryItem={{
              id: excelModelId,
              fileName: excelModel?.fileName,
              type: "WORD_DOC",
              sharedWith: excelModel?.sharedWith || [],
            }}
          />
          <ModelSelectDropdown />
          <DocSourcesModalTrigger
            trigger={
              <ModalTriggerWide style={{ margin: "0 10px" }}>
                <TooltipNew
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                  tipStyle={{ transform: "translate(-3.5px, 6px)" }}
                  tipText="Add PDF, Word, Excel"
                >
                  <span style={{ fontWeight: 600 }}>
                    {excelModel?.content?.sources?.length || 0} source
                    {excelModel?.content?.sources?.length === 1 ? "" : "s"}
                  </span>
                  <FileCopy />
                </TooltipNew>
              </ModalTriggerWide>
            }
            sources={excelModel?.content?.sources}
            onChangeSources={newSources => {}}
          />
          <StyledNavHeader />
        </DocNameAndSourcesModal>
        <ExcelArea excelModelId={excelModelId} />
        <Slideout isOpen={isChatOpen}>
          <FixedButton onClick={() => setIsChatOpen(prev => !prev)}>
            <ChatIcon />
          </FixedButton>
          {isChatOpen && (
            <ChatView
              abortController={abortController}
              setAbortController={setAbortController}
              isGenerating={isGenerating}
              setIsGenerating={setIsGenerating}
            />
          )}
        </Slideout>
      </Container>
    </OuterContainer>
  );
};

export default ExcelViewPage;
