import {
  deleteSigmasV2,
  getPipelineConfig,
  postListSigmas,
  postSigmasV2,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import { postDownloadExcelFileOfTable } from "api/backend/projectServiceEndpoints";
import { getWordDoc, postWordDoc } from "api/backend/wordDocsEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import ColumnsListModal from "components/ColumnsListModal";
import GridDraggable from "components/GridDraggable";
import {
  DocSearchIcon,
  ListIcon,
  MoveIcon,
  TableGridIcon,
} from "components/IconsNew";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import KanbanConfigModal from "components/KanbanConfigModal";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import PipelineRecordsTable from "components/PipelineRecordsTable";
import RecordsTableComponent from "components/RecordsTableComponent";
import SortAndFilterConfigModal from "components/SortAndFilterConfigModal";
import RecordCard from "components/shared/apps/RecordCard";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, DownloadIcon, PlusIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import NavWithTabsNew from "components/ui/NavWithTabsNew";
import { clone, cloneDeep, isEmpty, isNil } from "lodash";
import { AggregationComponent } from "pages/apps/dashboard";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { getColorFromIndex, parseJson, uuidv4 } from "utils/common";

const doDownloadTable = async (columns, records) => {
  const tableColumns = columns?.map(column => column?.name);
  const tablePreview = records?.map(record => {
    const newRow = {};
    tableColumns?.forEach(columnName => {
      let val = record?.fields?.[columnName]?.value || "";
      if (typeof val === "object") {
        val = JSON.stringify(val);
      }
      newRow[columnName] = {
        Value: val,
        RawValue: val,
      };
    });

    return newRow;
  });

  const body = {
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const GradientSpan = styled.span`
  background: linear-gradient(88.57deg, ${COLOR1} 0%, ${COLOR2} 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  overflow: hidden;
  align-content: start;
`;

const Cards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
  align-content: start;
`;

const TopBar = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto auto;
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  /* width: 800px; */
  width: 100%;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
`;

const ToggleDiv = styled.div`
  border: 1px solid #424242;
  color: #424242;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: white;
  svg {
    fill: #424242;
  }
  :hover {
    color: ${props => props.theme.color.primary};
    border-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #424242; 
    color: white;
    svg {
      fill: white;
    }
    `}
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const TitleBar = styled.div``;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ButtonWordIcon = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: max-content;
  svg {
    fill: white;
  }
`;

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4px;
  border-radius: 14px;
`;

const SwitchLink = styled.div`
  color: white;
  background-color: ${props => (props.isActive ? "#d4d4d4" : "#eaeaea")};
  color: #434343;
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: #000000;
  }
  svg {
    fill: #000000;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-right: 10px;
  :hover {
    opacity: 1;
  }
`;

const KanbanColumns = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 2px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const KanbanColumnTitle = styled.div`
  font-weight: 600;
  justify-self: start;
  padding: 4px 8px;
  height: 24px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${props => props.bgColor};
  color: white;
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const KanbanColumn = styled.div`
  background-color: ${props => props.bgColor}33;
  width: 216px;
  display: grid;
  gap: 8px;
  justify-items: center;
  align-content: start;
  padding: 8px 0;
  padding-top: 0;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
`;
const GreyHeader = styled.div`
  color: black;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 8px 14px;
`;

const ModelOptionImg = styled.img`
  height: 22px;
`;

const TitleComponentContainer = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #dedede;
  height: 100%;
`;

export const SwitchLinksBar = ({ viewType = "", setViewType = () => {} }) => (
  <SwitchLinks>
    <SwitchLink
      onClick={() => {
        setViewType("cards");
        localStorage.setItem("viewType", "cards");
      }}
      isActive={viewType === "cards"}
    >
      <TableGridIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => {
        setViewType("table");
        localStorage.setItem("viewType", "table");
      }}
      isActive={viewType === "table"}
    >
      <ListIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => {
        setViewType("kanban");
        localStorage.setItem("viewType", "kanban");
      }}
      isActive={viewType === "kanban"}
    >
      <ListIcon style={{ transform: "rotate(90deg)" }} />
    </SwitchLink>
  </SwitchLinks>
);

export const doPostEmptyRecord = async (
  pipelineConfig,
  tableId = "source0"
) => {
  const selectedTable = pipelineConfig?.sourceTables?.find(
    table => table?.id === tableId
  );

  const columns = selectedTable?.columns || [];
  const recordNameColumn = columns?.find(
    column => !column?.groupName || column?.isNameField
  );

  // populate word doc fields
  const wordDocColumns = selectedTable?.columns?.filter(
    column => column?.displayGroup === "File Templates"
  );
  let wordDocFields = {};
  const wordDocPromises = wordDocColumns?.map(async column => {
    const { data: templateWordDoc } = await getWordDoc(column?.description);
    const { data: wordDoc } = await postWordDoc(
      {},
      {
        fileName: column?.name,
        content: templateWordDoc?.content,
      }
    );
    wordDocFields[column?.dbName] = {
      value: wordDoc.id,
    };
  });
  await Promise.all(wordDocPromises);

  let fields = {
    [recordNameColumn?.dbName]: {
      value: "Blank Record",
    },
    ...wordDocFields,
  };

  // populate default values for categorical columns
  columns?.forEach(column => {
    if (column?.formatType === "Categorical" || column?.formatType === "Tags") {
      const defaultCatStr =
        column?.categories?.find(
          catStr => catStr?.split(",")?.[2] === "true"
        ) || column?.categories?.[0];
      const defaultValue = defaultCatStr?.split(",")?.[0];

      fields[column?.dbName] = {
        value: defaultValue,
      };
    }
  });

  const { data } = await postSigmasV2(
    {},
    {
      pipelineId: pipelineConfig?.id,
      tableName: selectedTable?.id,
      fields,
    }
  );

  return { data };
};

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const ModalContent = styled.div`
  padding: 20px;
  height: 200px;
  width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
  grid-template-rows: repeat(3, auto) 1fr;
`;

const BoldDiv = styled.div`
  font-weight: 500;
`;

const DateInput = styled.input`
  font-family: "Montserrat";
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  column-gap: 40px;
`;

const StyledNavWithTabsNew = styled(NavWithTabsNew)`
  justify-self: start;
`;

const GridContainer = styled.div`
  height: 100%;
  /* background: linear-gradient(180deg, #f3f5f7 0%, #fdfdfd 100%); */
  border-left: 1px solid #e8ecef;
  overflow: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const DownloadRecordsModal = ({ onClickDownload = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DownloadIcon
        style={{ cursor: "pointer", fill: "black" }}
        onClick={() => setIsOpen(true)}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>Download records</ModalTitle>
          <Gap height="0px" />
          <Row>
            <BoldDiv>Period Start</BoldDiv>
            <BoldDiv>Period End</BoldDiv>
            <DateInput type="date" />
            <DateInput type="date" />
          </Row>

          <ButtonWord
            onClick={onClickDownload}
            style={{ justifySelf: "end", alignSelf: "end" }}
            isPrimary
          >
            Download
          </ButtonWord>
        </ModalContent>
      </Modal>
    </>
  );
};

export const INITIAL_LIMIT = 15;
export const INITIAL_SORT_FILTER = {
  sort: {
    limit: INITIAL_LIMIT,
    sortColumn: "",
    direction: "DESC",
  },
  filters: [],
};

const cleanSortFilter = config => {
  const newConfig = cloneDeep(config);

  newConfig.filters = newConfig.filters
    .map(filter => {
      const newLikeValues = filter.likeValues.filter(val => val !== "");
      if (!newLikeValues?.length) {
        // TODO: BE returns error if likeValues=[]
        return null;
      }

      return {
        ...filter,
        likeValues: filter.likeValues.filter(val => val !== ""),
      };
    })
    ?.filter(filter => !!filter);

  return newConfig;
};

const AppPageRecordsNew = () => {
  const navigate = useNavigate();

  const { pipelineConfigId } = useParams();

  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [query, setQuery] = useState("");
  const [isEditingLayout, setIsEditingLayout] = useState(false);
  const [searchParams] = useSearchParams();

  const componentsStr = localStorage?.getItem(`${pipelineConfigId}-components`);
  let storedComponents = parseJson(componentsStr);
  if (isEmpty(storedComponents)) {
    storedComponents = [];
  }

  const [components, setComponents] = useState(storedComponents);

  const layoutStr = localStorage?.getItem(`${pipelineConfigId}-layout`);
  let storedLayout = parseJson(layoutStr);
  if (isEmpty(storedLayout)) {
    storedLayout = {};
  }

  const [layout, setLayout] = useState(storedLayout);

  const [bgColor, setBgColor] = useState(
    localStorage?.getItem(`${pipelineConfigId}-bgColor`) || "#ffffff"
  );

  useEffect(() => {
    if (searchParams?.get("color")) {
      setAndPersistColor(`#${searchParams?.get("color")}`);
    }
  }, [searchParams?.get("color")]);

  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${query}&type=RECORD`);
    }
  };

  const setAndPersistColor = newColor => {
    setBgColor(newColor);
    localStorage.setItem(`${pipelineConfigId}-bgColor`, newColor);
  };

  const setAndPersistLayout = newLayout => {
    setLayout(newLayout);
    localStorage.setItem(
      `${pipelineConfigId}-layout`,
      JSON.stringify(newLayout)
    );
  };

  const setAndPersistComponents = newComponents => {
    setComponents(newComponents);
    localStorage.setItem(
      `${pipelineConfigId}-components`,
      JSON.stringify(newComponents)
    );
  };

  const onClickEmptyCell = ({ x, y }, e) => {
    let type = "table";
    if (e.metaKey) {
      type = "analytics";
    }

    const id = uuidv4();

    setAndPersistComponents([...components, { id, type }]);

    const newLayout = cloneDeep(layout);
    newLayout[id] = {
      x,
      y,
      w: 20,
      h: 20,
    };

    setAndPersistLayout(newLayout);
  };

  const onDeleteKey = id => {
    const newLayout = cloneDeep(layout);
    delete newLayout[id];
    setAndPersistLayout(newLayout);

    const newComponents = components.filter(component => component.id !== id);
    setAndPersistComponents(newComponents);
  };

  const onSaveComponent = async (id, newFields) => {
    const newComponents = components.map(component => {
      if (component.id === id) {
        return {
          ...component,
          ...newFields,
        };
      }

      return component;
    });

    setAndPersistComponents(newComponents);
  };

  const titleBar = (
    <TitleComponentContainer key="title-bar">
      <TitleBar
        style={{
          display: "flex",
          gap: 20,
          alignItems: "center",
          whiteSpace: "nowrap",
          height: "100%",
        }}
      >
        <TitleText>
          <GradientSpan>{pipelineConfig?.name}</GradientSpan> records
        </TitleText>
      </TitleBar>
    </TitleComponentContainer>
  );

  const searchBar = (
    <TitleComponentContainer key="search-bar">
      <StyledInput
        value={query}
        onChange={e => setQuery(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder="Search records..."
      />
    </TitleComponentContainer>
  );

  return (
    <LayoutApp>
      <Container style={{ backgroundColor: bgColor }}>
        <GridContainer>
          <GridDraggable
            // initialLayout={pipelineConfig?.meta?.dashboardLayout || {}}
            initialLayout={layout}
            isEditingDisabled={!isEditingLayout}
            onDragEnd={setAndPersistLayout}
            areItemsRemovable
            onDeleteKey={onDeleteKey}
            onClickEmptyCell={onClickEmptyCell}
            style={{
              width: "2000px",
              height: "1300px",
            }}
            tooltipMsg="click = table, cmd+click = analytics"
          >
            {titleBar}
            {searchBar}
            {components?.map(component => {
              if (component?.type === "analytics") {
                return (
                  <AggregationComponent
                    pipelineConfig={pipelineConfig}
                    aggComponent={component}
                    key={component?.id}
                    onSaveComponent={newFields =>
                      onSaveComponent(component?.id, newFields)
                    }
                  />
                );
              }

              return (
                <RecordsTableComponent id={component.id} key={component.id} />
              );
            })}
            {/* <RecordsTableComponent id="table-1" key="table-1" />
            <RecordsTableComponent id="table-2" key="table-2" /> */}
            {/* {tableView} */}
            {/* {pipelineConfig?.aggregationComponents?.map(aggComponent => (
              <AggregationComponent
                pipelineConfig={pipelineConfig}
                key={aggComponent.id}
                aggComponent={aggComponent}
                // onSaveComponent={onSaveComponent}
                // onClickDuplicate={() => onClickDuplicate(aggComponent)}
                // onClickDelete={() => onDeleteKey(aggComponent.id)}
              />
            ))} */}
          </GridDraggable>
        </GridContainer>

        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            gap: 10,
            zIndex: 1,
            display: "flex",
          }}
        >
          {isEditingLayout && (
            <input
              value={bgColor}
              onChange={e => setAndPersistColor(e.target.value)}
              type="color"
            />
          )}
          <ToggleDiv
            isSelected={isEditingLayout}
            onClick={() => setIsEditingLayout(!isEditingLayout)}
          >
            <MoveIcon />
            Rearrange
          </ToggleDiv>
        </div>
      </Container>
    </LayoutApp>
  );
};

export default AppPageRecordsNew;
