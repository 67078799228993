import { postIngestWebpage } from "api/backend/filesEndpoints";
import { getJobV2 } from "api/backend/jobServiceEndpoints";
import ButtonWord from "components/ui/ButtonWord";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledButtonWord = styled(ButtonWord)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const AddUrlToSourceButton = ({ url = "", onUrlReady = newSource => {} }) => {
  const [hasIngested, setHasIngested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [jobId, setJobId] = useState(null);
  const [job, setJob] = useState(null);

  const onDone = () => {
    setHasIngested(true);
    setIsLoading(false);
    setJobId(null);
    setJob(null);
  };

  const doPopulateJob = async () => {
    const { data } = await getJobV2(jobId);
    setJob(data);
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }

    if (job?.status === "DONE") {
      onDone();
      return;
    }

    doPopulateJob();
    const intervalId = setInterval(doPopulateJob, 2000);

    return () => clearInterval(intervalId);
  }, [jobId, JSON.stringify(job)]);

  const doBeginIngestion = async () => {
    setIsLoading(true);

    const readableUrlName =
      url?.replace(/^https?:\/\/(www\.)?([^\/]+).*/, "$2") || url?.slice(0, 20);
    const { data } = await postIngestWebpage(
      {},
      {
        url,
        name: `${readableUrlName?.replaceAll(".", "-")}.pdf`,
      }
    );

    setJobId(data?.jobId);
  };

  return (
    <StyledButtonWord
      style={{ lineHeight: "20px", fontSize: "12px" }}
      onClick={doBeginIngestion}
      disabled={isLoading}
    >
      {hasIngested ? "Update" : "Add"}
    </StyledButtonWord>
  );
};

export default AddUrlToSourceButton;
