import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";

const Tip = styled.div`
  background-color: transparent;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
  transition-delay: 0.5s;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    width: 100%;
    height: 10px;
    background-color: transparent;
  }

  :hover {
    opacity: 1;
  }
`;

const DefaultTip = styled(Tip)`
  background-color: black;
  color: white;
  padding: 2px 4px;
  font-weight: 400;
`;

const Span = styled.span`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  :hover {
    ${Tip} {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
  }
  display: inline-flex;
`;

const TooltipNew = ({
  children,
  tipText = "",
  tipContent = null,
  style = {},
  tipStyle = {},
  isDisabled = false,
}) => {
  const ref = useRef(null);
  const [rect, setRect] = useState(null);
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    setRect(rect);

    const onWheel = e => {
      const rect = ref.current?.getBoundingClientRect();
      setRect(rect);
    };

    document.addEventListener("wheel", onWheel);
    return () => document.removeEventListener("wheel", onWheel);
  }, [isMouseOver]);

  if (tipText) {
    return (
      <Span style={style} ref={ref} isDisabled={isDisabled}>
        {children}
        <DefaultTip
          style={{
            position: "fixed",
            top: rect?.top + rect?.height + 4 ?? 0,
            ...tipStyle,
          }}
        >
          {tipText}
        </DefaultTip>
      </Span>
    );
  }

  return (
    <Span
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      style={style}
      ref={ref}
      isDisabled={isDisabled}
    >
      {children}
      <Tip
        style={{
          position: "fixed",
          top: rect?.top + rect?.height + 4 ?? 0,
          ...tipStyle,
        }}
      >
        {tipContent}
      </Tip>
    </Span>
  );
};

export default TooltipNew;
