import { getWordDoc, patchWordDoc } from "api/backend/wordDocsEndpoints";
import DocArea from "components/DocArea";
import DocToolbar from "components/DocToolbar";
import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { getBlocksFromDoc, getDocFromBlocks } from "utils/word-coversion";

const StyledDocArea = styled(DocArea)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const Container = styled.div`
  height: 100%;
  border-left: 1px solid #e8ecef;
  ${props => props?.isDisabled && `pointer-events: none; opacity: 0.5;`}
`;

const ToolbarContainer = styled.div`
  border-bottom: 1px solid #e8ecef;
  padding: 8px;
`;

const WordDocAreaNew = ({ wordDocId = "", isFillingUp = false }) => {
  const [doc, setDoc] = useState(null);
  const [wordDoc, setWordDoc] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    doPopulateWordDoc();
  }, [wordDocId]);

  const doPopulateWordDoc = async () => {
    const { data } = await getWordDoc(wordDocId);

    setWordDoc(data);
    setDoc(getDocFromBlocks(data?.content?.blocks));
  };

  const doSaveDoc = async () => {
    setIsDisabled(true);
    const { blocks } = getBlocksFromDoc(doc);
    console.log({ blocks });
    const newWordDoc = cloneDeep(wordDoc);
    newWordDoc.content.blocks = blocks;
    const { data } = await patchWordDoc(wordDocId, {}, newWordDoc);

    setWordDoc(data);
    setDoc(getDocFromBlocks(data?.content?.blocks));
    setIsDisabled(false);
  };

  return (
    <Container isDisabled={isDisabled}>
      <ToolbarContainer>
        <DocToolbar
          doc={doc}
          onDocChange={newDoc => setDoc(newDoc)}
          onClickSave={doSaveDoc}
        />
      </ToolbarContainer>
      <StyledDocArea
        topMargin={80}
        doc={doc}
        onDocChange={newDoc => setDoc(newDoc)}
      />
    </Container>
  );
};

export default WordDocAreaNew;
