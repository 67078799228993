import { Visibility } from "@material-ui/icons";
import Modal from "components/ui/Modal";
import { useState } from "react";
import styled from "styled-components";

const Trigger = styled.div`
  cursor: pointer;
  width: max-content;

  svg {
    height: 14px;
  }
  :hover {
    opacity: 0.8;
  }
`;

const ModalContent = styled.div`
  width: 800px;
  height: 600px;
  overflow: auto;
  border-radius: 10px;
  padding: 20px;
`;

const HtmlContent = styled.div`
  white-space: pre-wrap;
  line-height: 1.2;
`;

const ViewHtmlModal = ({ htmlString = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cleanedHtmlStr = htmlString
    ?.replace(/</g, "&lt;")
    ?.replace(/>/g, "&gt;");

  return (
    <>
      <Trigger onClick={() => setIsOpen(true)}>
        <Visibility />
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <HtmlContent dangerouslySetInnerHTML={{ __html: cleanedHtmlStr }} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewHtmlModal;
