import { postAccessTokensTrialUser } from "api/backend/authenticationServiceEndpoints";
import { isStoredTokenInvalid } from "api/services/authenticationService";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import {
  setLoginTokenInClientStorage,
  setTrackIdInClientStorage,
} from "utils/auth-utils";

const useLoginFromCode = () => {
  const [hasLoggedIn, setHasLoggedIn] = useState(false);

  const doLogin = async () => {
    const { data, error } = await postAccessTokensTrialUser({
      token: "666c15f0c113937b2846586d",
    });
    if (error) {
      return;
    }
    setHasLoggedIn(true);
    setLoginTokenInClientStorage(data.accessToken);
    setTrackIdInClientStorage(data?.trackId);
  };

  useEffect(() => {
    if (!isStoredTokenInvalid()) {
      return;
    }

    doLogin();
  }, []);

  return hasLoggedIn;
};

export default useLoginFromCode;
