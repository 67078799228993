import ColumnFieldInput from "components/ColumnFieldInput";
import InnerCategoriesEditor from "components/InnerCategoriesEditor";
import { Gap } from "components/Layout";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import {
  getColumnInstructions,
  getDbTypeFromFormatType,
  getFormatTypeFromDbType,
} from "pages/apps/:pipelineConfigId/settings-new";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const Container = styled.div`
  padding: 10px;
  background-color: #eaeaea;
  padding-left: 50px;
  padding-top: 20px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Td = styled.td`
  position: relative;
  /* border: 1px solid ${props => props.theme.color.closer1}; */
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px 20px;

  font-weight: 600;
  z-index: 1;
`;

const Table = styled.table`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 12px;
  border-collapse: separate;
  background-color: white;
  width: 100%;
`;

const BoldDiv = styled.div`
  font-weight: 500;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props?.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.6;"}
`;

const INNER_COLUMN_FIELDS = [
  { label: "Column Name", name: "displayName" },
  {
    label: "Action type",
    name: "actionType",
    type: "select",
    options: [
      { label: "Extraction", value: "extraction" },
      { label: "Manual", value: "manual" },
      { label: "Reasoning", value: "reasoning" },
    ],
  },
  { label: "Description", name: "description" },
  {
    label: "Type",
    name: "formatType",
    type: "select",
    options: [
      { label: "Text", value: "Text", dbType: "TEXT" },
      { label: "Number", value: "Number", dbType: "NUMBER" },
      { label: "Date", value: "Date", dbType: "DATETIME" },
      { label: "Categorical", value: "Categorical", dbType: "TEXT" },
      { label: "Tags", value: "Tags", dbType: "TEXT" },
      { label: "Rank", value: "Rank", dbType: "NUMBER" },
      { label: "Long Text", value: "Long Text", dbType: "TEXT" },
      { label: "Currency", value: "Currency", dbType: "NUMBER" },
      { label: "Email", value: "Email", dbType: "TEXT" },
      { label: "Phone", value: "Phone", dbType: "TEXT" },
      { label: "Web Link", value: "Web Link", dbType: "TEXT" },
      { label: "Address", value: "Address", dbType: "TEXT" },
      { label: "Percentage", value: "Percentage", dbType: "NUMBER" },
    ],
  },
];

const InnerColumnsEditor = ({
  columns = [],
  onChangeColumns = newColumns => {},
  tableColumn = {},
}) => {
  const addColumn = () => {
    const newColumns = [
      ...(columns || []),
      {
        id: uuidv4(),
        actionType: "extraction",
        description: "",
        displayName: "Name",
        type: "TEXT",
      },
    ];
    onChangeColumns(newColumns);
  };

  const removeColumn = columnId => {
    const newColumns = columns.filter(column => column.id !== columnId);
    onChangeColumns(newColumns);
  };

  const onChangeColumn = (columnId, newValue, key) => {
    const newColumns = columns.map(column => {
      if (column.id === columnId) {
        let newColumn = { ...column, [key]: newValue };
        if (key === "formatType") {
          newColumn.type = getDbTypeFromFormatType(newValue);
        }

        return newColumn;
      }

      return column;
    });

    onChangeColumns(newColumns);
  };

  const isCrm = tableColumn?.formatType === "CRM_TABLE";

  return (
    <Container>
      <Table>
        <thead>
          {INNER_COLUMN_FIELDS.map(field => (
            <Th key={field?.name}>{field?.label}</Th>
          ))}
          <Th />
        </thead>
        <tbody>
          {columns.map((column, index) => {
            const isDisabled = isCrm && index < 4;

            return (
              <>
                <Tr isDisabled={isDisabled}>
                  {INNER_COLUMN_FIELDS.map(field => {
                    let value = column[field?.name];
                    if (field?.name === "formatType" && !value) {
                      value = getFormatTypeFromDbType(field?.type);
                    }

                    return (
                      <Td>
                        <ColumnFieldInput
                          key={field?.name}
                          value={value}
                          onChange={e =>
                            onChangeColumn(
                              column?.id,
                              e.target.value,
                              field?.name
                            )
                          }
                          columnType={field?.type}
                          options={field?.options}
                        />
                      </Td>
                    );
                  })}
                  <Td>
                    <StyledTrashIcon onClick={() => removeColumn(column?.id)} />
                  </Td>
                </Tr>
                {["Categorical", "Tags", "Rank"]?.includes(
                  column?.formatType
                ) && (
                  <tr>
                    <td colSpan={5} style={{}}>
                      <BoldDiv style={{ padding: "10px" }}>
                        {getColumnInstructions(column)}
                      </BoldDiv>
                      <InnerCategoriesEditor
                        isFullDeletionAllowed={column?.formatType !== "Rank"}
                        // savedColumnStrings={column?.categories || []}
                        columnsStrings={column?.categories || []}
                        onChangeColumns={newCategories => {
                          onChangeColumn(
                            column?.id,
                            newCategories,
                            "categories"
                          );
                        }}
                      />
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </Table>
      <Gap height="10px" />
      <StyledPlusIcon onClick={() => addColumn()} />
    </Container>
  );
};

export default InnerColumnsEditor;
