import { postBulkUploadFormData } from "api/services/filesService";
import usePollFiles from "api/services/usePollFiles";
import { InfoMsg } from "components/DocSourcesModalTriggerNew";
import { Gap } from "components/Layout";
import ButtonIcon from "components/ui/ButtonIcon";
import {
  CrossIcon,
  InformationIcon,
  PdfIcon,
  TickIcon,
  UploadCloudIcon,
  UploadFileIcon,
  WarningIcon,
} from "components/ui/Icons";
import { uniqBy } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  height: 100%;
`;

const Label = styled.label`
  cursor: pointer;
  width: max-content;
  display: flex;

  input {
    display: none;
  }
  :hover {
    opacity: 0.6;
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  padding: 0 22px;
  font-weight: 500;
`;

const StyledProgress = styled.progress`
  // pulsing opacity animation
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  animation: pulse 1s infinite;
`;

const DropArea = styled.div`
  width: 100%;
  padding: 20px;
  border: 2px dashed #cdcdcd;
  color: #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  svg {
    height: 32px;
    margin-bottom: 16px;
    fill: #cdcdcd;
  }
  border-radius: 10px;
`;

const DirItem = styled.label`
  line-height: 1.5;
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  gap: 8px;
  grid-template-columns: 16px 1fr auto auto;
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

const ErrText = styled.div`
  font-weight: 500;
  line-height: 1.2;
  color: ${props => props.theme.color.error};
  padding: 4px 0;
`;

const MIN_PROGRESS = 5;

const BlueButton = styled(ButtonIcon)`
  font-weight: 500;
  justify-self: end;
  align-self: end;
  padding: 4px;
  margin-top: 20px;
  width: max-content;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilesCount = styled.div`
  margin: 10px 0;
  font-weight: 500;
`;

const FilesContainer = styled.div`
  display: grid;
  gap: 4px;
  align-content: start;
  height: 80px;
  overflow: auto;
  border-bottom: 1px solid #eaeaea;
`;

const FileItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  height: 16px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  svg {
    height: 12px;
    width: 12px;
  }
`;

const FileInputAreaGeneric = ({ onChange = e => {} }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!files?.length) {
      return;
    }

    onChange({ target: { files } });
  }, [files?.length]);

  return (
    <Container>
      <DropArea
        onDrop={e => {
          e.preventDefault();
          setFiles(prev => [...prev, ...e.dataTransfer.files]);
        }}
        onDragOver={e => {
          e.preventDefault();
        }}
      >
        <UploadFileIcon />
        Drop files here or use the "Upload content" button
        <Gap />
        <Label>
          <StyledButtonIcon isActive>Upload files</StyledButtonIcon>
          <input
            type="file"
            multiple
            accept=".pdf, .docx"
            onChange={e => {
              setFiles(prev => [...prev, ...e.target.files]);
            }}
          />
        </Label>
      </DropArea>
      <FilesCount style={{ opacity: files?.length > 0 ? 1 : 0 }}>
        {files?.length} files
      </FilesCount>
      <FilesContainer style={{ opacity: files?.length > 0 ? 1 : 0 }}>
        {files.map(file => (
          <FileItem>
            <PdfIcon />
            {file.name}
            <CrossIcon />
          </FileItem>
        ))}
      </FilesContainer>
    </Container>
  );
};

export default FileInputAreaGeneric;
