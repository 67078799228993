import { Gap } from "components/Layout";
import LoadingSpinner from "components/ui/LoadingSpinner";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
  min-width: 400px;
  height: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
  background-color: ${props => props.theme.color.closer1}55;
`;

const ImgAndRectContainer = styled.div`
  position: relative;
`;

const Img = styled.img`
  width: 100%;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Rect = styled.div`
  position: absolute;
  cursor: pointer;
  outline: 1px solid #fff70088;
  background-color: #fff70022;

  :hover {
    background-color: ${props => props.theme.color.success}88;
  }
`;

const RectThatScrollsIntoView = ({ ...props }) => {
  const ref = useRef(null);

  // useEffect(() => {
  //   if (props?.hasReferenceBorder) {
  //     console.log("scrolling into view", {
  //       props,
  //       "ref?.current": ref?.current,
  //     });
  //     ref?.current?.scrollIntoView();
  //   }
  // }, [props?.hasReferenceBorder]);

  return <Rect ref={ref} {...props} />;
};

const ImgWithRectangleFlexible = ({
  className,
  src,
  selectedAnnotationId,
  newAnnotationId,
  onClickBox = id => {},
  rectangles = [{ x: 0, y: 0, w: 0, h: 0, id: 0 }],
  isLoading,
  zoomFactor = 1,
}) => {
  const [scaleFactorY, setScaleFactorY] = useState(1);
  const [scaleFactorX, setScaleFactorX] = useState(1);

  if (isLoading) {
    return (
      <ImgAndRectContainer>
        <Gap height="40px" />
        <LoadingSpinner />
        {/* <LoadingContainer /> */}
      </ImgAndRectContainer>
    );
  }

  return (
    <ImgAndRectContainer
      style={{
        width: `calc(100% * ${zoomFactor})`,
        height: `calc(100% * ${zoomFactor})`,
        // transformOrigin: "top left",
        // transform: `scale(${zoomFactor}, ${zoomFactor})`,
      }}
      className={className}
    >
      <Img
        // style={{
        //   transformOrigin: "top left",
        //   transform: `scale(${zoomFactor}, ${zoomFactor})`,
        // }}
        src={src}
        alt="page preview"
        onLoad={e => {
          setScaleFactorX(e?.target?.width / e?.target?.naturalWidth);
          setScaleFactorY(e?.target?.height / e?.target?.naturalHeight);
        }}
      />
      {rectangles?.map((rectangle, i) => (
        <RectThatScrollsIntoView
          key={`rect-${rectangle?.y}-${rectangle?.x}-${rectangle?.w}-${rectangle?.h}-${rectangle?.id}`}
          style={{
            top: rectangle?.y * scaleFactorY * zoomFactor,
            left: rectangle?.x * scaleFactorX * zoomFactor,
            width: rectangle?.w * scaleFactorX * zoomFactor,
            height: rectangle?.h * scaleFactorY * zoomFactor,
          }}
        />
      ))}
    </ImgAndRectContainer>
  );
};

export default ImgWithRectangleFlexible;
