import styled from "styled-components";
import { TrashIcon } from "components/ui/Icons";
import SmallButton from "components/ui/SmallButton";
import { useState } from "react";
import Modal from "components/ui/Modal";
import ButtonWord from "components/ui/ButtonWord";

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ModalContent = styled.div`
  padding: 24px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 24px;
`;

const ModalText = styled.div`
  font-size: 18px;
  max-width: 400px;
  line-height: 1.5;
  text-align: center;
`;

const BottomButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const BottomButton = styled(ButtonWord)`
  padding: 2px 8px;
`;

const TriggerContainer = styled.div`
  /* ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}; */
`;

const ConfirmationModalTrigger = ({
  icon = <StyledTrashIcon />,
  message = "Are you sure you want to delete?",
  yesText = "Yes",
  onClickYes = () => {},
  isDisabled = false,
  style = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TriggerContainer
        style={style}
        isDisabled={isDisabled}
        onClick={() => {
          if (isDisabled) {
            return;
          }
          setIsOpen(true);
        }}
      >
        {icon}
      </TriggerContainer>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalText dangerouslySetInnerHTML={{ __html: message }} />
          <BottomButtons>
            <BottomButton
              onClick={() => {
                onClickYes();
                setIsOpen(false);
              }}
            >
              {yesText}
            </BottomButton>
            <BottomButton onClick={() => setIsOpen(false)}>Cancel</BottomButton>
          </BottomButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmationModalTrigger;
