import styled from "styled-components";
import { getColorFromString } from "utils/common";

const StyledInput = styled.input`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 8px 14px;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
  }
`;

const ColumnFieldInput = ({
  style = {},
  value = "",
  onChange = e => {},
  columnType = "",
  options = [],
}) => {
  if (columnType === "select") {
    const backgroundColor = `${getColorFromString(`${value || "Text"}`)}55`;

    return (
      <StyledSelect
        style={{ ...style, backgroundColor }}
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </StyledSelect>
    );
  }

  return <StyledInput style={style} value={value} onChange={onChange} />;
};

export default ColumnFieldInput;
