import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  FormatBold,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Image as ImageIcon,
  Redo,
  Undo,
  Link as LinkIcon,
  Remove,
  GroupAdd,
  FileCopy,
  ChevronRight,
  ChevronLeft,
} from "@material-ui/icons";

import {
  getSlideDoc,
  getSlideDocsLoadImage,
  patchSlideDoc,
  postSlideDocsUploadImage,
} from "api/services/slideDocsService";
import ColorPickerTooltip from "components/ui/ColorPickerTooltip";
import {
  ChatIcon,
  CrossIcon,
  DownloadIcon,
  FilesIcon,
  PlusIcon,
  SlidesIcon,
} from "components/ui/Icons";
import DocSourcesModalTrigger from "components/DocSourcesModalTrigger";
import { getBase64FromImageFile, uuidv4 } from "utils/common";
import ItemListPopover from "components/ItemListPopover";
import { triggerDownloadOfFile } from "api/backend/filesEndpoints";
import NavHeader from "components/NavHeader";
import Slide, {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  SF,
  drawConfig,
} from "components/Slide";
import { clamp, cloneDeep, merge, uniq } from "lodash";
import { useRef } from "react";
import useCacheImages from "hooks/useCacheImages";
import ModelSelectDropdown from "components/ModelSelectDropdown";
import ShareDirectoryItemModalTrigger from "components/ShareDirectoryItemModalTrigger";
import TooltipNew from "components/ui/TooltipNew";
import WordDocSearchResultsModal from "components/WordDocSearchResultsModal";
import useSearchParamsState from "hooks/useSearchParamsState";
import ChatView from "components/ChatView";
import LayoutNew from "components/LayoutNew";

const getImagePaths = slideDoc => {
  const imagePaths = [];
  slideDoc?.content?.slides?.forEach(slide => {
    slide.boxes.forEach(box => {
      if (box.imageRefUrl) {
        imagePaths.push(box.imageRefUrl);
      }
    });
  });

  return imagePaths;
};

const enrichSlideDocWithImages = (slideDoc, pathToBase64) => {
  const newSlideDoc = cloneDeep(slideDoc);
  newSlideDoc?.content?.slides?.forEach(slide => {
    slide.boxes.forEach(box => {
      if (box.imageRefUrl && pathToBase64[box.imageRefUrl]) {
        box.canvasImage = new Image();
        box.canvasImage.src = pathToBase64[box.imageRefUrl];
      }
    });
  });

  return newSlideDoc;
};

const removeBased64sFromSlideDoc = slideDoc => {
  const newSlideDoc = cloneDeep(slideDoc);
  if (!newSlideDoc?.content?.slides) {
    return newSlideDoc;
  }
  newSlideDoc.content.slides.forEach(slide => {
    slide.boxes.forEach(box => {
      delete box.image;
      delete box.canvasImage;
    });
  });

  return newSlideDoc;
};

const OuterContainer = styled.div`
  display: grid;
  height: 100vh;
  overflow: hidden;
  user-select: none;
`;

const Container = styled.div`
  height: 100vh;
  display: grid;
  align-items: start;
  align-content: start;
  background-color: #f9fbfd;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  grid-template-rows: auto auto 1fr;
`;

const EditableDocNameInput = styled.input`
  margin: 0;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;

  display: block;
  align-self: center;
  border: none;
  width: 50%;
  outline: none;
  background-color: #f9fbfd;

  :focus {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const DocNameAndSourcesModal = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto auto;
  align-items: center;
  background-color: #ffffff;
  align-self: center;
  position: sticky;
  z-index: 3;
  height: 50px;
  border-bottom: 1px solid #e0e0e0;
`;

const ToolbarContainer = styled.div`
  position: relative;
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};

  padding: 1px 8px;
  margin: 0px 16px;
  margin-bottom: 0px;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.closer0};
`;

const IconButton = styled.div`
  position: relative;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.isActive && `background-color: ${props.theme.color.primary}22;`}

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.closest};
    height: 18px;
  }

  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  outline: none;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  margin: 0 5px;
`;

const ColoredIconButton = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }

    path:nth-child(2) {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ColoredIconButtonBucket = styled(IconButton)`
  svg {
    fill: ${props => props.theme.color.closest};
    color: ${props => props.theme.color.closest};

    path:nth-child(2) {
      fill: ${props => props.color || props.theme.color.closest};
      fill-opacity: 1;
    }
  }
`;

const TopMenuBar = styled.div`
  display: flex;
`;

const TopMenuBarItem = styled.div`
  font-weight: normal;
  padding: 2px 8px;
  font-size: 14px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  cursor: pointer;
  z-index: 4;
`;

const InputAndMenuBar = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;
  border: none;
  box-shadow: ${props => props.theme.shadow};
  left: 0;
  width: 200px;
  transform: translate(0, 4px);
`;

const PopoverItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  width: 200px;
  padding: 8px 14px;
  color: black;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PopoverItemBtn = styled(PopoverItemLink).attrs({ as: "div" })``;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${props => props.theme.color.closer1_5};
  height: 20px;
  padding: 10px 0;
  margin: 0 5px;
`;

const StyledNavHeader = styled(NavHeader)`
  padding: 0;
  padding-right: 10px;
  height: 100%;
  position: relative;
  background-color: #f9fbfd;
  width: max-content;
  box-shadow: none;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
`;

const Sidebar = styled.div`
  height: calc(100vh - 87px);
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  justify-items: center;
  align-content: start;
  gap: 10px;
  padding: 20px;
  border-right: 1px solid ${props => props.theme.color.closer1_5};
`;

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0;
  transition: opacity 0.2s;
  :hover {
    fill: ${props => props.theme.color.error};
  }
`;

const StyledFilesIcon = styled(FilesIcon)`
  position: absolute;
  bottom: 4px;
  right: 4px;
  opacity: 0;
  height: 16px;
  transition: opacity 0.2s;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const Thumbnail = styled.div`
  position: relative;
  width: 160px;
  height: ${160 * (CANVAS_HEIGHT / CANVAS_WIDTH)}px;
  border: 1px solid
    ${props =>
      props.isHighlighted
        ? props.theme.color.primary
        : props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.furthest};

  :hover {
    opacity: 0.6;
    ${StyledCrossIcon} {
      opacity: 1;
    }
    ${StyledFilesIcon} {
      opacity: 1;
    }
  }
  cursor: pointer;
`;

const ThumbnailCanvasEl = styled.canvas`
  transform-origin: top left;
  transform: scale(calc(160 / ${CANVAS_WIDTH * SF}));
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const ModalTrigger = styled.div`
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000088;

  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const ModalTriggerWide = styled.div`
  cursor: pointer;
  border-radius: 0px;
  padding: 0 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000088;

  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background-color: #d9d9d9;
  }
`;

const isEditingText = config => {
  if (!config?.boxes) {
    return false;
  }
  return config.boxes
    ?.find(box => box.isSelected)
    ?.styles?.find(s => s.isSelection);
};

const ThumbnailCanvas = ({ config = {} }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    drawConfig(ctx, config);
  }, [JSON.stringify(config)]);

  return (
    <ThumbnailCanvasEl
      ref={canvasRef}
      width={CANVAS_WIDTH * SF}
      height={CANVAS_HEIGHT * SF}
    />
  );
};

const SlideDocPage = () => {
  const [searchParams] = useSearchParams();
  const { slideDocId } = useParams();

  const [slideDoc, setSlideDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);

  const pathToBase64 = useCacheImages({ imagePaths: getImagePaths(slideDoc) });

  const [isSidebarOpen, setIsSidebarOpen] = useSearchParamsState({
    paramName: "isSidebarOpen",
    initialValue: false,
  });

  useEffect(() => {
    const slideDocWithImages = enrichSlideDocWithImages(slideDoc, pathToBase64);
    setSlideDoc(slideDocWithImages);
  }, [JSON.stringify(pathToBase64)]);

  const selectedSlide = slideDoc?.content?.slides?.[slideIndex];

  const isReadOnly = searchParams?.get("inSourceFiles") === "true";

  const onKeyDown = e => {
    const selectedSlide = slideDoc?.content?.slides?.[slideIndex];
    if (isEditingText(selectedSlide)) {
      return;
    }

    const lastSlideIndex = slideDoc?.content?.slides?.length - 1 || 0;
    if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
      setSlideIndex(prev => clamp(prev - 1, 0, lastSlideIndex));
    }

    if (e.key === "ArrowRight" || e.key === "ArrowDown") {
      setSlideIndex(prev => clamp(prev + 1, 0, lastSlideIndex));
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [slideDoc?.content]);

  useEffect(() => {
    doPopulateSlideDoc();
  }, [slideDocId]);

  const doPopulateSlideDoc = async () => {
    const { data } = await getSlideDoc(slideDocId, { excludeBase64: true });
    setSlideDoc(data);
    setIsLoading(false);
    console.log(data);
  };

  const doSaveDoc = async () => {
    setIsLoading(true);
    const payloadDoc = removeBased64sFromSlideDoc(slideDoc);
    const { data } = await patchSlideDoc(slideDocId, {}, payloadDoc);
    setSlideDoc(enrichSlideDocWithImages(data, pathToBase64));
    setIsLoading(false);
  };

  const addStyleAtSelection = (styleFields = {}) => {
    const newSlideDoc = cloneDeep(slideDoc);
    const selectedSlide = newSlideDoc?.content?.slides?.[slideIndex];
    const selectedBox = selectedSlide?.boxes?.find(box => box.isSelected);
    const selectedStyle = selectedBox?.styles?.find(style => style.isSelection);
    if (!selectedStyle) {
      return;
    }

    const newStyle = {
      start: selectedStyle?.start,
      end: selectedStyle?.end,
      ...styleFields,
    };

    selectedBox.styles.push(newStyle);
    setSlideDoc(newSlideDoc);
  };

  const addBoxStyle = (styleFields = {}) => {
    const newSlideDoc = cloneDeep(slideDoc);
    const selectedSlide = newSlideDoc?.content?.slides?.[slideIndex];
    const selectedBox = selectedSlide?.boxes?.find(box => box.isSelected);

    selectedBox.boxStyle = {
      ...selectedBox.boxStyle,
      ...styleFields,
    };
    setSlideDoc(newSlideDoc);
  };

  const addSlideStyle = (styleFields = {}) => {
    const newSlideDoc = cloneDeep(slideDoc);
    const selectedSlide = newSlideDoc?.content?.slides?.[slideIndex];
    selectedSlide.bgColor = styleFields.bgColor;
    setSlideDoc(newSlideDoc);
  };

  const selectedBox = selectedSlide?.boxes?.find(box => box.isSelected);
  const selectionStyle = selectedBox?.styles?.find(style => style.isSelection);
  const stylesInSelection = selectedBox?.styles?.filter(
    style =>
      style.start <= selectionStyle?.end && style.end >= selectionStyle?.start
  );
  const topBarStyle = merge(...cloneDeep(stylesInSelection || []));

  return (
    <OuterContainer
      style={{
        gridTemplateColumns: isSidebarOpen ? "auto 300px" : "auto 0px",
      }}
    >
      <Container isDisabled={isLoading}>
        <DocNameAndSourcesModal>
          <Link to="/">
            <SlidesIcon
              height="32px"
              style={{
                paddingLeft: "14px",
                paddingRight: "10px",
                fill: "#a27c26",
              }}
            />
          </Link>
          <InputAndMenuBar>
            <EditableDocNameInput
              maxWidth={600}
              value={slideDoc?.fileName}
              onChange={e => {
                setSlideDoc({
                  ...slideDoc,
                  fileName: e.target.value,
                });
              }}
              onBlur={doSaveDoc}
            />
          </InputAndMenuBar>

          <ModalTrigger
            style={{ marginRight: "10px" }}
            onClick={() =>
              triggerDownloadOfFile(slideDocId, { fileType: "WORD_DOC" })
            }
          >
            <DownloadIcon style={{ height: "14px", width: "14px" }} />
          </ModalTrigger>
          <ShareDirectoryItemModalTrigger
            trigger={
              <ModalTrigger style={{ marginRight: "10px" }}>
                <GroupAdd />
              </ModalTrigger>
            }
            directoryItem={{
              id: slideDocId,
              fileName: slideDoc?.fileName,
              type: "WORD_DOC",
              sharedWith: slideDoc?.sharedWith || [],
            }}
          />

          {/* <button onClick={() => setArePromptsVisible(!arePromptsVisible)}>
            {arePromptsVisible ? "Hide Prompts" : "Show Prompts"}
          </button> */}
          <ModelSelectDropdown />
          <DocSourcesModalTrigger
            trigger={
              <ModalTriggerWide style={{ margin: "0 10px" }}>
                <TooltipNew
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  tipStyle={{ transform: "translate(-3.5px, 6px)" }}
                  tipText="Add PDF, Word, Excel"
                >
                  <span style={{ fontWeight: 600 }}>
                    {slideDoc?.content?.sources?.length || 0} source
                    {slideDoc?.content?.sources?.length === 1 ? "" : "s"}
                  </span>
                  <FileCopy />
                </TooltipNew>
              </ModalTriggerWide>
            }
            sources={slideDoc?.content?.sources}
            onChangeSources={newSources => {}}
          />
          <StyledNavHeader />
        </DocNameAndSourcesModal>

        <ToolbarContainer
          style={{
            opacity: isReadOnly ? 0.5 : 1,
            pointerEvents: isReadOnly ? "none" : "auto",
          }}
        >
          <WordDocSearchResultsModal />
          <IconButton>
            <Undo />
          </IconButton>
          <IconButton>
            <Redo />
          </IconButton>
          <VerticalLine />
          <StyledSelect
            value={selectedBox?.boxStyle?.fontSize}
            onChange={e => addBoxStyle({ fontSize: e.target.value })}
          >
            <option>14</option>
            <option>16</option>
            <option>18</option>
            <option>20</option>
            <option>24</option>
            <option>28</option>
            <option>32</option>
            <option>36</option>
            <option>48</option>
          </StyledSelect>
          <VerticalLine />
          <IconButton
            isActive={topBarStyle?.fontWeight === "bold"}
            onClick={() =>
              addStyleAtSelection({
                fontWeight:
                  topBarStyle?.fontWeight === "bold" ? "normal" : "bold",
              })
            }
          >
            <FormatBold />
          </IconButton>
          <IconButton isActive={false} onClick={() => {}}>
            <FormatItalic />
          </IconButton>
          <IconButton>
            <FormatUnderlined />
          </IconButton>
          <ColorPickerTooltip
            selectedColor={
              isEditingText(selectedSlide)
                ? topBarStyle?.color
                : selectedBox?.boxStyle?.color
            }
            onNewColor={color => {
              if (!isEditingText(selectedSlide)) {
                addBoxStyle({ color });
                return;
              }
              addStyleAtSelection({
                color,
              });
            }}
            triggerIcon={
              <ColoredIconButton color={topBarStyle?.color}>
                <FormatColorText />
              </ColoredIconButton>
            }
          />
          <ColorPickerTooltip
            selectedColor={
              selectedBox?.boxStyle?.bgColor || selectedSlide?.bgColor
            }
            onNewColor={bgColor => {
              if (!selectedBox) {
                addSlideStyle({ bgColor });
                return;
              }
              addBoxStyle({ bgColor });
            }}
            triggerIcon={
              <ColoredIconButtonBucket color={selectedBox?.boxStyle?.bgColor}>
                <FormatColorFill />
              </ColoredIconButtonBucket>
            }
          />
          <VerticalLine />
          <IconButton>
            <LinkIcon />
          </IconButton>
          <IconButton>
            <HiddenInput
              type="file"
              onChange={async e => {
                const file = e.target.files[0];
                const base64Str = await getBase64FromImageFile(file);

                const imageToInsert = new Image();
                imageToInsert.src = base64Str;

                const id = uuidv4();
                const { data } = await postSlideDocsUploadImage(
                  slideDocId,
                  {},
                  {
                    base64Image: base64Str?.split("base64,")?.[1],
                    fileName: `${id}.png`,
                  }
                );

                const newSlideDoc = cloneDeep(slideDoc);
                const newSlide = newSlideDoc.content.slides[slideIndex];
                const newBox = {
                  id,
                  x: 100,
                  y: 100,
                  w: imageToInsert.width * (400 / imageToInsert.height),
                  h: 400,
                  styles: [],
                  boxStyle: {
                    bgColor: "transparent",
                  },
                  isSelected: true,
                  imageRefUrl: data,
                };
                newSlide.boxes = [newBox, ...newSlide.boxes];
                setSlideDoc(newSlideDoc);
              }}
            />
            <ImageIcon />
          </IconButton>
          <VerticalLine />
          <IconButton onClick={() => {}}>
            <FormatIndentIncrease />
          </IconButton>
          <IconButton onClick={() => {}}>
            <FormatIndentDecrease />
          </IconButton>
          <IconButton isActive={false} onClick={() => {}}>
            <FormatListBulleted />
          </IconButton>
          <VerticalLine />
          border width
          <StyledSelect
            value={selectedBox?.boxStyle?.lineWidth}
            onChange={e => addBoxStyle({ lineWidth: e.target.value })}
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
          </StyledSelect>
          <VerticalLine />
          <IconButton
            isActive={selectedBox?.boxStyle?.type === "line"}
            onClick={() => {
              addBoxStyle({ type: "line", lineWidth: 1 });
            }}
          >
            <Remove />
          </IconButton>
          {selectedBox?.boxStyle?.type === "line" && (
            <>
              <VerticalLine />
              end shape
              <StyledSelect
                value={selectedBox?.boxStyle?.lineEnd}
                onChange={e => addBoxStyle({ lineEnd: e.target.value })}
              >
                <option></option>
                <option>arrow</option>
              </StyledSelect>
            </>
          )}
          <IconButton
            style={{
              position: "absolute",
              right: 10,
              width: "auto",
              paddingRight: 8,
            }}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? <ChevronRight /> : <ChevronLeft />}
            <ChatIcon />
          </IconButton>
        </ToolbarContainer>

        <Content>
          <Sidebar>
            {slideDoc?.content?.slides?.map((slide, i) => (
              <Thumbnail
                key={i}
                isHighlighted={slideIndex === i}
                onClick={() => setSlideIndex(i)}
                style={{ background: slide?.bgColor || "#ffffff" }}
              >
                {/* <img
                style={{
                  opacity: slide?.thumbnailBase64Src ? 1 : 0,
                  width: "100%",
                }}
                src={slide?.thumbnailBase64Src}
                alt="thumbnail"
              /> */}
                <ThumbnailCanvas config={slide} />
                <StyledCrossIcon
                  onClick={() => {
                    const newSlideDoc = cloneDeep(slideDoc);
                    newSlideDoc.content.slides.splice(i, 1);
                    setSlideDoc(newSlideDoc);
                  }}
                />
                <StyledFilesIcon
                  onClick={() => {
                    const newSlideDoc = cloneDeep(slideDoc);
                    const newSlide = cloneDeep(newSlideDoc.content.slides[i]);
                    newSlide.id = uuidv4();
                    newSlideDoc.content.slides.splice(i + 1, 0, newSlide);
                    setSlideDoc(newSlideDoc);
                  }}
                />
              </Thumbnail>
            ))}
            <StyledPlusIcon
              onClick={() => {
                const newSlide = {
                  bgColor: "#ffffff",
                  boxes: [],
                };
                const newSlideDoc = cloneDeep(slideDoc);

                if (!newSlideDoc?.content) {
                  newSlideDoc.content = {};
                }
                if (!newSlideDoc?.content?.slides) {
                  newSlideDoc.content.slides = [];
                }
                newSlideDoc.content.slides.push(newSlide);
                setSlideDoc(newSlideDoc);
              }}
            />
          </Sidebar>
          <Slide
            slideIndex={slideIndex}
            config={selectedSlide}
            setConfig={newConfig => {
              if (!slideDoc) {
                return;
              }
              const newSlideDoc = cloneDeep(slideDoc);
              if (!newSlideDoc.content.slides) {
                newSlideDoc.content.slides = [];
              }
              newSlideDoc.content.slides[slideIndex] = newConfig;
              setSlideDoc(newSlideDoc);
            }}
          />
        </Content>
      </Container>

      <div
        style={{
          zIndex: 2,
          backgroundColor: "white",

          width: "300px",
          borderLeft: "1px solid lightgrey",
        }}
      >
        <ChatView />
      </div>
    </OuterContainer>
  );
};

export default SlideDocPage;
