import { useState } from "react";
import styled from "styled-components";

import { GearIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import ButtonWord from "components/ui/ButtonWord";
import { useEffect } from "react";
import { Gap } from "components/Layout";
import { cloneDeep, isEmpty } from "lodash";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const ModalTrigger = styled.div`
  cursor: pointer;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  width: 600px;
  height: 400px;

  display: grid;
  grid-template-rows: 1fr auto;
`;

const BottomBar = styled.div`
  display: grid;
  justify-content: end;
  padding-top: 10px;
  border-top: 1px solid ${props => props.theme.color.closer1};
`;

const Container = styled.div`
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Td = styled.td`
  padding: 6px 8px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  vertical-align: middle;
`;

const ThreeDots = styled.div`
  cursor: move;
  font-weight: 900;
  font-size: 20px;
`;

const Table = styled.table`
  width: 100%;
`;

const Tr = styled.tr`
  background-color: ${props =>
    props.isHighlighted ? "#00000022" : "transparent"};
  cursor: pointer;
  :hover {
    background-color: #00000011;
  }
`;

const isValidColumnsList = (value, columns) => {
  if (value?.length !== columns?.length) {
    return false;
  }

  if (isEmpty(value)) {
    return false;
  }

  return value?.every(col => columns?.some(c => c?.dbName === col?.dbName));
};

// TODO: fix strange re-arrange behaviour
const moveColumnToIndex = (columns, dbName, targetIndex) => {
  const columnsCopy = cloneDeep(columns);
  const dropColumnIndex = columnsCopy.findIndex(
    column => column.dbName === dbName
  );
  const droppedColumn = columnsCopy?.[dropColumnIndex];
  let newColumns = cloneDeep(columnsCopy)?.filter(col => col.dbName !== dbName);

  let sliceIndex = targetIndex;
  if (dropColumnIndex < targetIndex) {
    sliceIndex = targetIndex - 1;
  }

  newColumns = [
    ...newColumns?.slice(0, sliceIndex),
    droppedColumn,
    ...newColumns?.slice(sliceIndex),
  ];

  return newColumns;
};

/* 
value = [
  { dbName: "name", isHidden: true },
  ...
]
*/
const ColumnsListModal = ({
  value = [],
  columns = [],
  onPressSave = newValue => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedValue, setEditedValue] = useState(value || []);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    if (!isValidColumnsList(value, columns)) {
      setEditedValue(columns);
      return;
    }

    setEditedValue(value || []);
  }, [JSON.stringify(value), JSON.stringify(columns)]);

  const onChangeTickbox = dbName => {
    const newValue = editedValue.map(column => {
      if (column?.dbName === dbName) {
        return { ...column, isHidden: !column?.isHidden };
      }
      return column;
    });

    setEditedValue(newValue);
  };

  const onDrop = (e, columnIndex) => {
    e.preventDefault();
    setHighlightedIndex(-1);
    const droppedDbName = e.dataTransfer.getData("application/dbName");
    const newColumns = moveColumnToIndex(
      editedValue,
      droppedDbName,
      columnIndex
    );
    setEditedValue(newColumns);
  };

  const onDragOver = (e, overIndex) => {
    e.preventDefault();
    setHighlightedIndex(overIndex);
  };

  const onDragStart = (e, dbName) => {
    e.dataTransfer.setData("application/dbName", dbName);
    e.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      <ModalTrigger onClick={() => setIsOpen(true)}>
        <GearIcon />
      </ModalTrigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Container>
            <Title>Choose columns to show</Title>
            <Gap />
            <Table>
              <tbody>
                {editedValue?.map((column, i) => (
                  <Tr
                    key={column?.dbName}
                    isHighlighted={highlightedIndex === i}
                    draggable
                    onDragStart={e => onDragStart(e, column?.dbName)}
                    onDrop={e => onDrop(e, i)}
                    onDragOver={e => onDragOver(e, i)}
                    onDragLeave={e => (e.target.style.backgroundColor = "")}
                    onClick={() => onChangeTickbox(column?.dbName)}
                  >
                    <td style={{ verticalAlign: "middle" }}>
                      <ThreeDots>⋮</ThreeDots>
                    </td>
                    <Td>{column?.displayName}</Td>
                    <Td>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {column?.isHidden ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </div>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </Container>
          <BottomBar>
            <ButtonWord
              onClick={() => {
                onPressSave(editedValue);
                setIsOpen(false);
              }}
            >
              Save
            </ButtonWord>
          </BottomBar>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ColumnsListModal;
