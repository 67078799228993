import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import ButtonWord from "components/ui/ButtonWord";
import DocSourcesModalTriggerNew from "components/DocSourcesModalTriggerNew";
import {
  CrossIcon,
  FolderIcon,
  PdfIcon,
  PencilIcon,
} from "components/ui/Icons";
import { postWordDoc } from "api/services/wordDocsService";
import TemplateEditorModal from "components/TemplateEditorModal";
import { postRegenAll } from "api/backend/searchServiceEndpoints";
import { uuidv4 } from "utils/common";

const ModalContent = styled.div`
  padding: 40px;
  display: grid;
  justify-items: start;
  gap: 15px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  width: 800px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Cards = styled.div`
  display: flex;
  gap: 10px;
`;

const DirItem = styled.label`
  width: 100%;
  padding: 4px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 24px 18px 1fr;
  font-weight: 500;
  align-items: center;
  justify-items: start;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  svg {
    height: 12px;
  }
`;

const ShortDiv = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Card = styled.div`
  position: relative;
  width: 200px;
  flex-shrink: 0;
  height: 120px;
  background-color: #f3f3f3;
  border-radius: 18px;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid
    ${props => (props?.isSelected ? props.theme.color.primary : "transparent")};
  transition: background-color 0.2s;
  :hover {
    background-color: #eaeaea;
    box-shadow: ${props => props.theme.shadowWeak};
  }
  font-weight: 500;
  padding-bottom: 0;

  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 2px;
  line-height: 1.5;
`;

const Title = styled.div`
  font-size: 16px;
`;

const SubTitle = styled.div``;

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  overflow: hidden;
`;

const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
`;

const ModalSub = styled.div`
  font-weight: 600;
`;

const StyledPencilIcon = styled(PencilIcon)`
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

/*
Analyse {{Company 1}}: write a summary of the company’s financial performance that covers revenue, income, operational cost and competitive analysis
Analyse {{Company 2}}: write a summary of the company’s financial performance that covers revenue, income, operational cost and competitive analysis
Comparison: compare the content above to make advise on general investment strategy
Conclusion: summary the content above and give an overview of investment advice.
*/
const TEMPLATES = [
  {
    id: "0",
    title: "Blank",
    subtitle: "",
    description: "",
    fileName: "Untitled Doc",
    sections: [],
  },
  {
    id: "1",
    title: "Financial Report",
    fileName: "Financial Report",
    subtitle: "[month] [year]",
    description:
      "The key indicators for this year were as follows and the financial report is as follows. The key indicators for this year were as follows and the financial report is as follows.",
    sections: [
      {
        name: "Analyse {{Company 1}}",
        description:
          "write a summary of the company’s financial performance that covers revenue, income, operational cost and competitive analysis",
      },
      {
        name: "Analyse {{Company 2}}",
        description:
          "write a summary of the company’s financial performance that covers revenue, income, operational cost and competitive analysis",
      },
      {
        name: "Comparison",
        description:
          "compare the content above to make advise on general investment strategy",
      },
      {
        name: "Conclusion",
        description:
          "summary the content above and give an overview of investment advice.",
      },
    ],
  },
];

const EXAMPLE_BLOCKS = [
  {
    text: "",
    styles: [
      {
        start: 0,
        end: -1,
      },
    ],
    startI: 0,
  },
  {
    text: "",
    styles: [],
    startI: 0,
  },
  {
    text: "What is the doc about in a few words",
    isQuery: true,
    queryId: "0e7c0ac024c34cb9b75bb0ae11c3683e",
  },
  {
    text: "How long is the report in a few words",
    isQuery: true,
    queryId: "3d0f28fa9a444627979ea4ce1db8b698",
  },
  {
    text: "",
    styles: [],
    startI: 161,
  },
];

const getBlocksFromSections = sections => {
  let blocks = [{ text: "", styles: [] }];
  sections.forEach(section => {
    let titleBlock = { text: section?.name, styles: [] };
    let queryBlock = {
      isQuery: true,
      text: section?.description,
      styles: [],
      queryId: uuidv4(),
    };
    let gapBlock = { text: "", styles: [] };

    blocks.push(titleBlock);
    blocks.push(queryBlock);
    blocks.push(gapBlock);
  });
  return blocks;
};

const ITEM_TYPE_TO_ICON = {
  FILE: <PdfIcon style={{ fill: "#ed1c24" }} />,
  DIRECTORY: <FolderIcon />,
};
const getIconFromItem = item => {
  return ITEM_TYPE_TO_ICON?.[item?.type];
};

const CreateWordModalTrigger = ({
  trigger = null,
  onDocCreated = () => {},
  directoryPath = "/working-files",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [templates, setTemplates] = useState(TEMPLATES);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    TEMPLATES?.[0]?.id
  );
  const [sources, setSources] = useState([]);

  const selectedTemplate = TEMPLATES.find(
    template => template.id === selectedTemplateId
  );

  const doCreateDoc = async () => {
    if (!selectedTemplate?.sections?.length) {
      const { data, error } = await postWordDoc(
        {},
        {
          fileName: `Untitled Doc`,
          directoryPath,
          content: { blocks: [{ text: "" }], sources },
        }
      );
      if (!error) {
        onDocCreated(data);
        window.open(`/word-docs/${data?.id}`, "_blank");
      }
      return;
    }

    const { data } = await postRegenAll(
      {},
      {
        blocks: getBlocksFromSections(selectedTemplate?.sections),
        cursor: {
          blockIndex: 1,
          letterIndex: 0,
        },
        sources,
        generation_config: {
          do_sample: false,
          max_new_tokens: 100,
        },
      }
    );

    if (data?.wordDocId) {
      onDocCreated(data);
      window.open(
        `/word-docs/${data?.wordDocId}?jobId=${data?.jobId}`,
        "_blank"
      );
    }
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>
        {trigger || <ButtonCreateNewAsset />}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <ModalTitle>Create a new Word document</ModalTitle>
          <Gap />
          <ModalSub>1. Select template</ModalSub>
          <Cards>
            {templates.map(template => (
              <Card
                isSelected={selectedTemplateId === template?.id}
                key={template?.title}
                onClick={() => setSelectedTemplateId(template?.id)}
              >
                <Title>{template?.title}</Title>
                <SubTitle>{template?.subtitle}</SubTitle>
                <Description>{template?.description}</Description>
                <TemplateEditorModal
                  template={template}
                  onChangeTemplate={newTemplate => {
                    setTemplates(
                      templates.map(t =>
                        t?.id === template?.id ? newTemplate : t
                      )
                    );
                  }}
                  trigger={
                    <StyledPencilIcon
                      height="14px"
                      style={{ position: "absolute", top: 14, right: 14 }}
                    />
                  }
                />
              </Card>
            ))}
          </Cards>
          <Gap height="4px" />
          <ModalSub>2. Select sources</ModalSub>

          <DocSourcesModalTriggerNew
            doneBtnText="Add to doc"
            alreadyAddedSources={sources}
            onAddSources={newSources => {
              setSources([...sources, ...newSources]);
            }}
            trigger={
              <ButtonWord style={{ marginLeft: "8px" }}>Sources</ButtonWord>
            }
          />
          <div style={{ width: 300, height: 150, overflow: "auto" }}>
            {sources?.map(source => (
              <DirItem
                key={source.id}
                style={{ gridTemplateColumns: "16px 1fr auto" }}
              >
                {getIconFromItem(source)}
                <ShortDiv>{source?.fileName}</ShortDiv>
                <CrossIcon
                  onClick={() =>
                    setSources(
                      sources.filter(s => s?.fileId !== source?.fileId)
                    )
                  }
                />
              </DirItem>
            ))}
          </div>
          <Gap />
          <StyledButton
            variant="highlighted"
            value="Create"
            onClick={doCreateDoc}
          />
          {error && <div>{error?.message}</div>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateWordModalTrigger;
