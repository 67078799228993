export const PRIVACY_HTML_STR = `

    <b id="docs-internal-guid-4d6ac9aa-7fff-90c8-3336-9dd400d03bff"
      ><br /><span><br /></span>
    </b>
    <p dir="ltr">
      <span
        >We are Boltzbit Limited (CRN: 12398587). Our registered office is at:
        Office 202 221 Pentonville Road, London, England, N1 9UZ. If you have any
        questions about this privacy policy, including any requests to exercise
        your legal rights, please contact us at </span
      ><a href="mailto:privacy@boltzbit.com"
        ><span>privacy@boltzbit.com</span></a
      ><span>. </span>
    </p>
    <ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>ABOUT THIS POLICY</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >The corporate information, records and data of Boltzbit is
              important to how we conduct business and manage employees.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >There are legal and regulatory requirements for us to retain
              certain data, usually for a specified amount of time. We also
              retain data to help our business operate and to have information
              available when we need it. However, we do not need to retain all
              data indefinitely, and retaining data can expose us to risk as
              well as be a cost to our business.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >This Data Retention Policy explains our requirements to retain
              data and to dispose of data and provides guidance on appropriate
              data handling and disposal.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >Failure to comply with this policy can expose us to fines and
              penalties, adverse publicity, difficulties in providing evidence
              when we need it and in running our business.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >This policy does not form part of any employee's contract of
              employment and we may amend it at any time.</span
            >
          </p>
        </li>
      </ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>SCOPE OF POLICY</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >This policy covers all data that we hold or have control over.
              This includes physical data such as hard copy documents,
              contracts, notebooks, letters and invoices. It also includes
              electronic data such as emails, electronic documents, audio and
              video recordings and CCTV recordings. It applies to both personal
              data and non-personal data. In this policy we refer to this
              information and these records collectively as "data".</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >This policy covers data that is held by third parties on our
              behalf, for example cloud storage providers or offsite records
              storage. It also covers data that belongs to us but is held by
              employees on personal devices.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >This policy explains the differences between our formal or
              official records, disposable information, confidential information
              belonging to others, personal data and non-personal data. It also
              gives guidance on how we classify our data.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >This policy applies to all business units and functions of
              Boltzbit in the UK.</span
            >
          </p>
        </li>
      </ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>GUIDING PRINCIPLES</span>
        </p>
      </li>
    </ol>
    <p dir="ltr">
      <span
        >Through this policy, and our data retention practices, we aim to meet
        the following commitments:</span
      >
    </p>
    <ul>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We comply with legal and regulatory requirements to retain
            data.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We comply with our data protection obligations, in particular to
            keep personal data no longer than is necessary for the purposes for
            which it is processed (storage limitation principle).</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We handle, store and dispose of data responsibly and
            securely.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We create and retain data where we need this to operate our
            business effectively, but we do not create or retain data without
            good business reason.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We allocate appropriate resources, roles and responsibilities to
            data retention.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We regularly remind employees of their data retention
            responsibilities.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >We regularly monitor and audit compliance with this policy and
            update this policy when required.</span
          >
        </p>
      </li>
    </ul>
    <ol start="4">
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>ROLES AND RESPONSIBILITIES</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Responsibility of all employees</span
            ><span
              >. We aim to comply with the laws, rules, and regulations that
              govern our organisation and with recognised compliance good
              practices. All employees must comply with this policy, the Record
              Retention Schedule, any communications suspending data disposal
              and any specific instructions from the compliance manager. Failure
              to do so may subject us, our employees, and contractors to serious
              civil and/or criminal liability. An employee's failure to comply
              with this policy may result in disciplinary sanctions, including
              suspension or termination. It is therefore the responsibility of
              everyone to understand and comply with this policy.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >We have designated Data Compliance Lead the compliance manager,
              who is responsible for:</span
            >
          </p>
        </li>
      </ol>
    </ol>
    <ul>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>Administering the data management programme;</span>
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >Helping department heads implement the data management programme
            and related best practices;</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >Planning, developing, and prescribing data disposal policies,
            systems, standards, and procedures; and</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >Providing guidance, training, monitoring and updating in relation
            to this policy.</span
          >
        </p>
      </li>
    </ul>
    <ol start="5">
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>TYPES OF DATA AND DATA CLASSIFICATIONS</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Formal or official records.</span
            ><span>
              Certain data is more important to us and is therefore listed in
              the Record Retention Schedule. This may be because we have a legal
              requirement to retain it, or because we may need it as evidence of
              our transactions, or because it is important to the running of our
              business. Please see paragraph 6.1 below for more information on
              retention periods for this type of data.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Disposable information</span
            ><span
              >. Disposable information consists of data that may be discarded
              or deleted at the discretion of the user once it has served its
              temporary useful purpose and/or data that may be safely destroyed
              because it is not a formal or official record as defined by this
              policy and the Record Retention Schedule. Examples may
              include:</span
            >
          </p>
        </li>
      </ol>
    </ol>
    <ul>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>Duplicates of originals that have not been annotated.</span>
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >Preliminary drafts of letters, memoranda, reports, worksheets, and
            informal notes that do not represent significant steps or decisions
            in the preparation of an official record.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span
            >Books, periodicals, manuals, training binders, and other printed
            materials obtained from sources outside of Boltzbit and retained
            primarily for reference purposes.</span
          >
        </p>
      </li>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>Spam and junk mail.</span>
        </p>
      </li>
    </ul>
    <p dir="ltr">
      <span
        >Please see paragraph 6.2 below for more information on how to determine
        retention periods for this type of data.</span
      >
    </p>
    <ol start="3">
      <li aria-level="2" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>Personal data. </span
          ><span
            >Both formal or official records and disposable information may
            contain personal data; that is, data that identifies living
            individuals. Data protection laws require us to retain personal data
            for no longer than is necessary for the purposes for which it is
            processed (principle of storage limitation). See paragraph 6.3 below
            for more information on this.</span
          >
        </p>
      </li>
      <li aria-level="2" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>Confidential information belonging to others</span
          ><span
            >. Any confidential information that an employee may have obtained
            from a source outside of Boltzbit, such as a previous employer, must
            not, so long as such information remains confidential, be disclosed
            to or used by us. Unsolicited confidential information submitted to
            us should be refused, returned to the sender where possible, and
            deleted, if received via the internet.
          </span>
        </p>
      </li>
    </ol>
    <ol start="6">
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>RETENTION PERIODS</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Formal or official records. </span
            ><span
              >Any data that is part of any of the categories listed in the
              Record Retention Schedule contained in the Annex to this policy,
              must be retained for the amount of time indicated in the Record
              Retention Schedule. A record must not be retained beyond the
              period indicated in the Record Retention Schedule, unless a valid
              business reason (or notice to preserve documents for contemplated
              litigation or other special situation) calls for its continued
              retention. If you are unsure whether to retain a certain record,
              contact the Data Compliance Lead or the Data Compliance
              Team.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Disposable information.</span
            ><span>
              The Record Retention Schedule will not set out retention periods
              for disposable information. This type of data should only be
              retained as long as it is needed for business purposes. Once it no
              longer has any business purpose or value it should be securely
              disposed of.
            </span>
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Personal data.</span
            ><span>
              As explained above, data protection laws require us to retain
              personal data for no longer than is necessary for the purposes for
              which it is processed (principle of storage limitation). Where
              data is listed in the Record Retention Schedule, we have taken
              into account the principle of storage limitation and balanced this
              against our requirements to retain the data. Where data is
              disposable information, you must take into account the principle
              of storage limitation when deciding whether to retain this data. </span
            ><span>More information can be found in our Privacy Policy. </span>
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >What to do if data is not listed in the Record Retention
              Schedule. </span
            ><span
              >If data is not listed in the Record Retention Schedule, it is
              likely that it should be classed as disposable information.
              However, if you consider that there is an omission in the Record
              Retention Schedule, or if you are unsure, please contact the Data
              Monitoring Officer.
            </span>
          </p>
        </li>
      </ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>STORAGE, BACK-UP AND DISPOSAL OF DATA</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Storage. </span
            ><span
              >Our data must be stored in a safe, secure, and accessible manner.
              Any documents and financial files that are essential to our
              business operations during an emergency must be duplicated and/or
              backed up at least once per week and maintained off site.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Destruction</span
            ><span
              >. Our Data Monitoring Officer is responsible for the continuing
              process of identifying the data that has met its required
              retention period and supervising its destruction. The destruction
              of confidential, financial, and employee-related hard copy data
              must be conducted by shredding if possible. Non-confidential data
              may be destroyed by recycling. The destruction of electronic data
              must be co-ordinated with </span
            ><span>the IT Department</span><span>.</span>
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >The destruction of data must stop immediately upon notification
              from the Commercial team that preservation of documents for
              contemplated litigation is required (sometimes referred to as a
              litigation hold). This is because we may be involved in a legal
              claim or an official investigation (see next paragraph).
              Destruction may begin again once the Commercial team lifts the
              requirement for preservation.</span
            >
          </p>
        </li>
      </ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>SPECIAL CIRCUMSTANCES</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >Preservation of documents for contemplated litigation and other
              special situations.</span
            ><span>
              We require all employees to comply fully with our Record Retention
              Schedule and procedures as provided in this policy. All employees
              should note the following general exception to any stated
              destruction schedule: If you believe, or the Commercial team
              informs you, that certain records are relevant to current
              litigation or contemplated litigation (that is, a dispute that
              could result in litigation), government investigation, audit, or
              other event, you must preserve and not delete, dispose, destroy,
              or change those records, including emails and other electronic
              documents, until the Commercial team determines those records are
              no longer needed. Preserving documents includes suspending any
              requirements in the Record Retention Schedule and preserving the
              integrity of the electronic files or other format in which the
              records are kept.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >If you believe this exception may apply, or have any questions
              regarding whether it may apply, please contact the Commercial
              team.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >In addition, you may be asked to suspend any routine data
              disposal procedures in connection with certain other types of
              events, such as our merger with another organisation or the
              replacement of our information technology systems.</span
            >
          </p>
        </li>
      </ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>WHERE TO GO FOR ADVICE AND QUESTIONS</span>
        </p>
      </li>
    </ol>
    <p dir="ltr">
      <span>Questions about the policy.</span
      ><span>
        Any questions about retention periods relevant to your Data should be
        raised with the Data Officer or the Commercial team. Any questions about
        this policy should be referred to info@boltzbit.com, who is in charge of
        administering, enforcing, and updating this policy.</span
      >
    </p>
    <ol start="10">
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>BREACH REPORTING AND AUDIT</span>
        </p>
      </li>
      <ol>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Reporting policy breaches.</span
            ><span>
              We are committed to enforcing this policy as it applies to all
              forms of data. The effectiveness of our efforts, however, depend
              largely on employees. If you feel that you or someone else may
              have breached this policy, you should report the incident
              immediately to your supervisor. If you are not comfortable
              bringing the matter up with your immediate supervisor, or do not
              believe the supervisor has dealt with the matter properly, you
              should raise the matter with the manager at the next level above
              your direct supervisor. If employees do not report inappropriate
              conduct, we may not become aware of a possible breach of this
              policy and may not be able to take appropriate corrective
              action.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span
              >No one will be subject to and we do not allow, any form of
              discipline, reprisal, intimidation, or retaliation for reporting
              incidents of inappropriate conduct of any kind, pursuing any
              record destruction claim, or co-operating in related
              investigations.</span
            >
          </p>
        </li>
        <li aria-level="2" dir="ltr">
          <p dir="ltr" role="presentation">
            <span>Audits</span
            ><span
              >. Our Data Officer will periodically review this policy and its
              procedures (including where appropriate by taking outside legal or
              auditor advice] to ensure we are in compliance with relevant new
              or amended laws, regulations or guidance. Additionally, we will
              regularly monitor compliance with this policy, including by
              carrying out audits.</span
            >
          </p>
        </li>
      </ol>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <span>OTHER RELEVANT POLICIES</span>
        </p>
      </li>
    </ol>
    <p dir="ltr">
      <span
        >This policy supplements and should be read in conjunction with our
        other policies and procedures in force from time to time, including
        without limitation our:</span
      >
    </p>
    <ul>
      <li aria-level="1" dir="ltr">
        <p dir="ltr" role="presentation">
          <a href="https://boltzbit.com/flow/terms">Terms and Conditions</a>
        </p>
      </li>
    </ul>

    <p dir="ltr" role="presentation">
      <span><br /></span><span><br /></span><span>Appendix A</span>
      <span><br /></span><span><br /></span><span>DEFINITIONS</span>
    </p>
    <p dir="ltr">
      <span>"</span><span>Data:</span
      ><span
        >" all data that we hold or have control over and therefore to which
        this policy applies. This includes physical data such as hard copy
        documents, contracts, notebooks, letters and invoices. It also includes
        electronic data such as emails, electronic documents, audio and video
        recordings and CCTV recordings. It applies to both personal data and
        non-personal data. In this policy we refer to this information and these
        records collectively as "data".</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>Data Retention Policy:</span
      ><span
        >" this policy, which explains our requirements to retain data and to
        dispose of data and provides guidance on appropriate data handling and
        disposal.</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>Disposable information</span
      ><span
        >": disposable information consists of data that may be discarded or
        deleted at the discretion of the user once it has served its temporary
        useful purpose and/or data that may be safely destroyed because it is
        not a formal or official record as defined by this policy and the Record
        Retention Schedule.</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>Formal or official record:</span
      ><span
        >" certain data is more important to us and is therefore listed in the
        Record Retention Schedule. This may be because we have a legal
        requirement to retain it, or because we may need it as evidence of our
        transactions, or because it is important to the running of our business.
        We refer to this as formal or official records or data.</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>Non-personal data:</span
      ><span
        >" data which does not identify living individuals, either because it is
        not about living individuals (for example financial records) or because
        it has been fully anonymised.</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>Personal data:</span
      ><span
        >" any information identifying a living individual or information
        relating to a living individual that we can identify (directly or
        indirectly) from that data alone or in combination with other
        identifiers we possess or can reasonably access. This includes special
        categories of personal data such as health data and pseudonymised
        personal data but excludes anonymous data or data that has had the
        identity of an individual permanently removed. Personal data can be
        factual (for example, a name, email address, location or date of birth)
        or an opinion about that person's actions or behaviour.</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>[Records Management Officer:</span
      ><span
        >" the Records Management Officer is head of the Records Management
        Department and is responsible for administering the data management
        programme, helping department heads implement it and related best
        practices, planning, developing, and prescribing data disposal policies,
        systems, standards, and procedures and providing guidance, training,
        monitoring and updating in relation to this policy.]</span
      >
    </p>
    <p dir="ltr">
      <span>"</span><span>Record Retention Schedule:</span
      ><span
        >" the schedule attached to this policy which sets out retention periods
        for our formal or official records.</span
      >
    </p>
    <span>"</span><span>Storage limitation principle:</span
    ><span
      >" data protection laws require us to retain personal data for no longer
      than is necessary for the purposes for which it is processed. This is
      referred to in the UK GDPR as the principle of storage limitation.</span
    >

`;
