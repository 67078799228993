import {
  deleteSigmasV2,
  getPipelineConfig,
  postListSigmas,
  postSigmasV2,
  putPipelineConfig,
} from "api/backend/fileSystemEndpoints";
import { postDownloadExcelFileOfTable } from "api/backend/projectServiceEndpoints";
import { getWordDoc, postWordDoc } from "api/backend/wordDocsEndpoints";
import AddSigmaRecordModalNew from "components/AddSigmaRecordModalNew";
import ColumnsListModal from "components/ColumnsListModal";
import { DocSearchIcon, ListIcon, TableGridIcon } from "components/IconsNew";
import JobsTooltipTrigger from "components/JobsTooltipTrigger";
import KanbanConfigModal from "components/KanbanConfigModal";
import { Gap } from "components/Layout";
import LayoutApp from "components/LayoutApp";
import PipelineRecordsTable from "components/PipelineRecordsTable";
import SortAndFilterConfigModal from "components/SortAndFilterConfigModal";
import RecordCard from "components/shared/apps/RecordCard";
import ButtonWord from "components/ui/ButtonWord";
import { CrossIcon, DownloadIcon, PlusIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import NavWithTabsNew from "components/ui/NavWithTabsNew";
import { cloneDeep, isEmpty, isNil } from "lodash";
import { COLOR1, COLOR2 } from "pages/login-v2";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getColorFromIndex, parseJson } from "utils/common";

const doDownloadTable = async (columns, records) => {
  const tableColumns = columns?.map(column => column?.name);
  const tablePreview = records?.map(record => {
    const newRow = {};
    tableColumns?.forEach(columnName => {
      let val = record?.fields?.[columnName]?.value || "";
      if (typeof val === "object") {
        val = JSON.stringify(val);
      }
      newRow[columnName] = {
        Value: val,
        RawValue: val,
      };
    });

    return newRow;
  });

  const body = {
    tableColumns,
    tablePreview,
  };

  await postDownloadExcelFileOfTable(body);
};

const GradientSpan = styled.span`
  background: linear-gradient(88.57deg, ${COLOR1} 0%, ${COLOR2} 10%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Container = styled.div`
  white-space: pre-wrap;
  display: grid;
  height: 100%;
  grid-template-rows: repeat(6, auto) 1fr;
  overflow: hidden;
  align-content: start;
  padding: 120px;
  padding-top: 80px;
  padding-bottom: 40px;
`;

const Cards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
  align-content: start;
`;

const TopBar = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto auto;
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  width: 800px;
  font-weight: 400;
  border: 2px solid #e8ecef;
  border-radius: 12px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
`;

const StyledSelect = styled.select`
  width: 100%;
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-right: 16px solid transparent;
`;

const TitleBar = styled.div``;

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ButtonWordIcon = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: max-content;
  svg {
    fill: white;
  }
`;

const SwitchLinks = styled.div`
  background-color: #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4px;
  border-radius: 14px;
`;

const SwitchLink = styled.div`
  color: white;
  background-color: ${props => (props.isActive ? "#d4d4d4" : "#eaeaea")};
  color: #434343;
  text-decoration: none;
  padding: 8px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: #000000;
  }
  svg {
    fill: #000000;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-right: 10px;
  :hover {
    opacity: 1;
  }
`;

const KanbanColumns = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 2px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a8a8a8;
  }
`;

const KanbanColumnTitle = styled.div`
  font-weight: 600;
  justify-self: start;
  padding: 4px 8px;
  height: 24px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${props => props.bgColor};
  color: white;
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const KanbanColumn = styled.div`
  background-color: ${props => props.bgColor}33;
  width: 216px;
  display: grid;
  gap: 8px;
  justify-items: center;
  align-content: start;
  padding: 8px 0;
  padding-top: 0;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
`;
const GreyHeader = styled.div`
  color: black;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 8px 14px;
`;

const ModelOptionImg = styled.img`
  height: 22px;
`;

export const SwitchLinksBar = ({ viewType = "", setViewType = () => {} }) => (
  <SwitchLinks>
    <SwitchLink
      onClick={() => {
        setViewType("cards");
        localStorage.setItem("viewType", "cards");
      }}
      isActive={viewType === "cards"}
    >
      <TableGridIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => {
        setViewType("table");
        localStorage.setItem("viewType", "table");
      }}
      isActive={viewType === "table"}
    >
      <ListIcon />
    </SwitchLink>
    <SwitchLink
      onClick={() => {
        setViewType("kanban");
        localStorage.setItem("viewType", "kanban");
      }}
      isActive={viewType === "kanban"}
    >
      <ListIcon style={{ transform: "rotate(90deg)" }} />
    </SwitchLink>
  </SwitchLinks>
);

export const doPostEmptyRecord = async (
  pipelineConfig,
  tableId = "source0"
) => {
  const selectedTable = pipelineConfig?.sourceTables?.find(
    table => table?.id === tableId
  );

  const columns = selectedTable?.columns || [];
  const recordNameColumn = columns?.find(
    column => !column?.groupName || column?.isNameField
  );

  // populate word doc fields
  const wordDocColumns = selectedTable?.columns?.filter(
    column => column?.displayGroup === "File Templates"
  );
  let wordDocFields = {};
  const wordDocPromises = wordDocColumns?.map(async column => {
    const { data: templateWordDoc } = await getWordDoc(column?.description);
    const { data: wordDoc } = await postWordDoc(
      {},
      {
        fileName: column?.name,
        content: templateWordDoc?.content,
      }
    );
    wordDocFields[column?.dbName] = {
      value: wordDoc.id,
    };
  });
  await Promise.all(wordDocPromises);

  let fields = {
    [recordNameColumn?.dbName]: {
      value: "Blank Record",
    },
    ...wordDocFields,
  };

  // populate default values for categorical columns
  columns?.forEach(column => {
    if (column?.formatType === "Categorical" || column?.formatType === "Tags") {
      const defaultCatStr =
        column?.categories?.find(
          catStr => catStr?.split(",")?.[2] === "true"
        ) || column?.categories?.[0];
      const defaultValue = defaultCatStr?.split(",")?.[0];

      fields[column?.dbName] = {
        value: defaultValue,
      };
    }
  });

  const { data } = await postSigmasV2(
    {},
    {
      pipelineId: pipelineConfig?.id,
      tableName: selectedTable?.id,
      fields,
    }
  );

  return { data };
};

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const ModalContent = styled.div`
  padding: 20px;
  height: 200px;
  width: 600px;
  display: grid;
  gap: 10px;
  align-content: start;
  grid-template-rows: repeat(3, auto) 1fr;
`;

const BoldDiv = styled.div`
  font-weight: 500;
`;

const DateInput = styled.input`
  font-family: "Montserrat";
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  column-gap: 40px;
`;

const StyledNavWithTabsNew = styled(NavWithTabsNew)`
  justify-self: start;
`;

const DownloadRecordsModal = ({ onClickDownload = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DownloadIcon
        style={{ cursor: "pointer", fill: "black" }}
        onClick={() => setIsOpen(true)}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>Download records</ModalTitle>
          <Gap height="0px" />
          <Row>
            <BoldDiv>Period Start</BoldDiv>
            <BoldDiv>Period End</BoldDiv>
            <DateInput type="date" />
            <DateInput type="date" />
          </Row>

          <ButtonWord
            onClick={onClickDownload}
            style={{ justifySelf: "end", alignSelf: "end" }}
            isPrimary
          >
            Download
          </ButtonWord>
        </ModalContent>
      </Modal>
    </>
  );
};

export const INITIAL_LIMIT = 15;
export const INITIAL_SORT_FILTER = {
  sort: {
    limit: INITIAL_LIMIT,
    sortColumn: "",
    direction: "DESC",
  },
  filters: [],
};

const cleanSortFilter = config => {
  const newConfig = cloneDeep(config);

  newConfig.filters = newConfig.filters
    .map(filter => {
      const newLikeValues = filter.likeValues.filter(val => val !== "");
      if (!newLikeValues?.length) {
        // TODO: BE returns error if likeValues=[]
        return null;
      }

      return {
        ...filter,
        likeValues: filter.likeValues.filter(val => val !== ""),
      };
    })
    ?.filter(filter => !!filter);

  return newConfig;
};

const AppPageRecords = () => {
  const navigate = useNavigate();

  const { pipelineConfigId } = useParams();

  const localConfigStr = localStorage.getItem(pipelineConfigId);
  const localConfig = parseJson(localConfigStr);
  const initialSortFilter =
    localConfig?.sortAndFilterConfig || INITIAL_SORT_FILTER;

  const [pipelineConfig, setPipelineConfig] = useState(null);
  const [sigmasRes, setSigmasRes] = useState(null);
  const [sigmaRecords, setSigmaRecords] = useState([]);
  const [isLoadingNewPage, setIsLoadingNewPage] = useState(false);
  const [sortFilterConfig, setSortFilterConfig] = useState(initialSortFilter);

  const [initialSigmaIds, setInitialSigmaIds] = useState(null);

  const [viewType, setViewType] = useState(
    localStorage?.getItem("viewType") || "table"
  );

  const [tableId, setTableId] = useState("");
  const [query, setQuery] = useState("");

  const selectedTable = pipelineConfig?.sourceTables?.find(
    table => table?.id === tableId
  );

  const limit = sortFilterConfig?.sort?.limit || INITIAL_LIMIT;
  const updateLimit = newLimit => {
    const newSortFilterConfig = cloneDeep(sortFilterConfig);
    newSortFilterConfig.sort.limit = newLimit;
    setSortFilterConfig(newSortFilterConfig);
  };

  // FETCHING
  useEffect(() => {
    doPopulatePipelineConfig();
  }, [pipelineConfigId]);

  useEffect(() => {
    if (!pipelineConfigId || !tableId) {
      return;
    }
    doPopulateSigmaRecords(pipelineConfigId, tableId, limit);
    const intervalId = setInterval(() => {
      doPopulateSigmaRecords(pipelineConfigId, tableId, limit);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [
    pipelineConfigId,
    tableId,
    // JSON.stringify(initialSigmaIds),
    JSON.stringify(sortFilterConfig),
  ]);

  const doPopulatePipelineConfig = async () => {
    const { data } = await getPipelineConfig(pipelineConfigId);
    setPipelineConfig(data);
    setTableId(data?.sourceTables?.[0]?.id);
  };

  const doPopulateSigmaRecords = async (
    pipelineId,
    tableName,
    limit = 10,
    resetHighlights = false
  ) => {
    // removes "" from filters
    const cleanedSortFillterConfig = cleanSortFilter(sortFilterConfig);
    const { data, error } = await postListSigmas(
      {
        pipelineId,
        tableName,
      },
      cleanedSortFillterConfig
    );
    setSigmasRes(data);
    setSigmaRecords(data?.items || []);
    if ((initialSigmaIds === null || resetHighlights) && !error) {
      setInitialSigmaIds(data?.items?.map(record => record?.id) || []);
    }
  };

  const doDeleteRecord = async recordId => {
    await deleteSigmasV2(recordId, {
      tableName: tableId,
      pipelineID: pipelineConfigId,
    });
    doPopulateSigmaRecords(pipelineConfigId, tableId, limit);
  };

  const doSaveMetaFields = async (metaFields = {}) => {
    const { data } = await putPipelineConfig(
      pipelineConfigId,
      {},
      {
        ...pipelineConfig,
        meta: {
          ...pipelineConfig.meta,
          ...metaFields,
        },
      }
    );
    setPipelineConfig(data);
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      navigate(`/apps/${pipelineConfigId}/search?query=${query}&type=RECORD`);
    }
  };

  const columns =
    selectedTable?.columns?.filter(c => c?.actionType !== "analytics") || [];

  let dbTableColumns = columns;
  const headerColumns = pipelineConfig?.meta?.headerColumns;
  if (headerColumns && !headerColumns?.some(el => typeof el === "string")) {
    dbTableColumns = headerColumns
      .filter(headerCol => !headerCol?.isHidden)
      .map(headerCol =>
        columns?.find(col => col?.dbName === headerCol?.dbName)
      );
  }

  const cardsView = (
    <Cards>
      {sigmaRecords?.map(record => {
        return (
          <RecordCard
            pipelineConfig={pipelineConfig}
            record={record}
            tableId={tableId}
          />
        );
      })}
    </Cards>
  );

  let tableView = (
    <PipelineRecordsTable
      sortAndFilterConfig={sortFilterConfig}
      onChangeConfig={newSortFilterConfig =>
        setSortFilterConfig(newSortFilterConfig)
      }
      onClickDeleteRecord={id => doDeleteRecord(id)}
      initialRecordIds={initialSigmaIds}
      columns={dbTableColumns}
      pipelineConfig={pipelineConfig}
      sigmasRes={sigmasRes}
      sigmaRecords={sigmaRecords}
      isLoading={isNil(initialSigmaIds) && !sigmaRecords?.length}
      isShowMoreHidden={limit > sigmaRecords?.length}
      isLoadingNewPage={isLoadingNewPage}
      onClickLoadMore={async () => {
        const newLimit = limit + 10;
        updateLimit(newLimit);
      }}
      onRecordPatchComplete={(recordId, fields) => {
        doPopulateSigmaRecords(pipelineConfigId, tableId, limit);
      }}
      tableId={tableId}
    />
  );

  let kanbanView = null;
  let kanbanColumnCategories = columns?.find(
    col => col?.dbName === pipelineConfig?.meta?.kanbanColumnName
  )?.categories;
  if (!isEmpty(pipelineConfig?.meta?.kanbanColumnValues)) {
    kanbanColumnCategories = pipelineConfig?.meta?.kanbanColumnValues;
  }

  if (kanbanColumnCategories?.length) {
    kanbanView = (
      <KanbanColumns>
        {kanbanColumnCategories.map((category, catIndex) => {
          const categoryValue = category?.split(",")?.[0];
          let categoryColor = category?.split(",")?.[1];
          if (!categoryColor?.startsWith("#") && categoryColor?.length !== 7) {
            categoryColor = getColorFromIndex(catIndex);
          }

          return (
            <KanbanColumn bgColor={categoryColor}>
              <KanbanColumnTitle bgColor={categoryColor}>
                {categoryValue}
              </KanbanColumnTitle>
              {[...sigmaRecords]
                .filter(
                  record =>
                    record?.fields?.[pipelineConfig?.meta?.kanbanColumnName]
                      ?.value === categoryValue
                )
                .map(record => (
                  <div style={{ width: 200 }}>
                    <RecordCard
                      pipelineConfig={pipelineConfig}
                      record={record}
                      tableId={tableId}
                    />
                  </div>
                ))}
            </KanbanColumn>
          );
        })}
      </KanbanColumns>
    );
  }

  let modelName = "Boltzbit LLM";
  let modelImg = <ModelOptionImg src="/images/logo-coloured.png" />;
  if (pipelineConfig?.name === "Syndicates") {
    modelName = "Gemini";
    modelImg = <ModelOptionImg src="/images/logo-gemini.png" />;
  }

  return (
    <LayoutApp>
      <Container>
        <TitleBar
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <TitleText>
            <GradientSpan>{pipelineConfig?.name}</GradientSpan> records
          </TitleText>
          <AddSigmaRecordModalNew
            selectedTableId={selectedTable?.id}
            trigger={
              <ButtonWordIcon isPrimary>
                <PlusIcon /> New
              </ButtonWordIcon>
            }
            pipelineConfig={pipelineConfig}
            doPostEmptyRecord={async () => {
              await doPostEmptyRecord(pipelineConfig, tableId);
              doPopulateSigmaRecords(pipelineConfigId, tableId, limit);
            }}
          />
          {(modelName || modelImg) && (
            <GreyHeader>
              {modelImg}
              {modelName}
            </GreyHeader>
          )}
          <JobsTooltipTrigger />
        </TitleBar>
        <Gap />
        <StyledInput
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Search records..."
        />
        <Gap height="20px" />
        <TopBar>
          {pipelineConfig?.sourceTables?.length > 1 ? (
            <StyledNavWithTabsNew
              tabs={pipelineConfig?.sourceTables?.filter(t => !t?.isArchived)}
              selectedId={tableId}
              onSelectTab={newTableId => setTableId(newTableId)}
            />
          ) : (
            <div />
          )}
          <div />
          <SortAndFilterConfigModal
            pipelineConfigId={pipelineConfigId}
            columns={dbTableColumns}
            sortAndFilterConfig={sortFilterConfig}
            onChangeConfig={newSortFilterConfig =>
              setSortFilterConfig(newSortFilterConfig)
            }
          />

          {/* <DownloadRecordsModal
            onClickDownload={() =>
              doDownloadTable(dbTableColumns, sigmaRecords)
            }
          /> */}
          {viewType === "cards" && <div />}
          {viewType === "table" && (
            <ColumnsListModal
              value={pipelineConfig?.meta?.headerColumns}
              columns={columns}
              onPressSave={newHeaderColumns =>
                doSaveMetaFields({ headerColumns: newHeaderColumns })
              }
            />
          )}
          {viewType === "kanban" && (
            <KanbanConfigModal
              value={pipelineConfig?.meta?.kanbanColumnName}
              columnValues={pipelineConfig?.meta?.kanbanColumnValues}
              columns={columns}
              onPressSave={(newName, kanbanColumnValues) => {
                doSaveMetaFields({
                  kanbanColumnName: newName,
                  kanbanColumnValues,
                });
              }}
            />
          )}
          <SwitchLinksBar setViewType={setViewType} viewType={viewType} />
        </TopBar>
        <Gap height="10px" />
        {viewType === "cards" && cardsView}
        {viewType === "table" && tableView}
        {viewType === "kanban" && kanbanView}
      </Container>
    </LayoutApp>
  );
};

export default AppPageRecords;
