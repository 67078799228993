import { Gap } from "components/Layout";
import ButtonWord from "components/ui/ButtonWord";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { cloneDeep, set } from "lodash";
import { INITIAL_LIMIT, INITIAL_SORT_FILTER } from "pages/apps/records";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { parseJson } from "utils/common";

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  width: 600px;
  height: 400px;
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
`;

const Filters = styled.div`
  overflow: auto;
  height: 200px;
  display: grid;
  gap: 4px;
`;

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  justify-items: start;
  align-items: start;
  gap: 10px;
  padding: 4px;
  border-bottom: 1px solid #d8d8d8;
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const TopRightBtn = styled(ButtonWord)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const BottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  padding-top: 10px;
  width: 560px;
  background-color: white;
  border-top: 1px solid #d8d8d8;
  display: grid;
  justify-content: end;
`;

const Trigger = styled.div``;

const getColorFromColumn = (categoryName, column) => {
  const matchedCategory = column?.categories?.find(
    category => category?.split(",")?.[0] === categoryName
  );
  const color = matchedCategory?.split(",")?.[1] || "transparent";

  if (color === "transparent") {
    return "transparent";
  }

  return `${color}44`;
};

const onUpdateConfig = (pipelineConfigId, newSortConfig) => {
  const localConfigStr = localStorage.getItem(pipelineConfigId);
  const localConfig = parseJson(localConfigStr);
  const newConfig = { ...localConfig };

  set(newConfig, "sortAndFilterConfig", newSortConfig);

  const newConfigStr = JSON.stringify(newConfig);
  localStorage.setItem(pipelineConfigId, newConfigStr);
};

const SortAndFilterConfigModal = ({
  columns = [],
  sortAndFilterConfig = {},
  onChangeConfig = () => {},
  pipelineConfigId = "",
  trigger = <ButtonWord>Sort & Filter</ButtonWord>,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(sortAndFilterConfig);

  const { sortColumn, direction } = config?.sort || {};

  useEffect(() => {
    setConfig(sortAndFilterConfig);
  }, [JSON.stringify(sortAndFilterConfig)]);

  const updateConfig = (key, value) => {
    const newConfig = cloneDeep(config);
    set(newConfig, key, value);
    setConfig(newConfig);
    onUpdateConfig(pipelineConfigId, newConfig);

    return newConfig;
  };

  const onChangeFilterValue = (dbName, newLikeValues) => {
    const newConfig = cloneDeep(config);
    const matchedFilterIndex = newConfig?.filters?.findIndex(
      filter => filter?.dbName === dbName
    );
    if (matchedFilterIndex === -1) {
      newConfig.filters.push({ dbName, likeValues: newLikeValues });
    } else {
      newConfig.filters[matchedFilterIndex].likeValues = newLikeValues;
      if (newLikeValues?.length === 0) {
        newConfig.filters.splice(matchedFilterIndex, 1);
      }
    }
    setConfig(newConfig);
  };

  const onClickApply = () => {
    const newConfig = updateConfig("sort.limit", INITIAL_LIMIT);
    onChangeConfig(newConfig);
    setIsOpen(false);
  };

  const onClickReset = () => {
    setConfig(INITIAL_SORT_FILTER);
  };

  return (
    <>
      <Trigger style={{ justifySelf: "end" }} onClick={() => setIsOpen(true)}>
        {trigger}
      </Trigger>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <TopRightBtn onClick={onClickReset}>Reset</TopRightBtn>
          <Title>Sort</Title>
          <Gap height="10px" />
          <div>
            Sort by:{" "}
            <select
              value={sortColumn}
              onChange={e => updateConfig("sort.sortColumn", e.target.value)}
            >
              <option value="">-- Choose column --</option>
              {columns.map(column => (
                <option key={column?.dbName} value={column?.dbName}>
                  {column?.displayName}
                </option>
              ))}
            </select>
          </div>
          <div>
            Direction:{" "}
            <select
              value={direction}
              onChange={e => updateConfig("sort.direction", e.target.value)}
            >
              <option value="ASC">Ascending</option>
              <option value="DESC">Descending</option>
            </select>
          </div>
          <Gap height="40px" />
          <Title>Filters</Title>
          <Gap height="10px" />
          <Filters>
            {columns?.map((column, index) => {
              const matchedFilter = config?.filters?.find(
                filter => filter?.dbName === column?.dbName
              );
              const value = matchedFilter?.likeValues?.[0] || "";

              if (column?.formatType === "Categorical") {
                const columnLikeValues = matchedFilter?.likeValues || [];

                const updateValueAtIndex = (value, index) => {
                  const newLikeValues = cloneDeep(columnLikeValues);
                  newLikeValues[index] = value;
                  onChangeFilterValue(column?.dbName, newLikeValues);
                };

                const deleteValueAtIndex = index => {
                  const newLikeValues = cloneDeep(columnLikeValues);
                  newLikeValues.splice(index, 1);
                  onChangeFilterValue(column?.dbName, newLikeValues);
                };

                return (
                  <FilterContainer key={index}>
                    <div>{column?.displayName}</div>
                    <div
                      style={{
                        display: "grid",
                        gap: 8,
                      }}
                    >
                      {columnLikeValues.map((likeValue, ind) => (
                        <div
                          style={{
                            display: "flex",
                            border: "1px solid lightgrey",
                            backgroundColor: getColorFromColumn(
                              likeValue,
                              column
                            ),
                          }}
                        >
                          <select
                            value={likeValue}
                            style={{ backgroundColor: "transparent" }}
                            onChange={e =>
                              updateValueAtIndex(e.target.value, ind)
                            }
                          >
                            <option value="">-- Choose value --</option>
                            {column?.categories?.map(category => {
                              const categoryName = category?.split(",")?.[0];
                              return (
                                <option key={categoryName} value={categoryName}>
                                  {categoryName}
                                </option>
                              );
                            })}
                          </select>
                          <StyledTrashIcon
                            onClick={() => deleteValueAtIndex(ind)}
                          />
                        </div>
                      ))}
                      <StyledPlusIcon
                        onClick={() =>
                          onChangeFilterValue(column?.dbName, [
                            ...columnLikeValues,
                            "",
                          ])
                        }
                      />
                    </div>
                  </FilterContainer>
                );
              }

              return (
                <FilterContainer key={index}>
                  {column?.displayName}
                  <input
                    value={value}
                    onChange={e =>
                      onChangeFilterValue(column?.dbName, [e.target.value])
                    }
                  />
                </FilterContainer>
              );
            })}
          </Filters>
          <BottomContainer>
            <ButtonWord isPrimary onClick={onClickApply}>
              Apply
            </ButtonWord>
          </BottomContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SortAndFilterConfigModal;
