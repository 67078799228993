import { useEffect, useState } from "react";
import styled from "styled-components";

import Modal from "components/ui/Modal";
import TooltipNew from "components/ui/TooltipNew";
import { getFile, getFilePageResults } from "api/services/filesService";
import ImgWithRectangleFlexible from "components/widgets/ImgWithRectangleFlexible";
import ButtonWord from "components/ui/ButtonWord";
import { getColorFromString, safeFormat, uuidv4 } from "utils/common";
import {
  getBlockTextSegments,
  getSegments,
  isListBlock,
} from "pages/chat-docs/:wordDocId";
import { Block } from "@material-ui/icons";
import {
  BoltzhubLogoInner,
  ChevronDownIcon,
  FilesIcon,
  LogoInner,
  PdfIcon,
  RoundTickIcon,
  WarningIcon,
} from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";
import { Gap } from "components/Layout";
import { DocSearchIcon, TableIcon } from "components/IconsNew";
import { Icon } from "@material-ui/core";

export const useIsDebug = (initialValue = false) => {
  const [isDebug, setIsDebug] = useState(initialValue);

  useEffect(() => {
    const toggleDebug = e => {
      if (e.key === "D" && e.shiftKey) {
        setIsDebug(prev => !prev);
      }
    };

    document.addEventListener("keydown", toggleDebug);
    return () => document.removeEventListener("keydown", toggleDebug);
  }, []);

  return isDebug;
};

export const getMetaId = (meta, value = "") => {
  const refCoords = meta?.references
    ?.map(ref => `${ref?.x}-${ref?.y}`)
    ?.join("-");
  return `${meta?.fileId}-${meta?.pageNumber}-${refCoords}-${value}`;
};

const Span = styled.span`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none; 
    opacity: 0.6;
    svg {
      fill: #9a9a9a;
    }  
  `}
`;

const ModalContent = styled.div`
  padding-left: 0px;
  padding-top: 0px;
  border-radius: 24px;
  background: linear-gradient(180deg, #f3f5f7 0%, #f3f5f7 100%);
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-content: start;
  white-space: pre-wrap;
  overflow: auto;

  @media (max-width: 1240px) {
    width: 90vw;
    height: auto;
    font-size: 14px;
    padding: 20px;
    line-height: 1.2;
  }
`;

const FileLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.primary};
  background-color: white;
  padding: 8px;
`;

const Tabs = styled.div`
  display: flex;
  overflow: auto;
`;

const Tab = styled.div`
  cursor: pointer;
  padding: 8px;
  border-bottom: 2px solid
    ${props =>
      props?.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer0};
  ${props => props.isSelected && `color: ${props.theme.color.primary};`}
`;

const ShortDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 200px;
`;

const TitleContainer = styled.div`
  background-color: white;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImgWithRectangleFlexibleStyled = styled(ImgWithRectangleFlexible)``;

const DocContainer = styled.div`
  padding: 40px;
  height: 500px;
  overflow: auto;
  background-color: #f3f5f7;
  border: 1px solid #f3f5f7;
  border-radius: 0;
  position: relative;
`;

const ZoomButtons = styled.div`
  position: fixed;
  z-index: 10000;
  transform: translate(-35px, -35px);
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  width: 485px;
  gap: 4px;
`;

const UploadedText = styled.div`
  font-size: 12px;
  background-color: #ebebeb;
  justify-self: end;
  margin-right: 8px;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid #bbbbbb;
`;

const ZoomButton = styled(ButtonWord)`
  padding: 0;
  width: 14px;
  height: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DocAnchor = styled.a`
  justify-self: end;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const LeftContent = styled.div`
  padding: 10px 20px;
  border-right: 1px solid #eaeaea;
  overflow: auto;
  height: calc(607px - 61px);
  padding-bottom: 50px;
`;

const BoldDiv = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AnswerDiv = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: auto;
  max-height: 200px;
  border: 1px solid #dedede;
  line-height: 1.2;
  ${props => props.isGrey && "color: #9a9a9a;"}
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid #dddddd;
  height: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const ReplyContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  ${BoldDiv} {
    color: ${props => props.bgColor};
  }
`;

const RepliesContainer = styled.div`
  display: grid;
  gap: 20px;
`;

const ReplyDiv = styled.div`
  max-height: 100px;
  overflow: auto;
  border: 1px solid #dedede;
  padding: 4px;
  border-radius: 4px;
  line-height: 1.4;
  white-space: pre-wrap;
`;

const ReplyHeader = styled.div`
  font-weight: 500;
  background-color: ${props => props.bgColor}DA;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: white;

  :hover {
    background-color: ${props => props.bgColor};
  }
`;

const ShortText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 450px;
`;

const IconWithLabel = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: max-content;
  padding: 2px 4px;
  background-color: ${props => props.bgColor};
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
  align-items: center;
  gap: 4px;
  svg {
    height: 10px;
    fill: white;
  }
`;

const NoFileText = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
  opacity: 0.6;
  justify-content: center;
  justify-items: center;
`;

const STEP_TYPES = [
  {
    label: "Search Document",
    value: "search_doc",
    icon: <DocSearchIcon />,
  },
  {
    label: "Validation",
    value: "error_checking",
    icon: <WarningIcon />,
  },
  {
    label: "Writing Table",
    value: "writing_table",
    icon: <TableIcon />,
  },
  {
    label: "Summarization",
    value: "summarization",
    icon: <DocSearchIcon />,
  },
];

const COLORS = ["#9095DB", "#DB9D90"];

export const Reply = ({ reply, index = 0 }) => {
  const [isExpanded, setExpanded] = useState(false);

  const step = STEP_TYPES?.[index % STEP_TYPES.length];
  const bgColor = COLORS?.[index % COLORS.length];

  let formattedStepType =
    reply?.stepType?.[0]?.toUpperCase() + reply?.stepType?.slice(1);
  formattedStepType = formattedStepType || STEP_TYPES?.[index]?.label || "";

  let chevronStyle = { stroke: "white" };
  if (!isExpanded) {
    chevronStyle = {
      transformOrigin: "center",
      transform: "rotate(-90deg)",
      stroke: "white",
    };
  }
  const stepHeader = (
    <ReplyHeader bgColor={bgColor} onClick={() => setExpanded(prev => !prev)}>
      <ChevronDownIcon height="8px" style={chevronStyle} />
      Step {index + 1}
      <div />
      <IconWithLabel bgColor={bgColor}>
        {step?.icon}
        {formattedStepType}
      </IconWithLabel>
      <IconWithLabel bgColor={bgColor}>
        <BoltzhubLogoInner />
        Boltzbit LLM
      </IconWithLabel>
    </ReplyHeader>
  );

  if (!isExpanded) {
    return (
      <ReplyContainer>
        {stepHeader}
        <ShortText style={{ padding: 12 }}>
          <span style={{ fontWeight: 500, color: bgColor }}>Prompt:</span>&nbsp;
          <span>{reply?.llmPrompt}</span>
        </ShortText>
      </ReplyContainer>
    );
  }

  return (
    <ReplyContainer bgColor={bgColor}>
      {stepHeader}
      <div style={{ padding: 12 }}>
        <BoldDiv>
          Prompt
          <FilesIcon
            onClick={() => navigator.clipboard.writeText(reply?.llmPrompt)}
            style={{ fill: bgColor, cursor: "pointer" }}
          />
        </BoldDiv>
        <ReplyDiv>{reply?.llmPrompt}</ReplyDiv>
        <Gap />
        <BoldDiv>Reply</BoldDiv>
        <ReplyDiv>{reply?.llmReply}</ReplyDiv>
      </div>
    </ReplyContainer>
  );
};

const Td = styled.td`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  font-weight: 500;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"}
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    fill: black;
    height: 14px;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  text-align: left;
  padding: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;

  font-weight: 600;
  z-index: 1;

  svg {
    fill: black;
    height: 14px;
  }
`;

const Tr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

export const EmailContent = styled.div`
  position: relative;
  padding: 20px;
  white-space: pre-wrap;
  line-height: 1.4;
  background-color: white;
  border-top: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  max-height: 500px;
  overflow: auto;
`;

const EmailSubject = styled.div`
  padding: 8px 20px;
  white-space: pre-wrap;
  line-height: 1.2;
  background-color: white;
  border-top: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  max-height: 200px;
  overflow: auto;
  font-weight: 500;
`;

const FieldReferenceModal = ({
  className = "",
  children,
  column = {},
  field = {},
  reference = null,
  isDisabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [pageResults, setPageResults] = useState(null);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [file, setFile] = useState(null);

  const selectedMeta = reference;

  useEffect(() => {
    setZoomFactor(1);
    if (!isOpen) {
      return;
    }

    console.log({ field, column, reference });
    doPopulatePageResults();
  }, [isOpen, selectedMeta?.fileId, selectedMeta?.pageNumber]);

  const doPopulatePageResults = async () => {
    const { data } = await getFilePageResults(
      selectedMeta?.fileId,
      selectedMeta?.pageNumber || 0
    );
    setPageResults(data);

    const { data: fileData } = await getFile(selectedMeta?.fileId);
    setFile(fileData);
  };

  const isMobile = window?.screen?.width < 1240;

  let fieldValue = <AnswerDiv>{field?.value}</AnswerDiv>;
  if (column?.type === "TABLE") {
    fieldValue = (
      <div style={{ maxHeight: 200, overflow: "auto" }}>
        <table>
          <thead>
            <Tr>
              {column?.tableColumns?.map((col, i) => (
                <Th key={i}>{col?.name}</Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {field?.value?.map((row, i) => (
              <Tr key={i}>
                {column?.tableColumns?.map((col, j) => (
                  <Td key={j}>{row?.[col?.name]?.value}</Td>
                ))}
              </Tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  if (isMobile) {
    return (
      <>
        <Span
          className={className}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {children}
        </Span>
        <Modal open={isOpen} handleClose={() => setOpen(false)}>
          <ModalContent>
            Reference checking only available on desktop
          </ModalContent>
        </Modal>
      </>
    );
  }

  let isReferenceAvailable = reference?.fileId || reference?.rawText;
  let subText = `(page ${(reference?.pageNumber || 0) + 1})`;

  let htmlText = reference?.rawText;
  if (reference?.rawText && !reference?.inSubject) {
    subText = "(email)";
    let { startInd, endInd, rawText } = reference;
    startInd = startInd || 0;
    endInd = endInd || 0;

    const preRef = rawText?.slice(0, startInd);
    const ref = rawText?.slice(startInd, endInd + 1);
    const postRef = rawText?.slice(endInd + 1);

    // if (isOpen) {
    //   console.log({ preRef, ref, postRef });
    // }

    htmlText = preRef + "<mark>" + ref + "</mark>" + postRef;
  }

  let subjectText = reference?.subject;
  if (reference?.inSubject) {
    let { startInd, endInd, subject } = reference;
    startInd = startInd || 0;
    endInd = endInd || 0;

    const preRef = subject?.slice(0, startInd);
    const ref = subject?.slice(startInd, endInd + 1);
    const postRef = subject?.slice(endInd + 1);

    subjectText = preRef + "<mark>" + ref + "</mark>" + postRef;
  }

  return (
    <>
      <Span
        className={className}
        isDisabled={isDisabled}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        {children}
      </Span>
      <Modal open={isOpen} handleClose={() => setOpen(false)}>
        <ModalContent>
          <TitleContainer
            style={{ paddingLeft: 20, borderRight: "1px solid #eaeaea" }}
          >
            {column?.name?.replace("_", " / ")}
          </TitleContainer>
          <TitleContainer style={{ paddingLeft: 20 }}>Sources</TitleContainer>
          <LeftContent>
            <BoldDiv>User prompt</BoldDiv>
            <AnswerDiv isGrey={!column?.description}>
              {column?.description || "No prompt given"}
            </AnswerDiv>
            <BoldDiv>Final answer</BoldDiv>
            {fieldValue}
            <HorizontalLine />
            <BoldDiv>Chain of Thought</BoldDiv>
            <RepliesContainer>
              {reference?.llmPromptReplies?.map((reply, i) => (
                <Reply key={i} reply={reply} index={i} />
              ))}
            </RepliesContainer>
          </LeftContent>

          <NoFileText
            style={{ display: isReferenceAvailable ? "none" : "grid" }}
          >
            <WarningIcon />
            No file linked
          </NoFileText>
          <div style={{ display: isReferenceAvailable ? "block" : "none" }}>
            <div style={{ backgroundColor: "white" }}>
              <FileLink
                style={{
                  display: reference?.fileId ? "block" : "none",
                  position: "absolute",
                  top: 120,
                  right: 0,
                }}
                href={`/files/${selectedMeta?.fileId}?pageNumber=${selectedMeta?.pageNumber}`}
                target="_blank"
              >
                <PdfIcon />
              </FileLink>
              <Tabs>
                {[reference]?.map(ref => (
                  <Tab isSelected key={ref?.id}>
                    <ShortDiv>{ref?.fileName || ref?.subject}</ShortDiv>
                    {subText}
                  </Tab>
                ))}
              </Tabs>
            </div>

            {reference?.rawText && (
              <>
                <EmailSubject
                  dangerouslySetInnerHTML={{ __html: subjectText }}
                />
                <EmailContent dangerouslySetInnerHTML={{ __html: htmlText }} />
              </>
            )}

            {reference?.fileId && (
              <DocContainer>
                <ZoomButtons>
                  <ZoomButton
                    onClick={() =>
                      setZoomFactor(prev => Math.max(prev - 0.5, 1))
                    }
                  >
                    -
                  </ZoomButton>
                  <ZoomButton
                    onClick={() => setZoomFactor(p => Math.min(p + 0.5, 10))}
                  >
                    +
                  </ZoomButton>
                  <UploadedText>
                    Uploaded: {safeFormat(file?.createdAt, "d MMM HH:mm ")}
                  </UploadedText>

                  <DocAnchor
                    target="_blank"
                    rel="noreferrer"
                    href={`/files/${selectedMeta?.fileId}?pageNumber=${selectedMeta?.pageNumber}`}
                  >
                    View file
                    <PdfIcon />
                  </DocAnchor>
                </ZoomButtons>

                <ImgWithRectangleFlexibleStyled
                  zoomFactor={zoomFactor}
                  isLoading={!pageResults?.image}
                  rectangles={selectedMeta?.references}
                  src={`data:image/png;base64,${pageResults?.image || ""}`}
                  alt="page preview"
                />
              </DocContainer>
            )}
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FieldReferenceModal;
