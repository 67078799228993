import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { getColorFromString } from "utils/common";
import { useState } from "react";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

const WrapContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 4px 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
`;

const StyledSelect = styled.select`
  background-color: #f3f5f7;
  border: none;
  outline: none;
  border-radius: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 4px;
  font-weight: 500;
`;

const Name = styled.div`
  white-space: nowrap;
  font-weight: 500;
`;

const NameAndInput = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 8px;
`;

export const ANIMATION_MS = 1800;

export const PieParamsEditor = ({
  params,
  onChangeParams,
  tableColumns,
  isEditing,
}) => {
  const { xColumnName, yColumnNames } = params;

  if (isEditing) {
    return (
      <div>
        <NameAndInput>
          <Name>Category:</Name>
          <StyledSelect
            value={xColumnName}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                xColumnName: e.target.value,
              });
            }}
          >
            <option>-- Choose category column --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>

        <NameAndInput>
          <Name>Value:</Name>
          <StyledSelect
            value={yColumnNames?.[0]}
            onChange={e => {
              onChangeParams({
                ...(params || {}),
                yColumnNames: [e.target.value],
              });
            }}
          >
            <option>-- Choose value column --</option>
            {tableColumns.map((column, index) => (
              <option key={index} value={column?.name}>
                {column?.name}
              </option>
            ))}
          </StyledSelect>
        </NameAndInput>
      </div>
    );
  }

  return (
    <WrapContainer>
      <Label
        style={{ backgroundColor: `${getColorFromString(xColumnName)}55` }}
      >
        {yColumnNames?.[0]}
      </Label>
    </WrapContainer>
  );
};

const OPTIONS = {
  chart: {
    type: "pie",
    backgroundColor: "transparent",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  // tooltip: {
  //   valueSuffix: "%",
  // },
  // subtitle: {
  //   text: "",
  // },
  plotOptions: {
    series: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: [
        {
          enabled: true,
          distance: 20,
        },
        // {
        //   enabled: true,
        //   distance: -40,
        //   format: "{point.percentage:.1f}%",
        //   style: {
        //     fontSize: "1.2em",
        //     textOutline: "none",
        //     opacity: 0.7,
        //   },
        //   filter: {
        //     operator: ">",
        //     property: "percentage",
        //     value: 10,
        //   },
        // },
      ],
    },
  },
  series: [
    {
      name: "",
      colorByPoint: true,
      data: [
        // {
        //   name: "Water",
        //   y: 55.02,
        // },
        // {
        //   name: "Fat",
        //   sliced: true,
        //   selected: true,
        //   y: 26.71,
        // },
        // {
        //   name: "Carbohydrates",
        //   y: 1.09,
        // },
        // {
        //   name: "Protein",
        //   y: 15.5,
        // },
        // {
        //   name: "Ash",
        //   y: 1.68,
        // },
      ],
    },
  ],
};

const PieChartParamsNew = ({
  params = {},
  isEditing = false,
  onChangeParams = newParams => {},
  tableColumns = [],
  records = [],
  marginBottom,
}) => {
  const { xColumnName, yColumnNames } = params;
  const [options, setOptions] = useState(OPTIONS);

  useEffect(() => {
    const seriesData = records?.map(record => {
      return {
        name: record?.[xColumnName]?.value,
        y: parseFloat(record?.[yColumnNames?.[0]]?.value),
      };
    });

    const newOptions = cloneDeep(options);
    newOptions.series[0].data = seriesData;
    newOptions.chart.height = 220;

    setOptions(newOptions);
  }, [xColumnName, yColumnNames?.[0], JSON.stringify(records)]);

  return (
    <div style={{ marginBottom: marginBottom ?? 40 }}>
      <PieParamsEditor
        params={params}
        onChangeParams={onChangeParams}
        tableColumns={tableColumns}
        isEditing={isEditing}
      />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PieChartParamsNew;
