import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { cloneDeep, isNil, last } from "lodash";

import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import Modal from "components/ui/Modal";
import TextInputSquare from "components/inputs/TextInputSquare";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import {
  CrossIcon,
  EmailIcon,
  FilesIcon,
  FolderIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  UploadCloudIcon,
} from "components/ui/Icons";
import { formatLongNumber, uuidv4 } from "utils/common";
import {
  postWordDoc,
  postWordDocsIdSaveAsTemplate,
} from "api/services/wordDocsService";
import { COLUMN_TYPES } from "components/DashboardColumnModalTrigger";
import {
  postPipelineConfigs,
  postSigmas,
  postSigmasV2,
} from "api/backend/fileSystemEndpoints";
import { PieChartSharp } from "@material-ui/icons";
import ButtonWord from "components/ui/ButtonWord";
import { postUploadForDatabase } from "api/backend/filesEndpoints";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import {
  postPipelineBulkIngest,
  postSigmasUploadCsv,
} from "api/backend/chatServiceEndpoints";
import FileInputArea from "components/FileInputArea";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";
import usePollAggregateProgress from "api/services/usePollAggregateProgress";
import { CircularProgress } from "@material-ui/core";
import FileInputAreaGeneric from "components/FileInputAreaGeneric";
import { getAppBasePathSource } from "components/LayoutApp";

const ModalContent = styled.div`
  padding: 20px;
  display: grid;
  align-content: start;
  width: 600px;
  height: 635px;
  gap: 15px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;

  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledTextInputSquare = styled(TextInputSquare)`
  min-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-self: start;
`;

const Td = styled.td`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  white-space: nowrap;
  padding: 8px 32px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;
  z-index: 1;

  position: sticky;
  top: 0px;
`;

const TableContainer = styled.div`
  height: 300px;
  overflow: auto;
`;

const GreyedOutTr = styled.tr`
  opacity: 0.5;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const StyledBoldInput = styled.input`
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-family: "Montserrat";
  width: 120px;
  padding: 0;
`;

const StyledInput = styled.input`
  outline: none;
  border: 1px solid #bfbfbf;
  font-weight: 500;
  font-family: "Montserrat";
  padding: 4px;
  border-radius: 4px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  height: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Card = styled.label`
  width: calc(100% / 2 - 10px);
  height: 100px;
  background-color: #e8ecefbf;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  display: grid;
  gap: 8px;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const CardTitle = styled.div`
  line-height: 1.2;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-weight: 600;
`;

const CopyDiv = styled.div`
  position: relative;
  padding: 10px;
  background-color: #f3f5f7;
  white-space: pre-wrap;
  border-radius: 10px;
  font-weight: 500;
`;

const StyledFilesIcon = styled(FilesIcon)`
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
  :active {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledButtonWord = styled(ButtonWord)`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  img {
    height: 12px;
  }
  background-color: #eaeaea;
  color: black;
  :hover {
    color: ${props => props.theme.color.primary};
  }
`;

const ALL_STATES = [
  {
    id: "choose",
    label: "Add records",
    choices: ["upload-file", "manual", "csv", "upload-file-with-output"],
  },
  {
    id: "upload-file",
    label: "Upload source from local drive",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  {
    id: "upload-file-with-output",
    label: "Bulk upload with training",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  {
    id: "enter-multiple",
    label: "Enter mutliple records",
    icon: <PencilIcon style={{ stroke: "black" }} />,
  },
  {
    id: "enter-text",
    isDisabled: true,
    label: "Extract from plain text",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  {
    id: "send-email",
    label: "By email",
    icon: <EmailIcon style={{ stroke: "black" }} />,
  },
  {
    id: "csv",
    label: "Batch upload (CSV)",
    icon: <UploadCloudIcon style={{ stroke: "black" }} />,
  },
  {
    id: "manual",
    label: "Manual entry",
    icon: <PencilIcon style={{ stroke: "black" }} />,
  },
];

const ProgressStepItem = styled.div`
  display: grid;
  grid-template-columns: 14px 220px 1fr;
  padding: 4px;
  width: max-content;
  border-radius: 4px;
  align-items: center;
  white-space: nowrap;
  background-color: ${props =>
    props.isCurrentStep ? "#eaeaea" : "transparent"};
  svg {
    opacity: ${props => (props.isCurrentStep ? 1 : 0)};
    color: ${props => props.theme.color.primary};
  }
  progress {
    margin-left: 40px;
    /* opacity: ${props => (props.isCurrentStep ? 1 : 0)}; */
    animation: none;
    ${props =>
      props.isCurrentStep && "animation: opacity-pulse 1s linear infinite;"}
    @keyframes opacity-pulse {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }
  }
`;

const AddSigmaRecordModalNew = ({
  trigger = <button>+</button>,
  pipelineConfig = {},
  style = {},
  doPostEmptyRecord = async () => {},
  recordId = null,
  selectedTableId = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pipelineName, setPipelineName] = useState("");
  const [stateId, setStateId] = useState(ALL_STATES[0].id);

  const [isCreatingManyRecords, setIsCreatingManyRecords] = useState(false);
  const [newRecordNames, setNewRecordNames] = useState([""]);

  const [progressStepIndex, setProgressStepIndex] = useState(null);
  const [jobIds, setJobIds] = useState([]);
  const [loadedBytes, setLoadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [messages, setMessages] = useState([]);
  const jobsProgress = usePollAggregateProgress(jobIds);

  const [isLoading, setIsLoading] = useState(false);
  const [plainText, setPlainText] = useState("");
  const [error, setError] = useState(null);

  const [files, setFiles] = useState([]);
  const [record, setRecord] = useState({
    fields: {},
  });

  const state = ALL_STATES.find(state => state.id === stateId);

  useEffect(() => {
    if (!isOpen) {
      let initialState = ALL_STATES[0].id;
      if (!isNil(recordId)) {
        initialState = "upload-file";
      }

      setStateId(initialState);
      setPlainText("");
      setFiles([]);
      setLoadedBytes(0);
      setTotalBytes(0);
      setMessages([]);
      setJobIds([]);
      setProgressStepIndex(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (progressStepIndex !== 2) {
      return;
    }

    if (jobsProgress?.progress === 100) {
      setProgressStepIndex(3);
    }
  }, [progressStepIndex, JSON.stringify(jobsProgress)]);

  const doPostRecord = async () => {
    await postSigmasV2(
      {},
      {
        pipelineId: pipelineConfig?.id,
        tableName: pipelineConfig?.sourceTables?.[0]?.id,
        ...record,
      }
    );
    setIsOpen(false);
  };

  const doPostPlainText = async () => {
    // const file = new File([plainText], `text.txt`, {
    //   type: "text/plain",
    // });
    // const formDataBody = new FormData();
    // formDataBody.append("file", file);
    // const { data } = await postUploadForDatabase(
    //   {
    //     pipelineId: pipelineConfig?.id,
    //     tableName: pipelineConfig?.sourceTables?.[0]?.id,
    //   },
    //   formDataBody
    // );
  };

  const doPostBulkIngest = async () => {
    const formDataBody = new FormData();
    for (let i = 0; i < files.length; i++) {
      formDataBody.append("file", files[i]);
    }

    const { data } = await postPipelineBulkIngest(
      pipelineConfig?.id,
      {},
      formDataBody
    );
  };

  const doPostFileForExtraction = async () => {
    const formDataBody = new FormData();
    for (let i = 0; i < files.length; i++) {
      formDataBody.append("files", files[i]);
    }
    setProgressStepIndex(0);

    const directoryPath = getAppBasePathSource(pipelineConfig?.id);
    let queryParamStr = `pipelineId=${pipelineConfig?.id}&directoryPath=${directoryPath}`;
    if (recordId) {
      queryParamStr += `&sigmaRecordId=${recordId}`;
    }

    let xhr = new XMLHttpRequest();
    xhr.open("POST", `/bz-api/v1/files/upload-for-database?${queryParamStr}`);
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + getLoginTokenFromClientStorage()
    );

    xhr.upload.addEventListener("progress", e => {
      setLoadedBytes(e.loaded);
      setTotalBytes(e.total);
      if (e.loaded === e.total) {
        setProgressStepIndex(1);
      }
    });

    xhr.addEventListener("progress", e => {
      const messagesText = xhr.responseText.split("data:");
      const messagesJson = messagesText
        .filter(m => m?.includes("{") && m?.includes("}"))
        .map(message => {
          try {
            return JSON.parse(message);
          } catch (e) {
            return null;
          }
        });
      setMessages(messagesJson);
    });

    xhr.addEventListener("loadend", e => {
      const lastMessageText = last(xhr.responseText.split("data:"));
      let lastMessageJson = null;
      try {
        lastMessageJson = JSON.parse(lastMessageText);
      } catch (e) {
        lastMessageJson = null;
      }
      setJobIds(lastMessageJson?.jobIds || []);
      setProgressStepIndex(2);
    });

    xhr.send(formDataBody);
    // setIsOpen(false);

    // await postUploadForDatabase(
    //   { pipelineId: pipelineConfig?.id },
    //   formDataBody
    // );
    // setIsOpen(false);
  };

  const doPostCsvOnFileChange = async e => {
    const file = e.target.files[0];
    const formDataBody = new FormData();
    formDataBody.append("file", file);
    await postSigmasUploadCsv(
      pipelineConfig?.id,
      {
        tableName: selectedTableId || pipelineConfig?.sourceTables?.[0]?.id,
      },
      formDataBody
    );
    setIsOpen(false);
  };

  const doCreateNewRecords = async () => {
    setIsCreatingManyRecords(true);
    const nameColumn = pipelineConfig?.sourceTables?.[0]?.columns?.find(
      col => !col?.groupName
    );

    for (let i = 0; i < newRecordNames.length; i++) {
      await postSigmasV2(
        {},
        {
          pipelineId: pipelineConfig?.id,
          tableName: pipelineConfig?.sourceTables?.[0]?.id,
          fields: {
            [nameColumn?.dbName]: {
              value: newRecordNames[i],
            },
          },
        }
      );
    }

    setIsCreatingManyRecords(false);
    setIsOpen(false);
  };

  const columns = pipelineConfig?.sourceTables?.[0]?.columns || [];

  let modalContent = null;
  if (stateId === "choose") {
    modalContent = (
      <ModalContent>
        <Title>{state?.label}</Title>
        <SubTitle>Single record</SubTitle>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
          {["upload-file", "manual", "send-email"]?.map(cardStateId => {
            const choiceState = ALL_STATES.find(
              state => state.id === cardStateId
            );

            return (
              <Card
                isDisabled={choiceState?.isDisabled}
                onClick={() => {
                  if (cardStateId === "manual") {
                    doPostEmptyRecord();
                    setIsOpen(false);
                    return;
                  }
                  setStateId(choiceState?.id);
                }}
              >
                {choiceState?.icon}
                <CardTitle>{choiceState?.label}</CardTitle>
              </Card>
            );
          })}
        </div>
        <Gap />
        <SubTitle>Multiple records</SubTitle>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
          {["csv", "upload-file-with-output", "enter-multiple"]?.map(
            cardStateId => {
              const choiceState = ALL_STATES.find(
                state => state.id === cardStateId
              );

              if (choiceState?.id === "csv") {
                return (
                  <Card>
                    <input
                      type="file"
                      style={{ position: "absolute", opacity: 0 }}
                      onChange={doPostCsvOnFileChange}
                    />
                    {choiceState?.icon}
                    <CardTitle>{choiceState?.label}</CardTitle>
                  </Card>
                );
              }

              return (
                <Card
                  isDisabled={choiceState?.isDisabled}
                  onClick={() => {
                    if (cardStateId === "manual") {
                      doPostEmptyRecord();
                      setIsOpen(false);
                      return;
                    }
                    setStateId(choiceState?.id);
                  }}
                >
                  {choiceState?.icon}
                  <CardTitle>{choiceState?.label}</CardTitle>
                </Card>
              );
            }
          )}
        </div>
      </ModalContent>
    );
  }

  if (stateId === "upload-file") {
    modalContent = (
      <ModalContent
        style={{
          gridTemplateRows: "auto auto 1fr auto",
        }}
      >
        <Title>Add files</Title>
        <div>Please add PDF files</div>
        <FileInputAreaGeneric onChange={e => setFiles(e.target.files)} />
        <div
          style={{
            display: "grid",
            gap: 8,
            opacity: !!files?.[0] ? 1 : 0,
          }}
        >
          <ProgressStepItem isCurrentStep={progressStepIndex === 0}>
            1.
            <div>
              Uploaded bytes: {formatLongNumber(loadedBytes)} /{" "}
              {formatLongNumber(totalBytes)}
            </div>
            <progress value={loadedBytes || 0} max={totalBytes || 100} />
          </ProgressStepItem>
          <ProgressStepItem isCurrentStep={progressStepIndex === 1}>
            2.
            <div>
              Ingested files: {last(messages)?.filesIngestedCount || 0} /&nbsp;
              {files?.length}
            </div>
            <progress
              value={last(messages)?.filesIngestedCount || 0}
              max={files?.length || 100}
            />
          </ProgressStepItem>
          <ProgressStepItem isCurrentStep={progressStepIndex === 2}>
            3.
            <div>
              Creating records, progress: {jobsProgress?.progress || 0} %
            </div>
            <progress value={jobsProgress?.progress || 0} max={100} />
          </ProgressStepItem>
        </div>
        {progressStepIndex !== 3 && (
          <ButtonWord
            disabled={!isNil(progressStepIndex)}
            onClick={doPostFileForExtraction}
            style={{ justifySelf: "end" }}
          >
            Submit
          </ButtonWord>
        )}
        {progressStepIndex === 3 && (
          <ButtonWord
            onClick={() => setIsOpen(false)}
            style={{ justifySelf: "end" }}
          >
            Close
          </ButtonWord>
        )}
      </ModalContent>
    );
  }

  if (stateId === "upload-file-with-output") {
    modalContent = (
      <ModalContent>
        <Title>Add files</Title>
        <div>Please add PDFs and one .jsonl file</div>
        <input type="file" multiple onChange={e => setFiles(e.target.files)} />
        <ButtonWord onClick={doPostBulkIngest} style={{ justifySelf: "end" }}>
          Submit
        </ButtonWord>
      </ModalContent>
    );
  }

  if (stateId === "enter-multiple") {
    modalContent = (
      <ModalContent>
        <Title>Create records</Title>
        {newRecordNames.map((recordName, index) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
              gap: 10,
            }}
          >
            <StyledInput
              placeholder="Record name"
              value={recordName}
              onChange={e => {
                const newRecordNamesCopy = cloneDeep(newRecordNames);
                newRecordNamesCopy[index] = e.target.value;
                setNewRecordNames(newRecordNamesCopy);
              }}
            />
            <CrossIcon
              style={{ cursor: "pointer", height: 10 }}
              onClick={() => {
                const newRecordNamesCopy = cloneDeep(newRecordNames);
                newRecordNamesCopy.splice(index, 1);
                setNewRecordNames(newRecordNamesCopy);
              }}
            />
          </div>
        ))}
        <StyledPlusIcon
          onClick={() => setNewRecordNames([...newRecordNames, ""])}
        />
        <ButtonWord
          disabled={isCreatingManyRecords}
          onClick={doCreateNewRecords}
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
          }}
        >
          Submit
        </ButtonWord>
      </ModalContent>
    );
  }

  if (stateId === "send-email") {
    const recipient = "data@boltzbit.io";
    const templateStr = `Pipeline: ${pipelineConfig?.name} \n\n<your content here>`;
    modalContent = (
      <ModalContent>
        <Title>Send email</Title>
        <div>Send to:</div>
        <CopyDiv>
          {recipient}
          <StyledFilesIcon
            onClick={() => navigator.clipboard.writeText(recipient)}
          />
        </CopyDiv>
        <div>Email body (include app name):</div>
        <CopyDiv style={{ height: 360 }}>
          {templateStr}
          <StyledFilesIcon
            onClick={() => navigator.clipboard.writeText(templateStr)}
          />
        </CopyDiv>
        <div style={{ display: "flex", gap: 10, justifyContent: "end" }}>
          <StyledButtonWord
            onClick={() =>
              window.open(
                "https://mail.google.com/mail/u/0/#inbox?compose=new",
                "_blank"
              )
            }
          >
            Go to <img src="/images/logo-gmail.png" />
          </StyledButtonWord>
          <StyledButtonWord
            onClick={() =>
              window.open("https://outlook.live.com/mail/0/", "_blank")
            }
          >
            Go to <img src="/images/logo-outlook.png" />
          </StyledButtonWord>
        </div>
      </ModalContent>
    );
  }

  if (stateId === "enter-text") {
    modalContent = (
      <ModalContent>
        <Title>{state?.label}</Title>
        <textarea
          value={plainText}
          onChange={e => setPlainText(e.target.value)}
          placeholder="Paste or type text here"
          style={{ height: 200 }}
        />
        <ButtonWord
          onClick={doPostPlainText}
          style={{ justifySelf: "end", alignSelf: "end" }}
        >
          Extract
        </ButtonWord>
      </ModalContent>
    );
  }

  return (
    <>
      <div style={{ width: "100%", ...style }} onClick={() => setIsOpen(true)}>
        {trigger}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        {modalContent}
      </Modal>
    </>
  );
};

export default AddSigmaRecordModalNew;
